/* eslint-disable react/prop-types */
import { CheckOutlined, CloseOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Divider, Image, Tooltip, Slider } from 'antd';
import React from 'react';
import SVG from 'react-inlinesvg';

import TableFilterDropdown from './TableFilterDropdown';
import VendorStatusModal from '../vendor_status/VendorStatusModal';
import CellContentWithPrimaryTag from './CellContentWithPrimaryText';

import actionRequiredStatusIcon from '../../../../assets/icons/action-required.svg';
import allowedStatusIcon from '../../../../assets/icons/allowed-status.svg';
import allowedRiskStatusIcon from '../../../../assets/icons/allowed-risk-status.svg';
import underReviewStatusIcon from '../../../../assets/icons/under-review-status.svg';
import unreviewedStatusIcon from '../../../../assets/icons/unreviwed-status.svg';
import googleIcon from '../../../../assets/images/diagnose/google_icon.png';
import cookieIcon from '../../../../assets/images/diagnose/cookie.svg';
import cookieFilledIcon from '../../../../assets/images/diagnose/cookie_filled.svg';
import localStorageIcon from '../../../../assets/images/diagnose/local_storage.svg';
import fingerprintIcon from '../../../../assets/images/diagnose/fingerprint.svg';
import VendorEvidenceModal from '../vendor_evidence/VendorEvidenceModal';
import moment from 'moment';
import CookieDetailsModal from './cookie_details/CookieDetailsModal';

const convertAnyToArray = (value) => {
  return Array.isArray(value) ? value : [value];
};

export const customPrevalenceFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
  const onCheckStorageType = (checkedValues) => {
    setSelectedKeys(checkedValues);
  };
  return (
    <div className="prevalence_custom_filter">
      <div className={'prevalence-range'}>
        <div className={'prevalence-range-from'}>
          <div>From:</div>
          <div>{selectedKeys[0]}%</div>
        </div>
        <div className={'prevalence-range-to'}>
          <div>To:</div>
          <div>{selectedKeys[1]}%</div>
        </div>
      </div>
      <div>
        <Slider
          range
          marks={{ 0: '0%', 100: '100%' }}
          onChange={onCheckStorageType}
          value={selectedKeys}
          tipFormatter={null}
        />
      </div>
      <div className="ant-table-filter-dropdown-btns">
        <Button onClick={() => clearFilters()}>Reset</Button>
        <Button type="primary" onClick={() => confirm({ closeDropdown: true })}>
          Save
        </Button>
      </div>
    </div>
  );
};

const defaultVendorTypeFilters = [
  {
    text: 'IAB',
    value: 'iab_id',
    icon: <div className="vendor-type-cell-content" style={{ backgroundColor: '#FCA015' }} />,
  },
  {
    text: 'Google',
    value: 'google',
    icon: (
      <div style={{ marginBottom: 3 }}>
        <Image src={googleIcon} preview={false} height={'10px'} width={'10px'} />
      </div>
    ),
  },
  {
    text: 'Custom',
    value: 'custom',
    icon: <div className="vendor-type-cell-content" style={{ backgroundColor: '#6783A7' }} />,
  },
];

export const AfterOptOutVendorTypeFilters = [
  {
    text: 'MSPA',
    value: 'mspa',
    icon: <div className="vendor-type-cell-content" style={{ backgroundColor: '#9124E3' }} />,
  },
  {
    text: 'No Label',
    value: 'other',
    icon: (
      <div className="vendor-type-cell-content" style={{ backgroundColor: '#EBEBEB', border: '2px solid #B4B4B4' }} />
    ),
  },
];

export const VendorTypeColumn = ({ title = '', filters = defaultVendorTypeFilters }) => ({
  dataIndex: 'vendor_type',
  key: 'vendor_type',
  filters,
  // onFilter: (value, record) => record[value],
  title,
  filterDropdown: (props) => <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} multiple />,
  className: 'vendor_type',
  render: (text) => (
    <div>
      {text === 'google' && (
        <div style={{ marginRight: '5px', height: '16px' }}>
          <Image src={googleIcon} preview={false} height={'13px'} width={'13px'} />
        </div>
      )}
      {text === 'iab_id' && <div className="vendor-type-cell-content" style={{ backgroundColor: '#FCA015' }} />}
      {text === 'custom' && <div className="vendor-type-cell-content" style={{ backgroundColor: '#6783A7' }} />}
      {text === 'other' && (
        <div className="vendor-type-cell-content" style={{ backgroundColor: '#EBEBEB', border: '2px solid #B4B4B4' }} />
      )}
      {text === 'mspa' && <div className="vendor-type-cell-content" style={{ backgroundColor: '#9124E3' }} />}
    </div>
  ),
  width: 30,
  fixed: 'left',
});

export const VendorNameColumn = ({ filters }) => ({
  title: 'Vendor Name',
  dataIndex: 'name',
  className: 'vendor_name',
  key: 'name',
  sorter: (a, b) => a.name.localeCompare(b.name),
  filters,
  filterDropdown: (props) => (
    <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} showSearch multiple />
  ),
  filterSearch: true,
  fixed: 'left',
  width: 120,
});

export const VendorStatusColumn = ({ searchParams }) => ({
  title: () => {
    return (
      <div>
        Vendor Status
        <Tooltip
          title={() => {
            return (
              <div className="information-storage-tooltip">
                <div className="information-storage-item">
                  <SVG src={unreviewedStatusIcon} />
                  <div className="information-storage-item-name">
                    <b style={{ marginRight: '3px' }}>Unreviewed:</b>
                    Default status for vendors with no status
                  </div>
                </div>
                <div className="information-storage-item">
                  <SVG src={underReviewStatusIcon} />
                  <div className="information-storage-item-name">
                    <b style={{ marginRight: '3px' }}>Under Review:</b>
                    Vendors currently being reviewed
                  </div>
                </div>
                <div className="information-storage-item">
                  <SVG src={allowedStatusIcon} />
                  <div className="information-storage-item-name">
                    <b style={{ marginRight: '3px' }}>Approved:</b>
                    Vendors that have been validated
                  </div>
                </div>
                <div className="information-storage-item">
                  <SVG src={actionRequiredStatusIcon} />
                  <div className="information-storage-item-name">
                    <b style={{ marginRight: '3px' }}>Action Required:</b>
                    Vendors that require an action eg add to vendor list, remove script from site or block from before
                    consent.
                  </div>
                </div>
                <div className="information-storage-item">
                  <SVG src={allowedRiskStatusIcon} />
                  <div className="information-storage-item-name">
                    <b style={{ marginRight: '3px' }}>Approved with risk:</b>
                    Vendors that have been validated with an accepted risk level
                  </div>
                </div>
                <Divider style={{ borderColor: '#DAE4EB', width: 'calc(100% + 10px)' }} />
                <div className="information-storage-item">
                  <div className={'vendor-status-tag'} style={{ backgroundColor: '#00B0FF' }} />
                  <div className="information-storage-item-name">
                    <b style={{ marginRight: '3px' }}>New:</b>
                    This vendor is newly seen on this property.
                  </div>
                </div>
                <div className="information-storage-item">
                  <div className={'vendor-status-tag'} style={{ backgroundColor: '#FFD500' }} />
                  <div className="information-storage-item-name">
                    <b style={{ marginRight: '3px' }}>Change detected:</b>
                    Changes were detected in the data for this vendor.
                  </div>
                </div>
              </div>
            );
          }}
          overlayClassName="vendor-status-tooltip-overlay"
          placement="bottom"
        >
          <InfoCircleFilled style={{ marginLeft: '5px' }} />
        </Tooltip>
      </div>
    );
  },
  width: 100,
  dataIndex: 'vendor_status',
  key: 'vendor_status',
  filters: [
    { text: 'Unreviewed', value: 'Unreviewed', src: unreviewedStatusIcon },
    { text: 'Under Review', value: 'Under Review', src: underReviewStatusIcon },
    { text: 'Approved', value: 'Approved', src: allowedStatusIcon },
    { text: 'Action Required', value: 'Action Required', src: actionRequiredStatusIcon },
    { text: 'Approved with risk', value: 'Approved with risk', src: allowedRiskStatusIcon },
  ],
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => {
    const allFilterStatuses = filters.map((item) => item.value);
    const selectedKeysFlat = selectedKeys.flat();
    const indeterminateStatuses = !!(selectedKeysFlat.length && selectedKeysFlat.length < 6);
    const checkAllStatuses = selectedKeysFlat.length === 6;
    const [statuses = [], tags = []] = selectedKeys.reduce(
      (sortedKeys, key) => {
        if (allFilterStatuses.includes(key)) {
          return [[...sortedKeys[0], key], sortedKeys[1]];
        }
        return [sortedKeys[0], [...sortedKeys[1], key]];
      },
      [[], []],
    );
    const onCheckStorageType = (checkedStatuses) => {
      setSelectedKeys([...convertAnyToArray(checkedStatuses), ...tags]);
      if (!tags.length && !checkedStatuses.length) {
        clearFilters();
      }
    };

    const onCheckFilterByAllStatuses = (e) => {
      if (e.target.checked) {
        setSelectedKeys([...allFilterStatuses, 'Change Detected', 'New']);
      } else {
        clearFilters();
      }
    };

    const resetFilter = () => {
      clearFilters();
      confirm({ closeDropdown: true });
    };

    const onCheckVendorTag = (checkedTags) => {
      setSelectedKeys([...statuses, ...convertAnyToArray(checkedTags)]);
      if (!checkedTags.length && !statuses.length) {
        clearFilters();
      }
    };

    return (
      <div className="vendor_status_custom_filter">
        <Checkbox
          indeterminate={indeterminateStatuses}
          onChange={onCheckFilterByAllStatuses}
          checked={checkAllStatuses}
        >
          <span style={{ marginLeft: '8px' }}>All</span>
        </Checkbox>
        <Checkbox.Group onChange={onCheckStorageType} value={statuses}>
          {filters.map((d) => {
            return (
              <Checkbox key={d.value} value={d.value}>
                {d.src ? <Image alt={d.text} src={d.src} preview={false} /> : null} {d.text}
              </Checkbox>
            );
          })}
        </Checkbox.Group>
        <Divider style={{ borderColor: '#DAE4EB' }} />
        <Checkbox.Group onChange={onCheckVendorTag} value={tags}>
          <Checkbox value="New">
            <div className={'vendor-status-tag'} style={{ backgroundColor: '#00B0FF' }} />
            New
          </Checkbox>
          <Checkbox value="Change Detected">
            <div className={'vendor-status-tag'} style={{ backgroundColor: '#FFD500' }} />
            Change detected
          </Checkbox>
        </Checkbox.Group>
        <div className="ant-table-filter-dropdown-btns">
          <Button onClick={resetFilter}>Reset</Button>
          <Button type="primary" onClick={() => confirm({ closeDropdown: true })}>
            Save
          </Button>
        </div>
      </div>
    );
  },
  onFilter: (value, record) => {
    if (!record.vendor_status.length) {
      return false;
    }
    const statusesList = record.vendor_status?.flatMap((item) => [item.status, item.tag]);
    const arrayValue = convertAnyToArray(value);
    return arrayValue.length
      ? statusesList.filter((status) => arrayValue.includes(status)).length
      : statusesList.length;
  },
  render: (text, record) => {
    return (
      <div key={record?.vendor_id}>
        {record.vendor_status?.length ? (
          <VendorStatusModal
            data={record}
            defaultOpen={
              record.vendor_id === searchParams.vendor &&
              ['CHANGED_STATUS', 'CHANGES_DETECTED'].includes(searchParams.type)
            }
          />
        ) : null}
      </div>
    );
  },
});

export const PrevalenceColumn = {
  title: 'Prevalence (My properties)',
  dataIndex: 'prevalence',
  key: 'prevalence',
  sortDirections: ['descend', 'ascend'],
  sorter: (a, b) => a.prevalence - b.prevalence,
  filters: [
    { text: '0', value: '0' },
    { text: '100', value: '100' },
  ],
  render: (text) => <div>{Math.round(parseFloat(text) * 100) / 100}%</div>,
  filterDropdown: customPrevalenceFilterDropdown,
  width: 145,
};

export const PrevalenceBenchMarkColumn = {
  title: 'Prevalence (Benchmark)',
  dataIndex: 'prevalence_benchmark',
  key: 'prevalence_benchmark',
  filters: [
    { text: '0', value: '0' },
    { text: '100', value: '100' },
  ],
  filterDropdown: customPrevalenceFilterDropdown,
  sorter: (a, b) => a.prevalence_benchmark - b.prevalence_benchmark,
  sortDirections: ['descend', 'ascend'],
  render: (text) => <div>{Math.round(parseFloat(text) * 100) / 100}%</div>,
  width: 145,
};

export const InformationStorageColumn = {
  title: () => {
    return (
      <div>
        Information Storage/Access
        <Tooltip
          title={() => {
            return (
              <div className="information-storage-tooltip">
                <div className="information-storage-item">
                  <Image src={cookieIcon} preview={false} />
                  <div className="information-storage-item-name">Cookie</div>
                </div>
                <div className="information-storage-item">
                  <Image src={cookieFilledIcon} preview={false} />
                  <div className="information-storage-item-name">Persistent Cookies</div>
                </div>
                <div className="information-storage-item">
                  <Image src={localStorageIcon} preview={false} />
                  <div className="information-storage-item-name">Local Storage</div>
                </div>
                <div className="information-storage-item">
                  <Image src={fingerprintIcon} preview={false} />
                  <div className="information-storage-item-name">Fingerprinting</div>
                </div>
              </div>
            );
          }}
          overlayClassName="information-storage-tooltip-overlay"
        >
          <InfoCircleFilled style={{ marginLeft: '5px' }} />
        </Tooltip>
      </div>
    );
  },
  key: 'information_storage',
  filters: [
    { text: 'Cookie', value: 'use_session_cookies', icon: <Image alt={'Cookie'} src={cookieIcon} preview={false} /> },
    {
      text: 'Persistent cookies',
      value: 'use_persistent_cookies',
      icon: <Image alt={'Persistent cookies'} src={cookieFilledIcon} preview={false} />,
    },
    {
      text: 'Fingerprinting',
      value: 'use_fingerprint',
      icon: <Image alt={'Fingerprint'} src={fingerprintIcon} preview={false} />,
    },
    {
      text: 'Local storage',
      value: 'use_local_storage',
      icon: <Image alt={'Local Storage'} src={localStorageIcon} preview={false} />,
    },
  ],
  render: (text, record) => {
    return (
      <>
        {record.use_session_cookies && (
          <span title="Cookie" style={{ marginRight: '5px' }}>
            <Image alt="Cookies" src={cookieIcon} preview={false} />{' '}
          </span>
        )}
        {record.use_persistent_cookies && (
          <span title="Persistent Cookies" style={{ marginRight: '5px' }}>
            <Image alt="Persistent cookies" src={cookieFilledIcon} preview={false} />{' '}
          </span>
        )}
        {record.use_fingerprint && (
          <span title="Fingerprinting" style={{ marginRight: '5px' }}>
            <Image alt="Fingerprint" src={fingerprintIcon} preview={false} />{' '}
          </span>
        )}
        {record.use_local_storage && (
          <span title="Local Storage" style={{ marginRight: '5px' }}>
            <Image alt="Local Storage" src={localStorageIcon} preview={false} />{' '}
          </span>
        )}
      </>
    );
  },
  filterDropdown: (props) => (
    <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} allowAllSelect multiple />
  ),
  width: 150,
};

export const VendorReferenceColumn = ({ filters }) => ({
  title: () => {
    return (
      <div>
        Referrer Vendor
        <Tooltip
          title={() => {
            return (
              <div className="information-storage-tooltip">
                The referrer vendor is the vendor identified to own the last web address accessed by the browser prior
                to triggering the vendor in question.
              </div>
            );
          }}
          overlayClassName="information-storage-tooltip-overlay"
        >
          <InfoCircleFilled style={{ marginLeft: '5px' }} />
        </Tooltip>
      </div>
    );
  },
  key: 'reference_vendors',
  dataIndex: 'reference_vendors',
  filters,
  sorter: true,
  filterDropdown: (props) => (
    <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} showSearch multiple />
  ),
  render: (text, record) => {
    const [firstReferenceVendors = '', ...restReferenceVendors] = record.reference_vendors || [];
    const refVendorsList = restReferenceVendors?.reduce((partialString, vendorData, idx) => {
      if (!idx) {
        return vendorData?.name || vendorData;
      }
      return `${partialString}; ${vendorData?.name || vendorData}`;
    }, '');
    return (
      <CellContentWithPrimaryTag
        className={'reference-vendors-cell'}
        content={firstReferenceVendors?.name || firstReferenceVendors}
        tooltipTitle={`+${restReferenceVendors.length}`}
        tooltipContent={refVendorsList}
      />
    );
  },
  width: 110,
});

export const TechnologyCategorisationColumn = ({ filters }) => ({
  title: 'Technology Categorisation',
  dataIndex: 'technologies',
  key: 'technologies',
  sorter: (a, b) => a.technologies && a.technologies.join().localeCompare(b.technologies),
  filters,
  // onFilter: (value, record) => record.technologies.join().includes(value),
  filterDropdown: (props) => (
    <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} showSearch multiple />
  ),
  render: (text) => {
    const [firstWord, ...restWords] = text;
    return (
      <CellContentWithPrimaryTag
        className={'reference-vendors-cell'}
        content={firstWord}
        tooltipTitle={`+${restWords.length}`}
        tooltipContent={restWords.join('; ')}
      />
    );
  },
  width: 150,
});

export const CookieNameColumn = ({ filters }) => ({
  title: 'Cookie Name',
  dataIndex: 'cookies',
  key: 'cookies',
  filters,
  filterDropdown: (props) => (
    <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} showSearch multiple />
  ),
  width: 100,
  render: (text) => {
    const [firstWord, ...restWords] = text || [];
    return (
      <CellContentWithPrimaryTag
        className={'reference-vendors-cell'}
        content={firstWord}
        tooltipTitle={`+${restWords.length}`}
        tooltipContent={restWords.join('; ')}
      />
    );
  },
});

export const VendorDetailsColumn = ({ metric }) => ({
  title: () => {
    return (
      <div style={{ textAlign: 'left' }}>
        Vendor Details
        <Tooltip
          title={() => {
            return (
              <div className="information-storage-tooltip">
                Click the view icon to get more granular information about this vendor.
              </div>
            );
          }}
          overlayClassName="information-storage-tooltip-overlay"
        >
          <InfoCircleFilled style={{ marginLeft: '5px' }} />
        </Tooltip>
      </div>
    );
  },
  key: 'vendor_details',
  dataIndex: 'vendor_details',
  align: 'center',
  render: (text, record) => {
    return (
      <div>
        <VendorEvidenceModal metric={metric} data={record} />
      </div>
    );
  },
  width: 100,
});

export const FirstAppearedScanColumn = {
  title: 'First Seen',
  dataIndex: 'first_appeared_scan',
  key: 'first_appeared_scan',
  sorter: (a, b) => a.first_appeared_scan.localeCompare(b.first_appeared_scan),
  render: (text) => <div>{moment(text).format('MMM-DD-YYYY')}</div>,
  width: 90,
};

export const LastAppearedScanColumn = {
  title: 'Last Seen',
  dataIndex: 'last_appeared_scan',
  key: 'last_appeared_scan',
  sorter: (a, b) => a.last_appeared_scan.localeCompare(b.last_appeared_scan),
  render: (text) => <div>{moment(text).format('MMM-DD-YYYY')}</div>,
  width: 90,
};

export const VendorDisclosedColumn = {
  title: 'Vendor Disclosed?',
  key: 'is_on_vendor_list',
  filters: [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ],
  dataIndex: 'is_on_vendor_list',
  sorter: (a, b) => a.is_on_vendor_list.toString().localeCompare(b.is_on_vendor_list),
  filterDropdown: (props) => <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} multiple />,
  render: (text) => {
    return (
      <div className={'is-on_vendor-list-cell'}>
        {text ? <CheckOutlined style={{ color: '#03A233' }} /> : <CloseOutlined style={{ color: '#cb483a' }} />}
      </div>
    );
  },
  width: 130,
};

export const CookieDetailsColumn = ({ metric }) => ({
  title: () => {
    return (
      <div style={{ textAlign: 'left' }}>
        Cookie Details
        <Tooltip
          title={() => {
            return (
              <div className="information-storage-tooltip">
                Click the view icon to get more granular information about the cookies found on the properties selected.
              </div>
            );
          }}
          overlayClassName="information-storage-tooltip-overlay"
        >
          <InfoCircleFilled style={{ marginLeft: '5px' }} />
        </Tooltip>
      </div>
    );
  },
  key: 'cookie_details',
  dataIndex: 'cookie_details',
  align: 'center',
  render: (text, cellRecord) => {
    return (
      <div>
        {cellRecord?.cookie_number || (cellRecord?.cookies && cellRecord?.cookies?.length) ? (
          <CookieDetailsModal metric={metric} data={cellRecord} />
        ) : (
          ''
        )}
      </div>
    );
  },
  width: 155,
});
