import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Switch, Input, Table } from 'antd';
import moment from 'moment';
import CustomFilter from './CustomFilter';
import { categoryMap, subcategoryMap } from '../../../../constants.js';
import WarningMessage from '../../../common/WarningMessage.js';

const messageType = [
  { label: 'Adblock', value: 'adblock' },
  { label: 'GDPR', value: 'gdpr' },
  { label: 'U.S. MultiState Privacy', value: 'usnat' },
  { label: 'U.S. Privacy (Legacy)', value: 'ccpa' },
  { label: 'iOS Tracking', value: 'ios' },
  { label: 'Custom', value: 'custom' },
  { label: 'Preferences', value: 'preferences' }
];

const status = [
  { label: 'Draft', value: 'draft' },
  { label: 'Public', value: 'public' }
];

const templateType = [
  { label: 'System', value: 'system' },
  { label: 'Account', value: 'account' },
];

const optionsSystem = { messageType, templateType, status };

const optionsAccount = { messageType, status };

const defaultCheckedList = {
  messageType: ['gdpr', 'ccpa', 'usnat', 'ios', 'custom', 'adblock', 'preferences'],
  templateType: ['system', 'account'],
  status: ['draft', 'public']
};

const deleteMessageDet = { id: '', name: '' };

const Search = Input.Search;

export default class NoticeTable extends Component {
  static propTypes = {
    templates: PropTypes.array.isRequired,
    showBuilder: PropTypes.func.isRequired,
    searchText: PropTypes.string.isRequired
  };

  state = {
    warningMessagePresent: false,
    deleteMsgDet: deleteMessageDet
  }

  keyByVal = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  changeStatus = (e, status, template) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.changePublishStatus(template, status);
  };

  deleteTemplate = (e, template) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.deleteMessage(template.id, template.name);
    this.setState((prevState) => ({ warningMessagePresent: !prevState.warningMessagePresent }));
  };

  toggleWarningMessage = (e, template) => {
    e.preventDefault();
    e.stopPropagation();
    const deleteMsgDet = !this.state.warningMessagePresent ? { id: template.id, name: template.name } : deleteMessageDet;
    this.setState((prevState) => ({ warningMessagePresent: !prevState.warningMessagePresent, deleteMsgDet }));
  };

  getModalTitle = (cat, subCat) => {
    switch (subCat) {
      case 'notice':
        if (cat === 'gdpr') {
          return 'GDPR TCF v1';
        } else if (cat === 'usnat') {
          return 'U.S. MultiState Privacy First Layer';
        } else if (cat === 'ccpa') {
          return 'U.S. Privacy First Layer (legacy)';
        } else if (cat === 'adblock') {
          return 'Ad-block';
        } else if (cat === 'ios14') {
          return 'iOS Tracking';
        } else if (cat === 'custom') {
          return 'Custom Message';
        }
        break;
      case 'notice-tcf-v2':
        return 'GDPR TCF v2 First Layer';
      case 'privacy-manager':
        if (cat === 'gdpr') {
          return 'GDPR TCF v2 Privacy Manager';
        } else if (cat === 'usnat') {
          return 'U.S. MultiState Privacy Manager';
        }
        break;
      case 'usnat_notice':
        return 'U.S. MultiState Privacy First Layer';
      case 'us_pm':
        return 'U.S. MultiState Privacy Second Layer';
      case 'privacy-manager-ott':
        return 'Legacy OTT';
      case 'sar':
        return 'U.S. Privacy Subject Access Request Form';
      case 'dsarRetrieval':
        return 'U.S. Privacy DSAR Retrieval Form';
      case 'notice_non_iab':
        return 'GDPR Non IAB First Layer';
      case 'privacy_manager_non_iab':
        return 'GDPR Non IAB Privacy Manager';
      case 'ios':
        return 'iOS Tracking';
      case 'custom':
        return 'Custom Message';
      case 'ccpa_pm':
        return 'U.S. Privacy Manager';
      case 'us_pm':
        return 'U.S. Privacy Manager New';
      case 'native-ott':
        return cat === 'gdpr' ? 'GDPR OTT' : 'U.S. Privacy OTT'
      case 'preferences':
        return 'preferences'
      default:
        return cat;
    }
  }

  itemRender = (current, type, originalElement) => {
    return originalElement;
  };

  showBuilder = (e, template) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.showBuilder(template, this.props.isPreviewOnly);
  };

  getFilterOptions() {
    let options = (this.props.accountId === 22) ? optionsSystem : optionsAccount;
    const { currentUser, currentUser: { accountId } } = this.props;

    if (accountId != 22 && currentUser && currentUser.accountFeatures.size) {
      const hasTcfv2 = currentUser.accountFeatures.includes('tcf_v2');
      const hasccpa = currentUser.accountFeatures.includes('ccpa');
      const hasusnat = currentUser.accountFeatures.includes('usnat');
      const hasAppleMessaging = currentUser.accountFeatures.includes('apple_messaging');
      const hasAdblockFeature = currentUser.accountFeatures.includes('adblock_v2');

      let messageType = options.messageType;
      if (!hasTcfv2) {
        messageType = messageType.filter(m => m.value != 'gdpr');
      }
      if (!hasccpa) {
        messageType = messageType.filter(m => m.value != 'ccpa');
      }
      if (!hasusnat) {
        messageType = messageType.filter(m => m.value != 'usnat');
      }
      if (!hasAppleMessaging) {
        messageType = messageType.filter(m => m.value != 'ios');
      }
      if (!hasAdblockFeature) {
        messageType = messageType.filter(m => m.value != 'adblock');
      }
      options.messageType = messageType;
    }
    return options;
  };

  getDefaultOptions() {
    let checkList = defaultCheckedList;
    const { currentUser, currentUser: { accountId } } = this.props;

    if (accountId != 22 && currentUser && currentUser.accountFeatures.size) {
      const hasTcfv2 = currentUser.accountFeatures.includes('tcf_v2');
      const hasccpa = currentUser.accountFeatures.includes('ccpa');
      const hasusnat = currentUser.accountFeatures.includes('usnat');
      const hasAppleMessaging = currentUser.accountFeatures.includes('apple_messaging');
      const hasAdblockFeature = currentUser.accountFeatures.includes('adblock_v2');

      let messageType = checkList.messageType;
      if (!hasTcfv2) {
        messageType = messageType.filter(m => m != 'gdpr');
      }
      if (!hasccpa) {
        messageType = messageType.filter(m => m != 'ccpa');
      }
      if (!hasusnat) {
        messageType = messageType.filter(m => m != 'usnat');
      }
      if (!hasAppleMessaging) {
        messageType = messageType.filter(m => m != 'ios');
      }
      if (!hasAdblockFeature) {
        messageType = messageType.filter(m => m != 'adblock');
      }
      checkList.messageType = messageType;
    }
    return checkList;
  };

  render() {
    const columns = [
      {
        title: 'Description',
        dataIndex: 'name',
        key: 'description',
        align: 'left',
        sorter: (a, b) => a.name.localeCompare(b.name),
        className: 'temp-record-style'
      },
      {
        title: 'Message Type',
        dataIndex: 'message_category_id',
        align: 'center',
        key: 'message-type',
        sorter: (a, b) => {
          const messageCategoryA = this.keyByVal(categoryMap, a.message_category_id);
          const messageSubcategoryA = this.keyByVal(subcategoryMap, a.message_sub_category_id);
          const messageTypeA = this.getModalTitle(messageCategoryA, messageSubcategoryA).toLowerCase();
          const messageCategoryB = this.keyByVal(categoryMap, b.message_category_id);
          const messageSubcategoryB = this.keyByVal(subcategoryMap, b.message_sub_category_id);
          const messageTypeB = this.getModalTitle(messageCategoryB, messageSubcategoryB).toLowerCase();
          return messageTypeA.localeCompare(messageTypeB)
        },
        className: 'temp-record-style',
        render: (category_id, record) => {
          let messageCategory = this.keyByVal(categoryMap, category_id);
          if (category_id === 7) {
            messageCategory = 'preferences'
          }
          const messageSubcategory = this.keyByVal(subcategoryMap, record.message_sub_category_id);
          const messageType = this.getModalTitle(messageCategory, messageSubcategory);
          return messageType;
        }
      },
      {
        title: 'Date Created',
        dataIndex: 'created_at',
        key: 'date-created',
        align: 'center',
        defaultSortOrder: 'descend',
        className: 'temp-record-style',
        sorter: (a, b) =>
          moment(a.created_at).valueOf() - moment(b.created_at).valueOf(),
        render: created_at => moment(created_at).format('YYYY-MM-DD')
      },
      {
        title: 'Date Modified',
        dataIndex: 'updated_at',
        key: 'modified',
        align: 'center',
        className: 'temp-record-style',
        sorter: (a, b) =>
          moment(a.updated_at === null ? a.created_at : a.updated_at).valueOf() - moment(b.updated_at === null ? b.created_at : b.updated_at).valueOf(),
        render: (updated_at, template) => (updated_at === null) ? moment(template.created_at).format('YYYY-MM-DD') : moment(updated_at).format('YYYY-MM-DD')
      },
      {
        title: 'ID',
        dataIndex: 'id',
        align: 'center',
        key: 'ID',
        sorter: (a, b) => a.id - b.id,
        className: 'temp-record-style'
      },
      {
        title: 'Template Type',
        dataIndex: 'is_system_template',
        key: 'template-type',
        align: 'center',
        className: 'temp-record-style',
        render: template_type => (template_type ? 'System' : 'Account')
      },
      {
        title: 'Status',
        dataIndex: 'is_draft',
        align: 'center',
        className: 'temp-record-style',
        key: 'status',
        render: (status, template) => {
          const style =
            status === 'PUBLIC'
              ? {
                backgroundColor: '#3FCC97',
                color: '#FFF',
                marginBottom: '2px'
              }
              : { marginBottom: '2px' };
          return (
            <div className="status-container">
              <Switch
                style={style}
                checked={!status}
                onChange={(status, e) => this.changeStatus(e, status, template)}
              />
              {status ? 'Draft' : 'Public'}
            </div>
          );
        }
      },
      {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (action, template) => (
          <div>
            <button
              className="avo-dialogue-icons avo-sp-edit"
              onClick={() =>
                this.props.showBuilder(template, this.props.isPreviewOnly)
              }
            />
            <button
              className="avo-dialogue-icons avo-sp-delete"
              onClick={(e) => this.toggleWarningMessage(e, template)}
            ></button>
          </div>
        )
      }
    ];

    let warningMessage;
    if (this.state.warningMessagePresent) {
      warningMessage = (
        <WarningMessage
          handleDelete={(e) => this.deleteTemplate(e, this.state.deleteMsgDet)}
          name={this.state.deleteMsgDet.name}
          handleCancel={this.toggleWarningMessage}
        />
      );
    }

    const columnAccount = columns.filter(col => col.key !== 'template-type');
    const options = this.getFilterOptions();
    const defaultChkList = this.getDefaultOptions();

    return (
      <div className="notice-table-template">
        <div className="card nt-card">
          <div className="top-container">
            <Search
              placeholder="Search"
              className="template-search"
              value={this.props.searchText}
              onChange={e => this.props.updateSearch(e)}
            />
            <CustomFilter
              filterCondition={this.props.filterTemplates}
              options={options}
              defaultCheckedList={defaultChkList}
            />
          </div>
          <Table
            className="template-table-container"
            dataSource={this.props.templates}
            pagination={{
              itemRender: this.itemRender,
              total: this.props.templates.size,
              showTotal: (total) => `Total Templates: ${total}`,
              size: 'small',
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30'],
              locale: { items_per_page: ' Records per page' },
            }}
            columns={(this.props.accountId === 22) ? columns : columnAccount}
            filtered
            onRow={template => {
              return {
                onClick: e => this.showBuilder(e, template)
              };
            }}
          />
        </div>
        {warningMessage}
      </div>
    );
  }
}
