import React, {useEffect, useState} from "react"
import classNames from "classnames";
import _ from "lodash";
import { usePrevious } from "../../../hooks";
import { CheckCircleFilled } from "@ant-design/icons";
import { renderInfoTip } from "../../consent/us_privacy_regulation/helper";
import { Map } from "immutable";

const getMainStepStatus = (step, returnMoreInfo = false) => {
  if (step.get('steps')) {
    if (step.get('steps').every(s => s.get('status') === 'wait')) 
      return 'wait';
    else if (step.get('steps').every(s => s.get('status') === 'finish'))
      return 'finish';
    else 
      return 'process';
  } else {
    return returnMoreInfo ? (step.get('status') + ' single-step') : step.get('status'); 
  }
};

const getMainStepIcon = (status, i ) => {
  if (status === 'finish') {
    return <CheckCircleFilled />;
  }
  return i + 1;
};

const SequentialLeftNav = (props) =>{
  const {stepsData, onChange} = props;
  const [steps, setSteps] = useState()


  const stepSetter = (updatedStepsData) => {
    let steps = [];
  
    updatedStepsData.forEach((step, i) => {
      let icon = null;
      let label = null;
  
      if (step.get('label')) {
        icon = <div className="icon-wrapper"><div className="icon">{getMainStepIcon(getMainStepStatus(step), i)}</div></div>;
        label = <div className="title">{step.get('label')}</div>;
        let warningIcon = step.get('warning') ? renderInfoTip("Changes Detected", "warning") : null;
        warningIcon = step.get('error') ? renderInfoTip("Error(s) found", "error-icon") : warningIcon;
        steps.push(
          <div className={`main-step ${getMainStepStatus(step, true)} pointer`} onClick={getMainStepStatus(step) === 'wait' ? null : () => onChange(step.get('steps') ? step.getIn(['steps', 0, 'key']) : step.get('key'))}>
            {icon}
            {label}
            {warningIcon}
          </div>
        );
      }
  
      if (step.get('steps')) {
        step.get('steps').forEach(subStep => {
          let subIcon = <div className="icon-wrapper"><div className="icon"></div></div>;
          let subLabel = <div className="title">{subStep.get('title')}</div>;
          let subWarningIcon = subStep.get('warning') ? renderInfoTip("Changes Detected", "warning") : null;
          subWarningIcon = subStep.get('error') ? renderInfoTip("Error(s) found", "error-icon") : subWarningIcon;
          steps.push(
            <div className={`sub-step ${subStep.get('status')} pointer`} onClick={subStep.get('status') === 'wait' ? null : () => onChange(subStep.get('key'))}>
              {subIcon}
              {subLabel}
              {subWarningIcon}
            </div>
          );
        });
      }
    });
    setSteps(steps);
  };

  useEffect(()=>{
    stepSetter(stepsData)
  },[stepsData, props.entityState]);

  return (
    <div className="sequential-navigation">
      {steps}
    </div>
  )
}
export default SequentialLeftNav;

export const updateStepsDataFunction = (updatedStepsData, targetKey, fieldToUpdate, updatedField) => {
  // let stepsData = List(updatedStepsData);

  const stepsData = updatedStepsData.map(item => {
    if (item.get('steps')) {
      const stepIndex = item.get('steps').findIndex(step => step.get('key') === targetKey);
      if (stepIndex !== -1) {
        let updatedStep = item.getIn(['steps', stepIndex]).set(fieldToUpdate, updatedField);
        return item.setIn(['steps', stepIndex], updatedStep);
      }
    } else if (item.get('key') === targetKey) {
      let updatedItem = item.set(fieldToUpdate, updatedField);
      return updatedItem;
    }
    return item;
  });

  return stepsData;
};

export const getOrderedStepsKeys = (sd) => {
  const resultArray = sd.flatMap((item) => {
    if (item.get('steps')) {
      return item.get('steps').map((step) => step.get('key'));
    } else {
      return [item.get('key')];
    }
  }).toArray();

  return resultArray;
};

export const getKeyForUnderProcessStep = (immutableData) => {
  let keyForProcessStatus = null;
  
  immutableData.forEach(mainStep => {
    if (mainStep.get('steps')) {
      mainStep.get('steps').forEach(nestedStep => {
        if (nestedStep.get('status') === 'process') {
          keyForProcessStatus = nestedStep.get('key');
        }
      });
    } else if (mainStep.get('status') === 'process') {
      keyForProcessStatus = mainStep.get('key');
    }
  });

  return keyForProcessStatus;
};

export const hasFinishedStatus = (data) => {
  if (data.get('status') === 'finished') {
      return true;
  }

  if (data.has('steps')) {
      return data.get('steps').some(step => hasFinishedStatus(step));
  }
  return false;
}

export const getStepsDataFieldMap = (stepsData, field) => {
  return stepsData.reduce((map, item, index) => {
    if (item.get('steps')) {
      item.get('steps').forEach(step => {
        map = map.set(step.get('key'), step.get(field));
      });
    } else {
      map = map.set(item.get('key'), item.get(field));
    }
    return map;
  }, Map());
};
