import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Space, Modal, Typography, Row, Col, Button, Select, Input, message, Divider, Checkbox, Alert, Flex, Spin } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LinkOutlined, StopOutlined } from '@ant-design/icons';
import { createVendorConnectionAjax, deleteVendorConnectionAjax, redirectForOAuth, saveOauthCodeWithVendorConnectionId, testVendorConnection } from '../../../../api/preferences';
import { VendorConnection } from '../../../../records/preferences_records';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const { Text, Title } = Typography;

const AddEditConnection = (props) => {
  const {
    showAddEditConnectionModal,
    closeModal,
    mode,
    addVendorConnection,
    accountId,
    selectedConnection,
  } = props;

  const [vendorConnection, setVendorConnection] = useState(mode === "edit" ? selectedConnection : new VendorConnection({}));
  const [testLoading, setTestLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [status, setStatus] = useState({ ok: false, msg: "" });
  const [oauthCode, setOauthCode] = useState(null);
  const [oauthStatus, setOauthStatus] = useState(mode === "edit" ? selectedConnection?.status : { ok: false, msg: null });
  const [continueToCategoriesModal, setContinueToCategoriesModal] = useState(true);
  const methodCallCount = useRef(0);

  const systemVendors = useSelector(state => state.preferencesState.getIn(['vendors', 'value']));
  const vendorConnectionsSavePending = useSelector(state => state.preferencesState.get('savePending'));

  const handleMessage = useCallback((event) => {
    if (event.source.name === "redirect-for-oauth") {
      setOauthCode(event.data);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    if (oauthCode) {
      (async () => {
        try {
          const resp = await createVendorConnectionAjax(vendorConnection?.toJS());
          if (resp?.connectionId) {
            setVendorConnection(resp);
            try {
              await saveOauthCodeWithVendorConnectionId(resp.vendorId, accountId, oauthCode, resp.connectionId);
              setOauthStatus({ ok: true, msg: "Authenticated" });
            } catch (err) {
              setOauthStatus({ ok: false, msg: err.message });
            }
          }
        } catch (err) {
          setOauthStatus({ ok: false, msg: err.message });
        } finally {
          setTestLoading(false);
        }
      })();
    }
  }, [oauthCode]);

  const handleCloseModal = useCallback(() => {
    if (mode === "add" && vendorConnection?.connectionId) {
      deleteVendorConnectionAjax(accountId, vendorConnection.connectionId)
        .then((resp) => {
          if (resp.dateDeleted) {
            closeModal();
          }
        })
        .catch(() => {
          alert("Something went wrong");
        });
    } else {
      closeModal();
    }
  }, [mode, vendorConnection, accountId, closeModal]);


  const title = (mode === 'add') ? 'New Connection' : 'Edit Connection';
  const connName = vendorConnection?.name?.trim()
  const connectionNameError = !Boolean(connName) ? <>Please enter a Connection name </>:  props.venConnNamesList.includes(connName) ? <>Connection with name <b>{connName}</b> already exists</> : null ;
  const vendorIdError = systemVendors?.find((vendor) => vendor?.id === vendorConnection?.vendorId) ? null : <>Please select an Integration</>;
  const clientIdError = Boolean(vendorConnection?.authConfig?.get("clientId")?.trim()) ? null : <>Please enter a Client Id</>;
  const clientSecretError = Boolean(vendorConnection?.authConfig?.get("clientSecret")?.trim()) ? null : <>Please enter Secret Code</>;
  const hubspotPortalIdError = Boolean(vendorConnection?.portalId?.trim()) ? null : <>Please enter your Hubspot' Portal ID</>;
  
  const handleVendorConnectionTest = () => {
    if(connectionNameError || vendorIdError || clientIdError || clientSecretError || hubspotPortalIdError) {
      setFormError(true);
    } else {
      methodCallCount.current += 1;
      setTestLoading(true);
      redirectForOAuth(vendorConnection?.vendorId, accountId, vendorConnection?.authConfig?.get("clientId"))
        .then((resp) => {
          if (resp.url) {
            const oauthWindow = window.open(resp.url, "redirect-for-oauth", 'popup');
            const checkWindowClosed = setInterval(() => {
              if (oauthWindow.closed) {
                clearInterval(checkWindowClosed);
                setTestLoading(false);
              }
            }, 500); // Check every 500ms
          } else {
            setTestLoading(false);
          }
        })
        .catch((err) => {
          setOauthStatus({ ok: false, msg: err.message });
          setTestLoading(false);
        });
        setFormError(false);
    }
  }

  const handleAddEditConnection = (mode) => {
    if(connectionNameError || vendorIdError || clientIdError || clientSecretError || hubspotPortalIdError) {
      setFormError(true);
    } else {
      addVendorConnection(vendorConnection, mode, continueToCategoriesModal)
      setFormError(false)
    }
  }

  let footer = [
    <Button type='default' onClick={handleCloseModal}>Cancel</Button>,
    <Button type='primary' loading={vendorConnectionsSavePending} onClick={() => handleAddEditConnection(mode)} disabled={mode == "add" && !oauthStatus.ok}>Save</Button>,
  ];

  if(props.linkedPrefConfigs) {
    footer = <Alert message={<Text> Cannot Edit this Connection as it is linked to the following Preferences Configurations : <b>{props.linkedPrefConfigs?.map((conn) => conn.name)?.toJS()?.join(", ")}</b></Text>} type="error" showIcon />
  }

  return (
    <Modal
      title={title}
      open={showAddEditConnectionModal}
      wrapClassName={classNames(`edit-add-connection`, {'footer-left' : props.linkedPrefConfigs})}
      width='40%'
      closable={true}
      destroyOnClose={true}
      onCancel={() => handleCloseModal()}
      confirmLoading={vendorConnectionsSavePending}
      maskClosable={false}
      footer={footer}
    >
      <Spin spinning={vendorConnectionsSavePending}>
      <Row gutter={[32]} className='row-gutter'>
        <Col span={12} >
          <Text>Integration (Vendor)</Text>
          <Select
            style={{display:"block"}}
            className="connection-search"
            placeholder="Select Integration"
            options={systemVendors?.map((v, i) => ({
              label: v?.name,
              value: v?.id,
            }))?.toJS()}
            value={vendorConnection?.vendorId}
            onChange={(value) => setVendorConnection((venConn) => venConn.set("vendorId", value))}
            disabled={oauthStatus.ok}
            status={formError && vendorIdError ? 'error' : null}
          />
          {formError && vendorIdError ? <Text type="danger">{vendorIdError}</Text> : null}
        </Col>
        <Col span={12}>
          <Text>Connection Name</Text>
          <Input
            placeholder="Connection Name"
            className="connection-search"
            value={ vendorConnection?.name}
            onChange={({target : {value}}) => setVendorConnection((venConn) => venConn.set("name", value))}
            status={formError && connectionNameError ? 'error' : null}
          />
          {formError && connectionNameError ? <Text type="danger">{connectionNameError}</Text> : null}
        </Col>
      </Row>
      <Row gutter={[32]} className='row-gutter'>
        <Col span={12} >
          <Text>Hubspot's Portal ID</Text>
          <Input
            placeholder="Enter Hubspot's Portal ID"
            className="connection-search"
            value={ vendorConnection?.portalId}
            disabled={oauthStatus.ok}
            onChange={({target : {value}}) => setVendorConnection((venConn) => venConn.set("portalId", value))}
            status={formError && connectionNameError ? 'error' : null}
          />
          {formError && hubspotPortalIdError ? <Text type="danger">{hubspotPortalIdError}</Text> : null}
        </Col>
      </Row>
      <Row gutter={[32]}>
        <Col span={24}>
        <Title level={5}>OAuth Details</Title>
        </Col>
      </Row>
      <Row gutter={[32]} className='row-gutter'>
        <Col span={12}>
          <Text>Client ID</Text>
          <Input
            placeholder="Client Id"
            className="api-input"
            value={vendorConnection?.authConfig?.get("clientId")}
            onChange={({target : {value}}) => setVendorConnection((venConn) => venConn.set('authConfig', venConn.get('authConfig').set('clientId', value)))}
            disabled={oauthStatus.ok}
            status={formError && clientIdError? 'error' : null}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
          {formError && clientIdError ? <Text type="danger">{clientIdError}</Text> : null}

        </Col>
        <Col span={12}>
          <Text>Secret Code</Text>
          <Input.Password
            type="password"
            placeholder="Secret Code"
            className="api-input"
            value={vendorConnection?.authConfig?.get("clientSecret")}
            onChange={({target : {value}}) => setVendorConnection((venConn) => venConn.set('authConfig', venConn.get('authConfig').set('clientSecret', value)))}
            disabled={oauthStatus.ok}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            status={formError && clientSecretError ? "error" : null}
            style={{height: "32px"}}
            size='large'
          />
          {formError && clientSecretError ? <Text type="danger">{clientSecretError}</Text> : null}
        </Col>
      </Row>
      <Row  className='row-gutter' justify="end">
        <Flex justify='flex-end' align='center'>
          {oauthStatus.ok && mode === "edit" ? (
          <Alert message={"Authenticated"} type="success" showIcon />
        ) : (
          <Col>
            {methodCallCount.current && !testLoading ? oauthStatus.ok ? (
              <Alert message={"Authenticated"} type="success" showIcon />
            ) : (
              <Text type='danger'><StopOutlined /> Error While Authenticating {" "}</Text>
            ) : null}
            {mode === "add" && !oauthStatus.ok ? (
              <Button
                type="default"
                onClick={handleVendorConnectionTest}
                loading={testLoading}
                icon={<LinkOutlined />}
                disabled={vendorIdError || clientIdError || clientSecretError || hubspotPortalIdError}
              >
                {testLoading ? "Waiting for authentication..." : "Authenticate with OAuth"}
              </Button>
            ) : null}
          </Col>
        )}
        </Flex>
      </Row>
      <Row>
        <Col>
          <Text><b>Note:</b> If the connection fails the test, you won't be able to pull categories from the connection.</Text>
        </Col>
      </Row>
      {props.mode == "add" ? (
        <>
          <Divider></Divider>
          <Row>
            <Col>
              <Checkbox checked={continueToCategoriesModal} onChange={({target : {checked}}) => setContinueToCategoriesModal(checked)}>
                {" "}
                <Text>Add <b>Connection Categories</b> after saving the Connection</Text>
              </Checkbox>
            </Col>
          </Row>
        </>
      ) : null}
      </Spin>
    </Modal>
  )
}

export default AddEditConnection
