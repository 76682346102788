import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { ConfigProvider, Modal, Form, Row, Col, Alert, Flex, message } from 'antd';
import moment from 'moment';
import enUS from 'antd/lib/locale/en_US';
import { useSelector } from 'react-redux';

import { Button, Tag } from '../../../../styleguide';
import AntdDatePicker from '../../../common/AntdDatePicker.jsx';
import DashboardSelectDropdown, { regionsTagRenderer } from '../shared/DashboardSelect';
import DatePickerFormat, { DateFormatDatePicker, customPanelRender } from '../shared/DatePicker.jsx';
import { getDiagnoseDetailsReport } from '../../../../api/diagnose_dashboard/diagnose_dashboard.js';

const { RangePicker } = AntdDatePicker;

function SubmitReportDataForm({ toggle, userDetails, plainRegionList, formattedRegions, propertiesList, selectedFilters }) {
  const metricsList = useSelector((store) => store.diagnoseDashboardState.getIn(['metricsList', 'value']));
  const selectedMetricsList = useSelector((store) => store.diagnoseDashboardState.getIn(['selectedMetrics', 'value']));
  const [pickerFormat, setPickerFormat] = useState('date');
  const [range, setRange] = useState([selectedFilters?.start, selectedFilters?.end]);
  const [properties, setProperties] = useState(selectedFilters.websites);
  const [regions, setRegions] = useState(selectedFilters.regions);
  const [quota, setQuota] = useState(0);

  const initialSelectedMetric = (metricList) => {
    const metric = {
      '/non_disclosed_vendors_observed': 'Non disclosed vendors observed',
      '/vendors_triggered_prior_to_consent': 'Vendors triggered prior to consent',
      '/disclosed_vendors': 'Disclosed Vendors',
      '/possible_fingerprinting': 'Possible Fingerprinting',
      '/data_leaving_the_EEA': 'Data leaving the EEA',
      '/cookies_with_long_lifespans': 'Cookies with long lifespans',
      '/all_cookies_found': 'All Cookies Found',
      '/vendors_after_opt_out': 'Vendors after opt out',
    }[location.pathname.split('diagnose_dashboard')?.[1]];
    return metric ? [metric] : metricList;
  };

  const [selectedMetrics, setSelectedMetrics] = useState(initialSelectedMetric(selectedMetricsList));
  const regionsProps = useMemo(() => {
    const isAllSelected = regions.length === plainRegionList?.length;
    const extractZoneFromRegion = (region) => region.split(':')[0];
    const uniqueZones = new Set(regions?.map(extractZoneFromRegion));

    let isUniqueZoneSelected = false;
    let uniqueZone = '';

    if (uniqueZones.size === 1) {
      const selectedZone = [...uniqueZones][0];
      const elementsFromSelectedZone = plainRegionList?.filter((region) => region.startsWith(`${selectedZone}:`));
      const allFromZoneSelected = elementsFromSelectedZone.every((element) => regions.includes(element));

      if (allFromZoneSelected) {
        isUniqueZoneSelected = true;
        uniqueZone = selectedZone;
      }
    }

    const maxTagPlaceholder = (omitted) => {
      const value = omitted
        ?.map((omit) => omit.title)
        ?.filter((title) => title?.toLowerCase() !== 'all')
        ?.join(', ');

      if (isAllSelected) {
        return <Tag label={'All'} value={value} noClass />;
      }

      if (isUniqueZoneSelected) {
        const zoneValue = formattedRegions
          ?.find((regionsWithZones) => regionsWithZones.value === uniqueZone)
          ?.children?.map((region) => region?.title)
          ?.join(', ');
        return <Tag label={uniqueZone} value={zoneValue} noClass />;
      }

      return <Tag label={`+${omitted.length}...`} value={value} noClass />;
    };

    const filterOption = (input, option) => {
      const isFound =
        option.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      return isFound;
    };

    return {
      selected: [...regions, ...(isAllSelected ? ['All'] : [])],
      maxTagCount: isAllSelected || isUniqueZoneSelected ? 0 : 1,
      maxTagPlaceholder,
      filterOption,
    };
  }, [formattedRegions, regions]);
  const handleSelectProperties = (value) => {
    const isPropertyAdded = properties.includes(value);
    if (value === 'All') {
      return setProperties(
        properties.length === propertiesList?.length ? [] : propertiesList.map((site) => site?.value),
      );
    } else if (properties.length + 1 > 20 && !isPropertyAdded) {
      return alert(
        'Please reduce the amount of sites selected in the property dropdown. You can select up to 20 sites at a time.',
      );
    } else if (isPropertyAdded) {
      return setProperties(properties?.filter((property) => property !== value));
    }
    return setProperties([...properties, value]);
  };
  const handleSelectRegions = (value) => {
    const [newZone, newRegion] = value.split(':');
    let newRegionsList = []
    if (value === 'All') {
      newRegionsList = regions.length !== plainRegionList.length ? plainRegionList : [];
    } else if (!newRegion && value !== 'All') {
      const zoneReions = plainRegionList.filter((region) => region.startsWith(`${newZone}:`));
      const [selectedFromZone, selectedInAnotherZone] = regions.reduce(
        ([selectedZone, anotherZone], region) => {
          if (region.startsWith(`${newZone}:`)) {
            selectedZone.push(region);
          } else {
            anotherZone.push(region);
          }
          return [selectedZone, anotherZone];
        },
        [[], []],
      );

      newRegionsList = 
        zoneReions?.length === selectedFromZone.length
          ? selectedInAnotherZone
          : [...regions, ...zoneReions.filter((child) => !regions.includes(child))];
    } else {
      newRegionsList = regions.includes(value) ? regions.filter((region) => region !== value) : [...regions, value];
    }
    selectedMetrics.includes('Vendors after opt out') && newRegionsList.every(regionItem => !regionItem.includes('US'))
      && setSelectedMetrics(selectedMetrics.filter(metric => metric !=='Vendors after opt out'));
    setRegions(newRegionsList);
    localStorage.setItem('diagnoseSelectedBenchmark', JSON.stringify([]));
  };
  const handleDateChange = (dates) => {
    setRange(
      pickerFormat === 'month'
        ? [moment.utc(dates[0]).startOf('month'), moment.utc(dates[1]).endOf('month')]
        : [...dates],
    );
  };
  const handleClearProperties = () => setProperties([]);
  const handleClearRegions = () => setRegions([]);

  const rangePickerFooter = useCallback(() => {
    return <DatePickerFormat pickerFormat={pickerFormat} changePickerFormat={setPickerFormat} />;
  }, [pickerFormat, setPickerFormat]);

  const handleSelectMetrics = (value) => {
    if (value === 'All') {
      setSelectedMetrics(selectedMetrics.length === metricsList?.length ? [] : [...metricsList]);
    } else {
      setSelectedMetrics(
        selectedMetrics.includes(value)
          ? selectedMetrics.filter((metric) => metric !== value)
          : [...selectedMetrics, value],
      );
    }
  };
  const handleSubmit = async (data) => {
    try {
      const res = await getDiagnoseDetailsReport({ 
        email: userDetails.email,
         accountId: userDetails.accountId,
        dateFrom: data.dates[0].format('YYYY-MM-DD'),
        dateTo: data.dates[1].format('YYYY-MM-DD'),
        properties: data.properties,
        region: data.regions,
        metric: data.metrics
      });
      setQuota(res.quota);
      message.success(res.msg);
    } catch(err) {
    }
  }

  const metricsFIltered = regionsProps.selected.some(region => region.includes('US'))
    ? metricsList : metricsList?.filter(metric =>  metric !== 'Vendors after opt out');

  const metricOptions = metricsFIltered.map((metric) => ({ title: metric, value: metric }))

  return (
    <>
    {quota ? <div style={{ paddingBottom: '10px' }}>
      <Alert
        message={
          <div>
            Monthly reporting quota: <b>{quota} out of 20 reports are remaining</b>
          </div>
        }
        banner
      />
    </div> : null}
    <Form
      name="report"
      className="diagnose_filters"
      onFinish={handleSubmit}
      onFinishFailed={() => console.log('Request Form Data Error')}
      layout="vertical"
      initialValues={{
        dates: range,
      }}
      fields={[
        { name: ['properties'], value: properties },
        { name: ['regions'], value: regions },
        { name: ['dates'], value: range },
        { name: ['metrics'], value: selectedMetrics },
      ]}
      requiredMark={'optional'}
    >
      <Row gutter={[16, 16]}>
        <Col span={11}>
          {/* properties */}
          <Form.Item
            label="Properties:"
            name="properties"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <DashboardSelectDropdown
              options={propertiesList}
              selected={[
                ...properties,
                ...(propertiesList?.length && properties.length === propertiesList?.length ? ['All'] : []),
              ]}
              onSelect={handleSelectProperties}
              onClear={handleClearProperties}
              placeholder={'Properties'}
              showAll={propertiesList?.length < 21}
            />
          </Form.Item>
        </Col>
        <Col span={11}>
          {/* regions */}
          <Form.Item
            label="Regions:"
            name="regions"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <DashboardSelectDropdown
              showSearch={false}
              options={formattedRegions}
              selected={regionsProps.selected}
              onSelect={handleSelectRegions}
              onClear={handleClearRegions}
              tagRender={regionsTagRenderer(formattedRegions)}
              placeholder={'Regions'}
              maxTagCount={regionsProps.maxTagCount}
              maxTagPlaceholder={regionsProps.maxTagPlaceholder}
              filterOption={regionsProps.filterOption}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={11}>
          {/* daterange */}
          <Form.Item
            label="Date range:"
            name="dates"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <ConfigProvider locale={enUS}>
              <RangePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                disabled={false}
                allowClear={false}
                picker={pickerFormat}
                size="medium"
                popupClassName="diagnose-range-calendar"
                className={`diagnose-range-input`}
                onChange={handleDateChange}
                renderExtraFooter={rangePickerFooter}
                defaultValue={range}
                value={range}
                format={DateFormatDatePicker}
                panelRender={customPanelRender}
                minDate={moment('2021-10-01')}
                maxDate={moment()}
              />
            </ConfigProvider>
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            label="Metrics:"
            name="metrics"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <DashboardSelectDropdown
              options={metricOptions}
              selected={[...selectedMetrics, ...(selectedMetrics.length === metricsList?.length ? ['All'] : [])]}
              onSelect={handleSelectMetrics}
              onClear={() => setSelectedMetrics([])}
              placeholder={'Metrics'}
              showAll
            />
          </Form.Item>
        </Col>
      </Row>
      <Flex justify='flex-end' gap={'20px'}>
        <Button onClick={toggle}>Close</Button>
        <Button type={'primary'} htmlType="submit">
          Request Report
        </Button>
      </Flex>
    </Form>
    </>
  );
}

export default function RequestReportModal({
  selectedFilters,
  plainRegionList,
  formattedRegions,
  propertiesList,
  open,
  toggle,
}) {
  const userDetails = useSelector((state) => state.accountState?.getIn(['userDetails', 'value']));

  return (
    <Modal
      wrapClassName="diagnose-dashboard"
      className="diagnose-modal avocado-modal"
      closeIcon={<CloseOutlined />}
      title={<h4 style={{ margin: 0 }}>Request Report</h4>}
      open={open}
      onCancel={toggle}
      footer={null}
      destroyOnClose
      width={769}
    >
      <p>
        View the metric details offline by requesting the report to be sent to your email: <b>{userDetails.email}</b>.
      </p>
      <SubmitReportDataForm
        toggle={toggle}
        userDetails={userDetails}
        formattedRegions={formattedRegions}
        plainRegionList={plainRegionList}
        propertiesList={propertiesList}
        selectedFilters={selectedFilters}
      />
    </Modal>
  );
}
