import React, { useEffect, useState } from 'react';
import {   } from '../../../../../styleguide';
import { Input, Table, Button, Typography, Modal, Space, Divider } from 'antd';
import classNames from 'classnames';
import { ConfirmationWrapper, wrapInInfotip } from "../../../helper";
import { useDebouncedSearch } from "../../../../../hooks";
import { SearchOutlined, DeleteOutlined, EditOutlined, DownloadOutlined, InfoCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

const CategoryTable = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [openBulkDeleteConfirmation, setOpenBulkDeleteConfirmation] = useState(false);
  const { dataSource, columns, checkStrictly, tableConfig, selectedRowKeys, setSelectedRowKeys, bulkDelete, readOnly } = props;
  
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(selectedRowKeys)
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  let categoriesData = dataSource.map((item, index) => ({
    ...item,
    key: item.categoryId || index,
  }));

  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);
  let data = searchValue.trim().length ? _.cloneDeep(categoriesData)?.filter(vendor => vendor.name.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1) : _.cloneDeep(categoriesData)
  const disableBulkActions = selectedRowKeys?.length === 0? 'disabled': null

  return (
    <>
      <div className="table-action-section">
      <div className="search-container">
        <Input
          placeholder="Search"
          type="search"
          onChange={({ target: { value } }) => {
            debouncedChangeHandler(value);
          }}
          suffix={<SearchOutlined />}
          size='large'
          style={{width: "250px", padding:'8px'}}
        />
        </div>
        {tableConfig?.isBulkEdit && (
          <div className="button-grp">
            <div className={classNames("bulk-actions")}>
                <Typography.Text disabled={disableBulkActions}>Bulk actions:</Typography.Text>
              {tableConfig.isBulkEdit.isEdit && (<Button icon={<EditFilled />} type="primary" className="icon" disabled={disableBulkActions}/>)}
              {tableConfig.isBulkEdit.isDelete && (
                wrapInInfotip(<Button type='link' icon={<DeleteOutlined/>} disabled={disableBulkActions} onClick={() => setOpenBulkDeleteConfirmation(true)}/>, "Remove Categories")
              )}
              <Divider type="vertical" orientation="center"/>
            </div>
          </div>
        )}
        {
          tableConfig?.btnConfig && (
            <Button className="btn" type="primary" onClick={() => tableConfig.btnConfig.action('Add',{})}>Add Category</Button>
          )
        }
    </div>
    <Table
      dataSource={data}
      columns={columns}
      className="preferences-category-table"
      rowSelection={!readOnly ?{
        ...rowSelection,
        checkStrictly,
      }: false}
      pagination={{
        position: ['bottomCenter'],
        showTotal: (total) => <div>Total Categories :{' '}<b>{total}</b></div>,
        size: 'small',
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '50', '75', '100'],
        locale: { items_per_page: ' Records per page' },
      }}
      />
      <Modal
        open={openBulkDeleteConfirmation}
        title={null}
        okText={'Yes'}
        cancelText={'No'}
        onOk={() => {props.bulkDelete(selectedRowKeys); setOpenBulkDeleteConfirmation(false)}}
        onCancel={() => {setOpenBulkDeleteConfirmation(false)}}
      >
        <Space> <Typography.Title type='warning' level={5}><ExclamationCircleFilled/></Typography.Title> <Typography.Text strong>Are you sure you want to remove selected Categories?</Typography.Text></Space>
      </Modal>
      </>
  )

}

export default CategoryTable;