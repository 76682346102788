import React, { useState,useRef, useEffect } from 'react'
import { Space, Modal, Typography, Row, Col, Button, message, Flex, Alert } from 'antd';
import ConnectionTable from "./ConnectionTable";
import { useSelector } from 'react-redux';
import { List, Map } from 'immutable';
import { VendorConnection } from '../../../../records/preferences_records';
import { getVendorConnectionCategories } from '../../../../api/preferences';
import classNames from 'classnames';

const {Text, Paragraph} = Typography;



const ConnectionCategoriesModal = (props) => {

  const {
    showConnectionCategoriesModal,
    closeModal,
    addVendorConnection
  } = props;
  const [vendorConnection, setVendorConnection] = useState(props.selectedConnection ?? new VendorConnection({}));
  const [loading, setLoading] = useState(false);
  const [pulledCategories, setPulledCategories] = useState(null);
  const [pulledCatsOverlap, setPulledCatsOverlap] = useState([]);//not used anymore but keeping for later.
  const [pullError, setPullError] = useState(false);
  const methodCallCount = useRef(0); // Initialize the counter
  const addCategoriesRef = useRef(null);
  const vendorsHash = useSelector(state => state.preferencesState.getIn(['vendorsHash', 'value']));
  const vendorConnectionsSavePending = useSelector(state => state.preferencesState.get('savePending'))

  useEffect(()=>{
    setVendorConnection(props.selectedConnection)
  },[props.selectedConnection])

  useEffect(()=>{
    if(pulledCategories && pulledCategories.length) {
      let overLaps = [];
      setVendorConnection(vendorConnection.update('categories', (categories) => {
        let updatedCategories = categories;
        pulledCategories.forEach((cat) => {
          let pulledCatIndex = updatedCategories.findIndex(c => c.id === cat.id);
          if(pulledCatIndex !== -1) {
            const existingCat = updatedCategories.find(c => c.id === cat.id);
            if(existingCat && !existingCat?.isPulledFromVendor){
              updatedCategories = updatedCategories.set(pulledCatIndex, cat);
            }
            overLaps.push(cat);
          } else {
            updatedCategories = updatedCategories.unshift(cat)
          }
        })
        return updatedCategories;
      }));
    
      setPulledCatsOverlap(overLaps);
    }
  },[pulledCategories])

  const handleAddEditConnection = () => {
    addVendorConnection(vendorConnection, "edit")
  }

  const handleCategories = (categories) => {
    setVendorConnection(vendorConnection.set('categories', List(categories)))
  }
  const handleAddCategories = () => {
    if(addCategoriesRef.current) {
      addCategoriesRef.current.addNewCategory();
    }
  }

  const handleCloseModal = () => {
    closeModal()
  }

  const handleVendorConnectionTest = () => {
    methodCallCount.current += 1; // Increment the counter
    setLoading(true);
    getVendorConnectionCategories(vendorConnection?.connectionId).then((resp) => {
      setPulledCategories(resp.map(cat => ({...cat, isPulledFromVendor: true, newlyAdded: true})))
      setLoading(false)
    })
    .catch((err) => {
      message.error(err);
      setPullError(true);
      setLoading(false)
    })
  }
  
  let footer = [
    <Button type='default' onClick={handleCloseModal}>Cancel</Button>,
    <Button type='primary' loading={vendorConnectionsSavePending} onClick={handleAddEditConnection} disabled={vendorConnectionsSavePending}>Save</Button>,
  ];

  if(props.linkedPrefConfigs) {
    footer = <Alert message={<Text> Cannot Edit this Connection as it is linked to the following Preferences Configurations : <b>{props.linkedPrefConfigs?.map((conn) => conn.name)?.toJS()?.join(", ")}</b></Text>} type="error" showIcon />
  }

  return (
    <Modal
      title={"Connection Categories"}
      open={showConnectionCategoriesModal}
      wrapClassName={classNames(`edit-add-connection`, {'footer-left' : props.linkedPrefConfigs})}
      width='40%'
      closable={true}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      confirmLoading={vendorConnectionsSavePending}
      footer={footer}
    >
      <Row gutter={[32]} className='row-gutter'>
        <Col span={12} >
          <Flex vertical>
            <Text>Integration</Text>
            <Text strong>{vendorsHash.get(vendorConnection?.vendorId)?.name ?? "vendor deleted"}</Text>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex vertical>
            <Text>Connection</Text>
            <Text strong>{vendorConnection?.name}</Text>
          </Flex>
        </Col>
      </Row>
      <Row gutter={[32]} align={'middle'}>
        <Col span={18}>
          <Paragraph>You can enter the category IDs from the integration portal and assign names to them. This makes it easier to link these categories with the system categories.</Paragraph>
        </Col>
        <Col>
          <Button onClick={handleAddCategories}>+ Add</Button>
        </Col>
      </Row>
      <Row gutter={[32]} className='row-gutter'>
        <Col span={24}>
          {pulledCategories && methodCallCount.current ? (
            <Space direction='vertical' style={{width: "100%"}}>
              <Alert
                message={<Text>{pulledCategories?.length} category(s) pulled successfully</Text>}
                type='success'
                closable
                showIcon
              />
              {/* {pulledCatsOverlap.length ? (
                <Alert
                  message={<Text>Skipped {pulledCatsOverlap?.length} pulled category(s) that overlap with existing categories in the Connection.</Text>}
                  type='warning'
                  closable
                />
              ): null} */}
            </Space>

          ) : (
            <Alert
              message={
                <Flex vertical>
                  <Text>Check connection server for categories</Text>
                  {pullError && methodCallCount.current ? <Text style={{fontSize: 12}} type='danger'>Error fetching categories. Please recheck connection details & try again.</Text> : null}
                </Flex>
              }
              type="info"
              action={
                <Space>
                  <Button type="default" onClick={handleVendorConnectionTest} loading={loading}>
                    {pullError && methodCallCount.current ? "Retry" : "Pull Categories"}
                  </Button>
                </Space>
              }
            />
            )
          }
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <ConnectionTable categories={vendorConnection?.categories} handleCategories={handleCategories} ref={addCategoriesRef}/>
        </Col>
      </Row>
    </Modal>
  )
}

export default ConnectionCategoriesModal
