import PropTypes from 'prop-types';
import React from 'react';
import { Menu, Dropdown } from 'antd';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import { Link } from 'react-router';
import { browserHistory } from 'react-router';
import { MenuItem, MenuItemStatus, MenuDropdown, Divider } from './NavigationComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import helpIcon from '../../../assets/icons/help.svg';

export default class NavbarDropdownMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isAdmin: PropTypes.bool.isRequired,
    isSPUser: PropTypes.bool.isRequired,
    featureAccess: PropTypes.any,
  }

  static defaultProps = {
    className: '',
  }

  state = {
    visible: false,
  };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  }

  logOut = () => {
    const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

    return fetch(`${permissionsSvcBaseUrl}/authentication/sign-out`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        // this.props.clearCurrentUser();
        browserHistory.push('/users/sign_in');
      });
  };

  onlyPrivacyLensFeature = () => {
    return (
      !this.props.isSPUser
      && this.props.features
      && (this.props.features.size === 1 && this.props.features.includes('privacy_lens')
        || this.props.features.size === 2 && this.props.features.includes('privacy_lens') && this.props.features.includes('sentinel'))
    );
  }

  render() {
    const privacyLensOnly = this.onlyPrivacyLensFeature();

    const isAccessToTemplates = (this.props.featureAccess && this.props.featureAccess.size && this.props.featureAccess.includes('templates')) ||
      !this.props.featureAccess;
    const isAccessStatus = (this.props.featureAccess && this.props.featureAccess.size && this.props.featureAccess.includes('status_page')) ||
      !this.props.featureAccess;
    const isAdblock = (this.props.featureAccess && this.props.featureAccess.size && this.props.featureAccess.includes('adblock')) ||
      !this.props.featureAccess;

    const sites = this.props.isAdmin && !isAdblock ? (
      <MenuItem name="Analytics Domains" to="/account/domains" />
    ) : null;
    const users = this.props.isAdmin ? (
      <MenuItem name="Users" to="/user/invite" />
    ) : null;
    const manageTemplates = isAccessToTemplates ? (
      <MenuItem name="Manage Templates" to="/admin/system_templates" />
    ) : null;
    const superAdminSubMenu = (this.props.isSPUser && this.props.isAdmin) ? (
      <MenuItem name="New Account" to="/new_account" />
    ) : null;
    const adminSettings = (this.props.isSPUser && this.props.isAdmin) ? (
      <MenuItem name="Admin Settings" to="/admin/admin_settings" />
    ) : null;
    const changeLog = (
      !this.props.featureAccess
      || this.props.featureAccess.includes('Change log')
    )
      ? (
        <MenuItem name="Change Log" to="/admin/change_log" />
      ) : null;
    const switchUser = this.props.isSPUser || this.props.masqed? (
      <MenuItem name="Masquerade" to="/admin/masq" />
    ) : null;

    const statusLink = isAccessStatus ?
      <MenuItemStatus name="Status" to="/status" />
      : null;

    return (
    <>
      {privacyLensOnly ? null : (
        <MenuDropdown name="Help" placement="topLeft" icon={<SVG src={helpIcon} />} className="admin-settings">
          <MenuItem
            name="Documentation"
            onClick={() => {
              window.open("https://sourcepoint.zendesk.com/hc/en-us", "_blank");
            }}
          />
          <MenuItem
            name="Submit a Request"
            onClick={() => {
              window.open("https://docs.sourcepoint.com/hc/en-us/requests/new", "_blank");
            }}
          />
        </MenuDropdown>
      )}
      <MenuDropdown name="Super Admin" placement="topLeft" icon={<FontAwesomeIcon icon={faUserCog} />} className="admin-settings">
        <MenuItem name="Account" to="/account/edit" />
        {sites}
        {users}
        {manageTemplates}
        {superAdminSubMenu}
        {adminSettings}
        {changeLog}
        {switchUser}
        {statusLink}
        <MenuItem name="Sign Out" onClick={() => { this.logOut() }} />
      </MenuDropdown>
    </>
    );
  }
}
