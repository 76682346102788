import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import {
  Modal,
  Button,
  Tooltip,
  message as antMessage,
  Infotip,
  Checkbox,
  Select,
  Input
} from '../../../../styleguide';
import { Layout, List as ListView } from 'antd'
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faPlus,
  faTrashAlt,
  faMobileAlt,
  faWindowRestore,
  faUpload,
  faEye,
  faEyeSlash,
  faFileCode,
} from '@fortawesome/free-solid-svg-icons';

import CustomIcon from '../../../common/CustomIcon.js';
import { starters as messageStarters, requiredComponents } from 'message-preact';
import { List, Map } from 'immutable';

import Sidebar from './Sidebar/Sidebar';
import AddComponent from './Sidebar/AddComponent/AddComponent';
import Templates from './Templates/Templates';
import SaveTemplate from './Templates/SaveTemplate';
import PrivayComplianceModal from './PrivacyComplianceModal';
import ViewNavigation from './ViewNavigation'
import { MessageVariables, IdNameValue, SiteVariables } from '../../../../records/message_records';
import {
  replaceWithVariables,
  checkForRequiredComponents,
  scanMessages,
  getViolations,
  clearStartFocus,
  checkSupportedLanguages,
  includesComponent
} from '../../../../util';
import { canAddSet, NOTICE_URL, sampleMessages, COUNTRY_LANGUAGES } from '../../../../constants';
import { PmOverrideContext, DefaultLanguageContext, SelectedPrivacyManager, FirstParentSettingsContext } from '../../contexts';
import MissingRequiredModal from './Sidebar/MissingRequiredModal';
import SupportedLanguagesModal from './Sidebar/SupportedLanguagesModal';
import CustomCSS from './CustomCSS/CustomCSS';
import { complianceList, privacyManagerComplianceList, CHOICE_TYPES } from '../../../../constants';
import { CurrentComponentIndexContext, CurrentMessageViewContext, NeutralPmContext } from '../../../contexts';
import {
  choiceValidators,
  hasFeature,
  updatePmChoiceOptions,
  validateVendorCount,
} from '../../../utils';

const { Option } = Select;

const { Content } = Layout;

const shouldShowPrivacyManager = (messageType) => ['privacy-manager', 'privacy-manager-ott', 'notice-tcf-v2', 'ccpa_pm', 'us_pm', 'usnat_notice'].includes(messageType);
const shouldHideBorders = (messageType) => ['sar', 'dsarRetrieval'].includes(messageType);
const selectedPrivacyManagerKey = 'selected_privacy_manager';
const shouldShowSaveAsTemplate = (messageType) => ['gdpr-notice'].includes(messageType);
const template_params = { name: '', is_system_template: false, is_draft: false };

/* 
  privacyManagerIdHiddenVarriable is spelled incorrectly - can't change now
*/

const getStarter = (messageSubcategory) => {
  if (messageSubcategory === 'usnat_notice') {
    return messageStarters.us_pm;
  }
  return messageStarters[messageSubcategory];
};

class Builder extends Component {
  state = {
    messages: getStarter(this.props.messageSubcategory),
    components: getStarter(this.props.messageSubcategory),
    current: [0],
    currentComponentIndex: 0,
    activeKey: 'settings',
    currentMessage: 0,
    loaded: false,
    addComponentModalVisible: false,
    missingRequiredModalVisible: false,
    supportedLanguagesModalVisible: false,
    supportedLanguagesMissing: [],
    selectTemplates: false,
    editing: false,
    variables: new MessageVariables,
    mobile: false,
    customCSS: false,
    hideRowColumnBorders: shouldHideBorders(this.props.messageSubcategory),
    privacyComplianceMessages: [],
    navigateViewsExpanded: true,
    pmData: null,
    accountId: '',
    showPrivacyManager: shouldShowPrivacyManager(this.props.messageSubcategory),
    showSaveAsTemplate: shouldShowSaveAsTemplate(`${this.props.messageCategory}-${this.props.messageSubcategory}`),
    showCompleteCheckPrivacyModal: false,
    privacyComplianceStatus: false,
    showSampleMessagesModal: false,
    msg_id: '1',
    previousValue: '',
    saveAsTemplate: false,
    template_params,
    subviewIndex: 'category',
    includesNeutralPm: false,
    updatedPMMessages: [],
    showVcModal: false,
    languagesWithoutVC: [],
  }

  tempComponents = [];

  componentDidMount = () => {
    const { messageSubcategory, editing, currentUser } = this.props;
    const { accountId } = currentUser;
    let messages = _.cloneDeep(getStarter(messageSubcategory));
    let parsedComplianceMessages;
    let compliance_status = false;
    if (messageSubcategory === 'sar' || messageSubcategory === 'dsar') {
      messages[0].children = [];
    };

    let variables = new MessageVariables;
    if (editing) {
      let parsedMessages = (this.props.isTemplate) ? JSON.parse(editing.template_json) : JSON.parse(editing.message_json);
      parsedComplianceMessages = (editing.hasOwnProperty('compliance_json')) ? JSON.parse(editing.compliance_json) : [];
      compliance_status = (parsedMessages.hasOwnProperty('compliance_status')) ? parsedMessages.compliance_status : false;
      parsedMessages = parsedMessages.siblings || parsedMessages;
      messages = Array.isArray(parsedMessages) ? parsedMessages : [parsedMessages];
      variables = editing.variables || new MessageVariables;
      if (messageSubcategory === 'privacy-manager' && messages.length === 1) {
        messages.push({
          "type": "CustomPmView",
          "name": "Custom View",
          "settings": {},
          "children": []
        })
      }
    };

    this.setState({
      messages,
      accountId,
      components: [messages[this.state.currentComponentIndex]],
      current: [0],
      activeKey: 'settings',
      currentMessage: 0,
      loaded: true,
      editing: Boolean(editing),
      variables,
      privacyComplianceMessages: parsedComplianceMessages,
      chooseTemplateTypeModalVisible: false,
      privacyComplianceStatus: compliance_status,
      includesNeutralPm: includesComponent('PMTCFv2InlineNeutral', [messages[this.state.currentComponentIndex]][0]),
    });

    window.addEventListener('message', this.applyListener, false);
  }

  componentWillUnmount = () => {
    window.removeEventListener('message', this.applyListener)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.siteId !== this.props.siteId) {
      this.setState({
        current: [0],
        activeKey: 'settings',
        currentMessage: 0,
        loaded: true
      });
    }
    this.updateIframe()

    if (includesComponent('PMTCFv2InlineNeutral', this.state.components[0]) && !this.state.includesNeutralPm) {
      this.setState({ includesNeutralPm: true })
    } else if (!includesComponent('PMTCFv2InlineNeutral', this.state.components[0]) && this.state.includesNeutralPm) {
      this.setState({ includesNeutralPm: false })
    }
  }

  applyListener = ({ data = null }) => {
    switch (data.action) {
      case 'dev.add':
        return this.showModal();
      case 'dev.templates':
        return this.openTemplates();
      case 'dev.setCurrent':
        this.setState({ current: data.newCurrent });
        return this.changeTab('settings');
      case 'dev.remove':
        const current = data.current;
        const lastItem = current.pop();
        return this.setState({ current }, () => this.removeComponent(lastItem));
      case 'dev.setPmData':
        this.setState({ pmData: data.pmData });
    }
  }

  /** Validate choice options attached to buttons, defaults to return just the first error. */
  validateAndCleanChoiceOptions = (message) => {

    const { pmMessages, messageCategory, messageSubcategory, sitesUnderSg, pmListLegacy } = this.props;
    // make sure every PM choice option uses the top level PM if set
    if (message.settings[selectedPrivacyManagerKey]
      && message.settings[selectedPrivacyManagerKey].data
      && message.settings[selectedPrivacyManagerKey].data.privacy_manager_iframe_url) {
      message = updatePmChoiceOptions(
        message,
        message.settings[selectedPrivacyManagerKey]
      );
    }

    // only validate notice-tcf-v2 and ccpa notice messages
    if (
      (messageCategory !== 'gdpr' || messageSubcategory !== 'notice-tcf-v2') &&
      (messageCategory !== 'ccpa' || messageSubcategory !== 'notice') &&
      messageSubcategory !== 'usnat_notice'
    ) {
      return [message, List()];
    }

    let errors = List();
    const pmButtons = [];

    // attach an error to a component so we can highlight it to the user
    const addComponentError = (c, settingKey, error) => {
      errors = errors.push(error);
      c.settings._errors = c.settings._errors || {};
      c.settings._errors[settingKey] = [error];
    }

    const getErrors = (component, path = [0]) => {
      const { isApp } = this.props
      const children = component.children || [];

      for (let i = 0; i < children.length; i++) {
        const c = children[i];
        if (c.settings._errors) c.settings._errors = {};
        const currentPath = path.slice()
        currentPath.push(i)
        if (c.type === 'Button' && !(c.settings.choice_option && c.settings.choice_option.type)) {
          addComponentError(c, 'choice_option',
            {
              message: `"${c.settings.text}" button has no action, please add one or remove the button.`,
              path: currentPath
            }
          );

          break;
        } else if (c.type === 'Button') {
          const toValidate = [];

          if (c.settings.choice_option) {
            // Button
            toValidate.push([c, c.settings.choice_option, 'choice_option']);
          }

          for (let i = 0; i < toValidate.length; i++) {
            const [child, choice_option, settingsKey] = toValidate[i];

            if (choiceValidators[choice_option.type]) {
              if (choice_option.type == 12) {
                pmButtons.push(child);
              }

              let [valid, errorMessage] = choiceValidators[choice_option.type](choice_option, child, pmMessages, pmListLegacy, sitesUnderSg, isApp);

              if (!valid) {
                addComponentError(c, settingsKey, { message: errorMessage, path: currentPath });

                break;
              }
            }
          }
        }
        if (c.children && c.children.length > 0) getErrors(c, currentPath);
      }
    };

    // validate top level privacy manager setting first
    let [valid, errorMessage] = choiceValidators[CHOICE_TYPES.privacyManager](
      message.settings[selectedPrivacyManagerKey],
      message,
      pmMessages,
      pmListLegacy,
      sitesUnderSg,
      this.props.isApp
    );

    if (!valid) {
      addComponentError(message, selectedPrivacyManagerKey, { message: errorMessage, path: [0] });
    }

    // continue validating if we are finding all errors or we haven't found one yet
    if (errors.size === 0) {
      if (message.settings._errors) message.settings._errors = {};
      getErrors(message);
    }

    // disable this for now for "non-iab" messages
    if (false
      && messageSubcategory === 'notice-tcf-v2'
      && pmButtons.length === 0) {
      errors = errors.push({
        message: 'Message must contain a button linked to a Privacy Manager.',
        path: [0]
      });
    }

    return [message, errors];
  }

  handleVisibleChange = visible => {
    this.setState({ chooseTemplateTypeModalVisible: visible });
  };

  saveMessage = (skipLanguageValidation) => {
    const {
      messages,
      currentComponentIndex,
      components,
      variables,
      showPrivacyManager,
      saveAsTemplate,
      template_params
    } = this.state;
    const { saveMessage, messageSubcategory, saveTemplate, isTemplate } = this.props;
    // VALIDATE SAVE
    const missingLanguages = checkSupportedLanguages(components[0].settings.supportedLanguages, components[0]);
    if (missingLanguages && missingLanguages.length > 0 && skipLanguageValidation !== 'skip') {
      this.setState({ supportedLanguagesModalVisible: true, supportedLanguagesMissing: missingLanguages });
      return false;
    } else if (skipLanguageValidation === 'skip') {
      this.setState({ supportedLanguagesModalVisible: false, supportedLanguagesMissing: missingLanguages });
    }

    if (components[0].compliance_list === undefined) {
      let newlist = List()
      if (showPrivacyManager) {
        let complianceListnew = (
          messageSubcategory === 'privacy-manager' ||
          messageSubcategory === 'privacy-manager-ott' ||
          messageSubcategory === 'ccpa_pm' ||
          messageSubcategory === 'us_pm' ||
          messageSubcategory === 'usnat_notice'
        ) ?
          privacyManagerComplianceList : complianceList;
        complianceListnew.map((item) => {
          let obj = new Map();
          obj = obj.set(item.get('id'), item.get('status'));
          newlist = newlist.push(obj);
        })
      }
      components[0].compliance_list = newlist.toJS();
    }
    const updatedMessages = _.cloneDeep(messages);
    updatedMessages.splice(currentComponentIndex, 1, components[0]);
    const isAccountPartOfWave1 = hasFeature(this.props.currentUser, 'tcf2.2');
    if (messageSubcategory === 'notice-tcf-v2' && isAccountPartOfWave1) {
      const languagesWithoutVC = validateVendorCount(updatedMessages[0], this.state.components[0].settings);
      if (languagesWithoutVC.length) {
        return this.setState({
          showVcModal: true,
          languagesWithoutVC,
        });
      }
    }

    // validate message
    if (updatedMessages.length === 1) {
      if (!isTemplate) {
        const [updatedMessage, errorMessages] = this.validateAndCleanChoiceOptions(updatedMessages[0]);
        if (errorMessages.size) {
          // if we have errors show them in modal
          const modal = Modal.error({
            content: <div>{errorMessages.get(0).message}</div>,
            okText: 'Dismiss and fix',
            onOk: () => {
              this.setState({
                components: [updatedMessage],
                current: errorMessages.get(0).path,
              });
            },
            title: 'Message Errors found',
          })
        } else {
          // else save the validated and cleaned message
          (saveAsTemplate) ? saveTemplate(updatedMessages, variables, template_params) : saveMessage(updatedMessages, variables);
        }
      } else {
        saveMessage(updatedMessages, variables, template_params);
      }
    } else {
      if (saveAsTemplate || isTemplate) {
        if (saveAsTemplate) { saveTemplate(updatedMessages, variables, template_params); };
        if (isTemplate) { saveMessage(updatedMessages, variables, template_params); };
      } else {
        saveMessage(updatedMessages, variables);
      }
    }
  }

  setFromTemplate = (messages) => {

    const updatedMessages = this.state.messages.length > 1
      ? _.cloneDeep([...messages, this.state.messages[1]])
      : _.cloneDeep(messages)
    this.setState({
      messages: updatedMessages,
      components: _.cloneDeep(messages).slice(0, 1),
      current: [0],
      activeKey: 'settings',
      currentMessage: 0,
      loaded: true
    });
  }

  addToNotice = ({
    componentName,
    components,
    ndx,
    opts = {},
  }) => {
    if (!components) {
      this.tempComponents = [...this.state.components];
      components = this.tempComponents;
    }
    ndx = ndx || 0;
    const more = this.state.current.length > ndx + 1;
    const currentIndex = this.state.current[ndx];
    if (more) {
      return this.addToNotice({
        componentName,
        components: components[currentIndex].children,
        ndx: ndx + 1,
        opts,
      });
    } else {
      components[currentIndex].children.push({
        type: componentName,
        name: componentName,
        settings: {},
        children: [],
        ...opts,
      });
      this.setState({ components: this.tempComponents }, () => {
        this.tempComponents = [];
      });
    }
  }

  removeComponent = (index, components, ndx) => {
    if (!components) {
      this.tempComponents = [...this.state.components];
      components = this.tempComponents;
    }
    ndx = ndx || 0;
    const more = this.state.current.length > ndx + 1;
    const currentIndex = this.state.current[ndx];

    if (more) {
      return this.removeComponent(index, components[currentIndex].children, ndx + 1);
    } else {
      components[currentIndex].children.splice(index, 1);
      this.setState({ components: this.tempComponents }, () => {
        this.tempComponents = [];
      });
    }
  }

  removeCurrent = () => {
    const current = [...this.state.current];
    const lastItem = current.pop();
    this.setState({ current }, () => this.removeComponent(lastItem));
    this.changeTab('settings');
  }

  updateCSS = (value) => {
    const firstComponent = [...this.state.components];
    firstComponent[0].css = value;
    this.setState({ components: firstComponent });
  }

  updateSubview = (value) => {
    const clonedComponents = _.cloneDeep(this.state.components[0])

    const updatePreviewType = (child) => {
      if (child.type === 'OTTCards') {
        child.settings.detailsPreviewType = value
        return
      } else {
        child.children.forEach(updatePreviewType)
      }
    }

    clonedComponents.children.forEach(updatePreviewType)

    this.setState({ subviewIndex: value, components: [clonedComponents] })

    const myIframe = document.getElementById('builder-preview');
    myIframe.contentWindow.postMessage(JSON.stringify({
      name: 'SWITCH_DETAIL_VIEW',
      value
    }), '*');
  }

  highlightComponent = (path) => {
    if (path) {
      this.updateIframe({ highlight: JSON.stringify([...path]) });
    }
  }

  updateSetting = (setting, value, components, ndx) => {
    if (!components) {
      this.tempComponents = [...this.state.components];
      components = this.tempComponents;
    }
    ndx = ndx || 0;
    const more = this.state.current.length > ndx + 1;
    const currentIndex = this.state.current[ndx];
    let previousValue = this.state.previousValue;

    if (more) {
      return this.updateSetting(setting, value, components[currentIndex].children, ndx + 1);
    } else {
      if (setting === 'componentName') {
        components[currentIndex].name = value;
      } else if (setting === 'componentHandle') {
        components[currentIndex].handle = value;
      } else if (setting === 'startFocus' && value === true) {
        const toggledOff = clearStartFocus(this.tempComponents[0]);

        if (toggledOff) {
          antMessage.warn('Prev. component set to "First Focus" has been toggled off');
        };

        components[currentIndex].settings[setting] = value;
      } else {
        if (value != null && previousValue != null && (JSON.stringify(value) !== JSON.stringify(previousValue))) {
          this.setState({ privacyComplianceStatus: false }, () => {
            this.confirmPrivacyCheck(false);
          })
        }
        components[currentIndex].settings[setting] = value;
      }

      this.setState({ components: this.tempComponents, previousValue: value }, () => {
        this.tempComponents = [];
      });
    }
  }

  updateBulkSetting = (setting, value, components, ndx, path) => {
    if (!components) {
      this.tempComponents = [...this.state.components];
      components = this.tempComponents;
    }
    ndx = ndx || 0;
    const more = path.length > ndx + 1;
    const currentIndex = path[ndx];

    if (more) {
      return this.updateBulkSetting(setting, value, components[currentIndex].children, ndx + 1, path);
    } else {
      if (setting === 'componentName') {
        components[currentIndex].name = value;
      } else {
        components[currentIndex].settings[setting] = value;
      }
    }
    this.setState({ components: this.tempComponents }, () => {
      this.tempComponents = [];
    });
  }

  getGrandparents = (path, component, grandparents = []) => {
    if (!path.length) return [...grandparents, component.type];
    const grandparent = component.type;
    const nextIndex = path.shift();
    return this.getGrandparents(path, component.children[nextIndex], [...grandparents, grandparent]);
  }

  getCurrent = (ndx, components, path) => {
    const more = this.state.current.length > ndx + 1;
    const currentIndex = this.state.current[ndx];
    const currentComponent = components[currentIndex];
    path.push({ index: currentIndex, name: currentComponent.name });

    return more
      ? this.getCurrent(ndx + 1, currentComponent.children, path)
      : { current: currentComponent, path };
  }

  setCurrent = (newCurrent) => {
    this.setState({ current: newCurrent })
  }

  goToChild = (newIndex) => {
    let current = this.state.current;
    current.push(newIndex);
    this.setState({ current });
  }

  moveChild = (index, newIndex, components, ndx) => {
    if (!components) {
      this.tempComponents = [...this.state.components];
      components = this.tempComponents;
    }
    ndx = ndx || 0;
    const more = this.state.current.length > ndx + 1;
    const currentIndex = this.state.current[ndx];

    if (more) {
      this.moveChild(index, newIndex, components[currentIndex].children, ndx + 1);
    } else {
      components[currentIndex].children.splice(newIndex, 0, components[currentIndex].children.splice(index, 1)[0]);
      this.setState({ components: this.tempComponents }, () => {
        this.tempComponents = [];
      });
    }
  }

  moveIntoChild = (index, newIndex, components, ndx) => {
    if (!components) {
      this.tempComponents = [...this.state.components];
      components = this.tempComponents;
    }
    ndx = ndx || 0;
    const more = this.state.current.length > ndx + 1;
    const currentIndex = this.state.current[ndx];

    if (more) {
      this.moveIntoChild(index, newIndex, components[currentIndex].children, ndx + 1);
    } else {
      const copyObj = _.cloneDeep(components[currentIndex].children[index]);
      components[currentIndex].children[newIndex].children.push(copyObj);
      components[currentIndex].children.splice(index, 1);
      this.setState({ components: this.tempComponents }, () => {
        this.tempComponents = [];
      });
    }
  }

  cloneComponent = (index, components, ndx) => {
    if (!components) {
      this.tempComponents = [...this.state.components];
      components = this.tempComponents;
    }
    ndx = ndx || 0;
    const more = this.state.current.length > ndx + 1;
    const currentIndex = this.state.current[ndx];

    if (more) {
      this.cloneComponent(index, components[currentIndex].children, ndx + 1);
    } else {
      let copyObj = _.cloneDeep(components[currentIndex].children[index]);
      components[currentIndex].children.splice(index, 0, copyObj);
      this.setState({ components: this.tempComponents }, () => {
        this.tempComponents = [];
      });
    }
  }

  showModal = () => this.setState({ addComponentModalVisible: true })

  handleCancel = () => this.setState({ addComponentModalVisible: false })

  closeTemplates = () => this.setState({ selectTemplates: false })

  openTemplates = () => this.setState({ selectTemplates: true })

  showSaveTemplateModal = () => {
    const { messages } = this.state;
    const { messageSubcategory } = this.props;
    const theseRequiredComponents = requiredComponents[messageSubcategory];
    if (theseRequiredComponents && !checkForRequiredComponents(messages, theseRequiredComponents)) {
      return this.setState({ missingRequiredModalVisible: true });
    };
    this.setState({ saveTemplateModal: true });
  }

  hideSaveTemplateModal = () => this.setState({ saveTemplateModal: false })

  createTemplate = (templateType) => this.props.createTemplate(this.state.messages, templateType)

  updateIframe = (opts = {}) => {
    const updatedOpts = { ...opts };
    if (this.props.messageCategory === 'preferences') {
      const activePreferencesList = this.props.preferencesLists.find(({ isActive }) => isActive) || null
      updatedOpts.preferencesList = activePreferencesList;
    } 
    const myIframe = document.getElementById('builder-preview');
    myIframe.contentWindow.postMessage(JSON.stringify({
      components: replaceWithVariables(this.state.components, this.state.variables),
      current: this.state.current,
      siteId: this.props.sitesUnderSg.size
        ? this.props.sitesUnderSg.toJS()[0].id
        : this.props.siteId,
      accountId: this.state.accountId,
      devOpts: { hideRowColumnBorders: this.state.hideRowColumnBorders },
      hideTemplatesButton: this.props.messageSubcategory === 'privacy-manager' && this.state.currentComponentIndex > 0,
      ...updatedOpts
    }), '*');
  }

  updateVariables = (variables) => this.setState({ variables })

  toggleMobile = () => this.setState({ mobile: !this.state.mobile })

  toggleCustomCSS = () => this.setState({ customCSS: !this.state.customCSS })

  changeTab = (activeKey) => this.setState({ activeKey })

  changeCurrentComponentIndex = (index) => {
    const {
      messages,
      currentComponentIndex,
      components: [component]
    } = this.state;
    const updatedMessages = _.cloneDeep(messages);
    updatedMessages.splice(currentComponentIndex, 0, component)
    this.setState({
      currentComponentIndex: index,
      components: [messages[index]],
      current: [0],
      messages: [...messages]
    });
  };

  setDefaultPrivacyManagerVariable = (pmId) => {
    const { variables } = this.state;

    const pmVarName = '$$privacyManagerIdHiddenVarriable$$';
    const defaultPmIdVar = new IdNameValue({ name: pmVarName, value: pmId });
    const defaultVariables = variables.get('defaultVariables');

    const updatedDefaultVariables = !(defaultVariables.find(({ name }) => name === pmVarName))
      ? defaultVariables.push(defaultPmIdVar)
      : defaultVariables
        .filterNot(({ name }) => name === pmVarName)
        .push(defaultPmIdVar);

    const updatedVariables = variables
      .set('defaultVariables', updatedDefaultVariables)
      .update('sitesVariables', (sites) => sites.map((site) => (
        site.update('variables', (variables) => variables.filterNot(({ name }) => name === pmVarName)))
      ));

    this.setState({ variables: updatedVariables });
  }

  overridePrivacyManager = (siteId, pmId) => {
    const pmVarName = '$$privacyManagerIdHiddenVarriable$$';
    const sitePmIdVar = new IdNameValue({ name: pmVarName, value: pmId });

    this.setState(({ variables }) => {
      const sitesVariables = variables.get('sitesVariables');
      const site = sitesVariables.find(site => site.siteId === siteId);
      let updatedSitesVariables;
      if (!site) {
        const newSite = new SiteVariables({ siteId, variables: List([sitePmIdVar]) });
        updatedSitesVariables = sitesVariables.push(newSite);
      } else {
        const updatedVariables = site.variables
          .filterNot(variable => variable.name === '$$privacyManagerIdHiddenVarriable$$')
          .push(sitePmIdVar);
        const updatedSite = site.set('variables', updatedVariables);
        updatedSitesVariables = sitesVariables
          .filterNot(site => site.siteId === siteId)
          .push(updatedSite);
      };
      const updatedVariables = variables.set('sitesVariables', updatedSitesVariables);

      return { variables: updatedVariables };
    })
  }

  clearPrivacyManagerOverride = (siteId) => {
    const site = this.state.variables.sitesVariables.find(site => site.siteId === siteId);
    if (site) {
      const updatedSite = site.set('variables', site.variables.filterNot(variable => variable.name === '$$privacyManagerIdHiddenVarriable$$'));
      const updatedSitesVariables = this.state.variables.sitesVariables
        .filterNot(site => site.siteId === siteId)
        .push(updatedSite);

      this.setState({ variables: this.state.variables.set('sitesVariables', updatedSitesVariables) });
    }
  }

  /** Get PM for message. Look first at top level setting, else look at first PM button we find */
  getSelectedPrivacyManager = (message) => {
    //this.getSelectedPrivacyManager(this.state.components[0])
    if (message.type !== "Notice" && message.type !== "PrivacyManagerUS") {
      return null;
    }

    let selectedPmSetting = message.settings.selected_privacy_manager;

    if (!selectedPmSetting) {
      const getPmButton = (component) => {
        if (component.type === "Button"
          && component.settings.choice_option
          && component.settings.choice_option.type === 12) {
          return component;
        } else if (!component.children || component.children.length === 0) {
          return null;
        }
        for (let i = 0; i < component.children.length; i++) {
          let pmButton = getPmButton(component.children[i]);

          if (pmButton) {
            return pmButton;
          }
        }
      }

      let pmButton = getPmButton(message);

      if (pmButton) {
        selectedPmSetting = pmButton.settings.choice_option;
      }
    }

    return selectedPmSetting || { type: 12, data: {} };
  }

  closeMissingModalOpenAddModal = (selectedView) => {
    this.changeCurrentComponentIndex(this.state.messages.findIndex(({ name }) => selectedView === name));
    this.setState({ missingRequiredModalVisible: false, addComponentModalVisible: true, });
  }

  closeSupportedLanguagesModal = () => {
    this.setState({ supportedLanguagesModalVisible: false });
  }

  checkForFieldNames = () => {
    const x = this.state.messages.reduce((msgsWithViolations, message, index) => {
      const targetTypes = ['Input', 'Radio', 'Dropdown', 'DatePicker'];
      const rules = [{ field: 'fieldName', value: v => !!v, }];
      const violations = getViolations(message, targetTypes, rules);
      return [
        ...msgsWithViolations,
        violations.map(v => ({ ...v, location: [index, v.location] }))
      ]
    }, []);
  }

  highlight = (index) => this.updateIframe({ highlight: JSON.stringify([...this.state.current, index]) });

  updateDefaultLanguage = (language) => {
    const clonedComponents = _.cloneDeep(this.state.components).map(c => {
      delete c.settings.language;
      return {
        ...c,
        settings: { ...c.settings, defaultLanguage: language }
      };
    });

    function populateDefault(message) {
      message.settings.selectedLanguage = language;
      if (message.settings.languages && message.settings.languages[language]) {
        Object.entries(message.settings.languages[language]).forEach(([key, value]) => {
          message.settings[key] = value;
        })
      };
      message.children && message.children.forEach(c => populateDefault(c));
    }

    clonedComponents.forEach(populateDefault);

    const clonedMessages = _.cloneDeep(this.state.messages).map(c => {
      delete c.settings.language;
      return {
        ...c,
        settings: { ...c.settings, defaultLanguage: language }
      };
    });

    clonedMessages.forEach(populateDefault);

    this.setState({ components: clonedComponents, messages: clonedMessages });
  }

  updatePreviewLanguage = (language) => {
    const clonedComponents = _.cloneDeep(this.state.components).map(c => {
      delete c.settings.language;
      return {
        ...c,
        settings: { ...c.settings, previewLanguage: language }
      };
    });


    function populateDefault(message) {
      message.settings.selectedLanguage = language;
      if (message.settings.languages && message.settings.languages[language]) {
        Object.entries(message.settings.languages[language]).forEach(([key, value]) => {
          message.settings[key] = value;
        })
      };
      message.children && message.children.forEach(c => populateDefault(c));
    }

    clonedComponents.forEach(populateDefault);

    const clonedMessages = _.cloneDeep(this.state.messages).map(c => {
      delete c.settings.language;
      return {
        ...c,
        settings: { ...c.settings, defaultLanguage: language }
      };
    });

    clonedMessages.forEach(populateDefault);

    this.setState({ components: clonedComponents, messages: clonedMessages });
  }

  updateSupportedLanguages = (language) => {
    const clonedComponents = _.cloneDeep(this.state.components).map(c => {
      delete c.settings.supportedLanguages;
      return {
        ...c,
        settings: { ...c.settings, supportedLanguages: language }
      };
    });
    this.setState({ components: clonedComponents });
  }

  updateComponentsText = (selectedComponents, value) => {
    const clonedComponents = _.cloneDeep(this.state.components).map(c => {
      return {
        ...c,
        settings: { ...c.settings, translationComponent: value }
      };
    });
    this.setState({ components: clonedComponents });
  }

  addHiddenField = () => {
    const clonedComponents = _.cloneDeep(this.state.components);
    let { hiddenFields } = clonedComponents[0].settings;

    if (!hiddenFields) {
      clonedComponents[0].settings.hiddenFields = [];
      ({ hiddenFields } = clonedComponents[0].settings);
    };

    clonedComponents[0].settings.hiddenFields = [
      ...hiddenFields,
      { fieldName: '', fieldValue: '' },
    ];

    this.setState({ components: clonedComponents });
  }

  handleCompPrivacyModal = (visible) => {
    this.setState({ showCompleteCheckPrivacyModal: visible })
  }

  handleSampleMessagesModal = (visible) => {
    this.setState({ showSampleMessagesModal: visible })
  }
  removeHiddenField = (fieldIndex) => {
    const clonedComponents = _.cloneDeep(this.state.components);
    const { hiddenFields } = clonedComponents[0].settings;

    clonedComponents[0].settings.hiddenFields = [
      ...hiddenFields.slice(0, fieldIndex),
      ...hiddenFields.slice(fieldIndex + 1),
    ];

    this.setState({ components: clonedComponents });
  }

  updateHiddenField = (fieldIndex, key, value) => {
    const clonedComponents = _.cloneDeep(this.state.components);
    const { hiddenFields } = clonedComponents[0].settings;
    const targetField = hiddenFields[fieldIndex];

    clonedComponents[0].settings.hiddenFields = [
      ...hiddenFields.slice(0, fieldIndex),
      { ...targetField, [key]: value },
      ...hiddenFields.slice(fieldIndex + 1),
    ];

    this.setState({ components: clonedComponents });
  }

  saveCompliance = (compliance_list) => {
    const firstComponent = [...this.state.components];
    firstComponent[0].compliance_list = compliance_list.toJS()
    this.setState({ components: firstComponent })
  }

  saveTemplateType = (status, type) => {
    this.setState({ template_params: { ...this.state.template_params, is_system_template: status, is_draft: type }, chooseTemplateTypeModalVisible: false }, () => this.saveMessage());
  };

  confirmPrivacyCheck = (status) => {
    const firstComponent = [...this.state.components];
    firstComponent[0].compliance_status = status;
    this.setState({ Component: firstComponent, showCompleteCheckPrivacyModal: false, privacyComplianceStatus: status })
  }

  selectMessage = (id) => {
    this.setState({ msg_id: id })
  }

  copyMessage = () => {
    const message_id = this.state.msg_id;
    const message = sampleMessages.find((msg) => msg.get('id') === message_id)
    const el = document.createElement('textarea');
    el.value = message.get('description');
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  onChangeTemplateName = (name) => this.setState((prevState) => ({ template_params: { ...prevState.template_params, name } }));

  editTemplateParams = () => {
    const { editing } = this.props;
    return { is_draft: editing.is_draft, is_system_template: editing.is_system_template };
  }

  redirectToSystemTemplates = () => {
    browserHistory.push(`/admin/system_templates`);
  }

  handleMessageBuilderClose = () => {
    this.props.exit(this.state.components[this.state.currentComponentIndex])
  }

  generateNoticeUrl = () => {
    if (this.props.messageSubcategory === 'usnat_notice') {
      return `${NOTICE_URL}/us_pm/index.html?&type=dev&is_usnat_notice=true`
    } else {
      return `${NOTICE_URL}/${this.props.messageSubcategory === 'preferences' ? 'preferences-app' : this.props.messageSubcategory}/index.html?&type=dev`
    }
  }

  render() {
    const { messageCategory, messageSubcategory, currentUser, previewSites } = this.props;
    const { components, pmData, showPrivacyManager, showSaveAsTemplate, showVcModal, languagesWithoutVC } = this.state;
    const { current, path } = this.getCurrent(0, components, []);
    const [messageComponent] = components;
    const includesType = [
      'Notice',
      'Sar',
      'SuccessView',
      'ErrorView'
    ].includes(current.type);
    const overrideVariables = this.state.variables.sitesVariables.reduce((acc, curr) => {
      const { value } = curr.variables.find(variable => variable.name === '$$privacyManagerIdHiddenVarriable$$') || {};
      return value ? [...acc, { siteId: curr.siteId, privacyManagerIdHiddenVarriable: value }] : acc;
    }, []);

    const requiredComponentsWithStatus = (requiredComponents[messageSubcategory] && Object.entries(requiredComponents[messageSubcategory]) || [])
      .map(([viewId, components]) => {
        const message = this.state.messages.find(m => m.id === viewId);
        return {
          name: message.name,
          components: components.map(c => ({
            ...c,
            added: scanMessages(message, [c.schema])
          }))
        }
      });


    const supportedLanguages = this.state.components[0].settings.supportedLanguages

    const supportLanguageDetails = (supportedLanguages != undefined) ? COUNTRY_LANGUAGES.filter((lang) =>
      supportedLanguages.includes(lang.code)) : COUNTRY_LANGUAGES


    const isToolbardisable =
      messageComponent.children.length === 0 ? true : false;

    const systemOrDraft = this.state.accountId === 22 ? 'System Template' : 'Save As Draft';
    const accountOrPublic = this.state.accountId === 22 ? 'Account Template' : 'Save As Public';

    let sites = [];
    if (previewSites && previewSites.size) {
      sites = previewSites.filterNot(s => s.type === 'property_group').toJS();
    }

    const templateContent = (
      <React.Fragment>
        <Input
          value={this.state.template_params.name}
          onChange={(e) => this.onChangeTemplateName(e.target.value)}
          placeholder="Template name" />
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: '10px', justifyContent: "flex-end" }}>
          <Button
            onClick={() => this.state.accountId === 22 ? this.saveTemplateType(true, true) : this.saveTemplateType(false, true)}
            type="ghost"
            style={{ marginRight: '5px' }}
          >
            {systemOrDraft}
          </Button>
          <Button onClick={() => this.state.accountId === 22 ? this.saveTemplateType(false, true) : this.saveTemplateType(false, false)} type="ghost">
            {accountOrPublic}
          </Button>
        </div>
      </React.Fragment>
    );

    const isAccessToTemplates = (currentUser.featureAccess && currentUser.featureAccess.size && currentUser.featureAccess.includes('templates')) ||
      !currentUser.featureAccess;

    const isCategoryGdpr = messageCategory === 'gdpr';

    const isCCPA = messageCategory === 'ccpa';

    const content = (
      <div className="template-type-selection">
        <Button
          onClick={() => this.saveTemplateType(true, true)}
          type="ghost"
        >
          System Template
        </Button>
        <Button onClick={() => this.saveTemplateType(false, true)} type="ghost">
          Account Template
        </Button>
      </div>
    );

    const defaultLang = this.state.components[0].settings.defaultLanguage || this.state.components[0].settings.language || 'EN';
    let langString = '';
    if (languagesWithoutVC.length) {
      if (!(languagesWithoutVC.length === 1 && languagesWithoutVC[0] === defaultLang)) {
        langString = ' in ';
        languagesWithoutVC.forEach((lang, i) => {
          langString += COUNTRY_LANGUAGES.find(langFull => langFull.code === lang).language;
          if (i === languagesWithoutVC.length - 1) {
            languagesWithoutVC.length > 1 ? langString += '  supported languages' : langString += '  supported language'
          } else if (i === languagesWithoutVC.length - 2) {
            langString += ' and ';
          } else {
            langString += ', ';
          }
        })
      }
    }

    return (
      <React.Fragment>
        <Modal
          visible={showVcModal}
          title="Save Message - Error"
          onCancel={() => this.setState({ showVcModal: false })}
          footer={(
            <Button
              onClick={() => this.setState({ showVcModal: false })}
              key="submit"
              type="primary"
            >
              Close
            </Button>
          )}
        >
          <div>
            <b>{this.state.messages[0]?.name}</b>{` cannot be saved due to missing Vendor Count in the title content${langString}. Please Add VC from additional options present at the top of the text field.`}
          </div>
        </Modal>
        <div className="builder-title">
          <h3>
            Builder: {messageComponent.name}<span className='meesage-title-verticle-line'> | </span>{this.props.editing?.id ? (<span className='message-id'>ID: {this.props.editing?.id}</span>) : ""}
          </h3>
          {!this.props.isTemplate ? (
            <Button
              onClick={() => {
                this.setState({ saveAsTemplate: false }, () => {
                  this.saveMessage();
                })
              }}
              type="primary"
              disabled={this.props.isPreviewOnly}
            >
              {this.state.editing ? 'Save Message' : 'Create Message'}
            </Button>
          ) : (
            !this.state.editing
              ? (this.state.accountId === 22
                ? (
                  <Infotip
                    placement="bottom"
                    title={(
                      <div>
                        <span style={{ textAlign: 'center' }}>
                          Template will be saved in draft state.
                        </span>
                        <br />
                        <span>Select the type of template</span>
                      </div>
                    )}
                    content={content}
                    open={this.state.chooseTemplateTypeModalVisible}
                    onOpenChange={visible => this.handleVisibleChange(visible)}
                    trigger="click"
                  >
                    <Button
                      disabled={isToolbardisable}
                      type="primary"
                      onClick={null}
                    >
                      Create Template
                    </Button>
                  </Infotip>
                )
                : (
                  <Button
                    disabled={isToolbardisable}
                    type="primary"
                    onClick={() => this.saveTemplateType(false, true)}
                  >
                    Create Template
                  </Button>
                )
              ) : (
                <Button
                  disabled={isToolbardisable}
                  type="primary"
                  onClick={() =>
                    this.saveTemplateType(
                      this.editTemplateParams().is_system_template,
                      this.editTemplateParams().is_draft
                    )
                  }
                >
                  Save Template
                </Button>
              )
          )}
          <Button onClick={this.handleMessageBuilderClose} type="default">Close</Button>
          {(showPrivacyManager && !this.props.isTemplate) && !isCCPA && messageCategory !== 'usnat' && (
            <PrivayComplianceModal
              title={"Check if all the compliance points are addressed"}
              complianceStatus={this.state.privacyComplianceStatus}
              complianceMessages={this.state.privacyComplianceMessages}
              saveCompliance={this.saveCompliance}
              messageSubcategory={messageSubcategory}
              handleCompPrivacyModal={this.handleCompPrivacyModal}
            />
          )}
          <Tooltip title="Select Template">
            <Button
              onClick={this.openTemplates}
              type="default"
              shape="circle"
            >
              <FontAwesomeIcon icon={faWindowRestore} />
            </Button>
          </Tooltip>

          <Tooltip title="Toggle Mobile View">
            <Button
              onClick={this.toggleMobile}
              type={this.state.mobile ? 'primary' : 'default'}
              shape="circle"
            >
              <FontAwesomeIcon icon={faMobileAlt} />

            </Button>
          </Tooltip>
          <Tooltip title="Custom CSS">
            <Button
              onClick={this.toggleCustomCSS}
              type={this.state.customCSS ? 'primary' : 'default'}
              shape="circle"
            >
              <FontAwesomeIcon icon={faFileCode} />
            </Button>
          </Tooltip>
          {!this.props.isTemplate && isAccessToTemplates && !showSaveAsTemplate ?
            (
              <Infotip
                placement="bottom"
                className='save-template'
                title={
                  <React.Fragment>
                    <span style={{ textAlign: 'center', fontSize: '18px' }}>
                      <b>Save as Template</b>
                    </span>
                    <br />
                    <div>
                      Templates can now be managed in the
                      <span className="redirect-system-templates" onClick={this.redirectToSystemTemplates}>
                        Manage Templates
                        <CustomIcon type={CustomIcon.types.PREVIEW} />
                        <br />
                      </span>
                      section available in the Super Admin menu.
                    </div>
                  </React.Fragment>
                }
                content={templateContent}
                open={this.state.chooseTemplateTypeModalVisible}
                onOpenChange={visible => {
                  this.setState({ saveAsTemplate: true, template_params }, () => this.handleVisibleChange(visible))
                }}
                trigger="click"
              >
                <Button
                  disabled={
                    ['notice_non_iab', 'privacy_manager_non_iab'].includes(this.props.messageSubcategory)}
                  type="primary"
                  shape="circle"
                  disabled={isToolbardisable}
                >
                  <Tooltip title="Save as Template"><FontAwesomeIcon icon={faUpload} /></Tooltip>
                </Button>
              </Infotip>
            ) : null}
        </div>
        <Layout className="bulletin" style={{ minHeight: 'calc(100vh - 68px)' }}>
          <Layout>
            {!this.state.loaded
              ? <FontAwesomeIcon id="site-spinner" icon={faCircleNotch} spin />
              : (
                <React.Fragment>
                  <AddComponent
                    current={current}
                    addToNotice={this.addToNotice}
                    visible={this.state.addComponentModalVisible}
                    handleCancel={this.handleCancel}
                    grandparents={this.getGrandparents(this.state.current.slice(1), messageComponent)}
                    requiredComponents={requiredComponentsWithStatus}
                    currentView={components[0].name}
                    messageSubcategory={messageSubcategory}
                    messageCategory={this.props.messageCategory}
                  />
                  <MissingRequiredModal
                    visible={this.state.missingRequiredModalVisible}
                    views={requiredComponentsWithStatus}
                    handleCancel={() => this.setState({ missingRequiredModalVisible: false })}
                    handleOk={this.closeMissingModalOpenAddModal}
                    currentView={components[0].name}
                  />
                  <SupportedLanguagesModal
                    visible={this.state.supportedLanguagesModalVisible}
                    missing={this.state.supportedLanguagesMissing}
                    handleCancel={this.closeSupportedLanguagesModal}
                    handleOk={() => this.saveMessage('skip')}
                  />
                  <Templates
                    templates={this.props.templates}
                    accountId={this.props.currentUser.accountId}
                    setFromTemplate={this.setFromTemplate}
                    visible={this.state.selectTemplates}
                    handleCancel={this.closeTemplates}
                    siteId={this.props.siteId}
                    isTemplate={this.props.isTemplate}
                    currentUser={this.props.currentUser}
                    messageSubcategory={messageSubcategory}
                    messageCategory={this.props.messageCategory}
                    deleteTemplate={this.props.deleteTemplate}
                    preferencesList={this.props.preferencesLists.find(({ isActive }) => isActive) || null}
                  />
                  <Content className="notice-preview" style={{ marginTop: '35px' }}>
                    {!isToolbardisable && isCategoryGdpr && this.props.isTemplate &&
                      <div className={
                        this.state.messages.length > 1 ? "template-preview preview-middle" :
                          (messageComponent.settings.type === 'top' ? 'template-preview preview-bottom' : 'template-preview')}
                      >
                        <div className="preview-action-wrapper">
                          Property Preview
                          <Select
                            showSearch
                            virtual={false}
                            value={this.props.siteId}
                            placeholder="Select"
                            optionFilterProp="children"
                            filterOption={(i, o) => o.children.toLowerCase().indexOf(i.toLowerCase()) >= 0}
                            onChange={(value) => this.props.setPropertyPreview(value)}>
                            {sites.map((s, si) => <Option key={si} value={s.id}>{s.domain}</Option>)}
                          </Select>
                        </div>
                      </div>}

                    <div id="iframe-holder" className={this.state.mobile ? "mobile" : null}>
                      <iframe
                        title="privacy-manager-iframe"
                        onLoad={() => this.updateIframe()}
                        id="builder-preview"
                        src={this.generateNoticeUrl()}
                        key={this.props.siteId}
                      />
                    </div>
                    {messageComponent.settings.url && (
                      <div
                        className="domain-bg"
                        style={{ backgroundImage: `url('https://image.thum.io/get/width/1200/${messageComponent.settings.url}')` }}
                      />
                    )}
                    {this.state.messages.length > 1 && (
                      <ViewNavigation
                        currentComponentIndex={this.state.currentComponentIndex}
                        changeCurrentComponentIndex={this.changeCurrentComponentIndex}
                        messages={this.state.messages}
                        updateSubview={this.updateSubview}
                        subviewIndex={this.state.subviewIndex}
                      />
                    )}
                    {this.state.customCSS && <CustomCSS value={components[0].css || ''} updateCSS={this.updateCSS} />}

                    {((includesType && current.children.length > 0) || !(includesType)) && (
                      <div className={messageComponent.settings.type === 'top' ? 'toolbar toolbar-bottom' : 'toolbar'}>
                        <h4>
                          Toolbar
                          <span>
                            {current && current.name}
                          </span>
                        </h4>
                        <div className="toolbar-options">
                          {canAddSet.has(current.type) && (
                            <Button
                              onClick={this.showModal}
                              type="primary"
                              shape="circle"
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          )}
                          {this.state.current.length > 1 && (
                            <Button
                              onClick={this.removeCurrent}
                              type="danger"
                              shape="circle"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                          )}
                          <Button
                            type={this.state.hideRowColumnBorders ? "danger" : "primary"}
                            onClick={() => this.setState({ hideRowColumnBorders: !this.state.hideRowColumnBorders })}
                            shape="circle"
                          >
                            <FontAwesomeIcon icon={this.state.hideRowColumnBorders ? faEyeSlash : faEye} />
                          </Button>
                        </div>
                      </div>
                    )}
                  </Content>
                  <FirstParentSettingsContext.Provider
                    value={components[0].settings}
                  >
                    <PmOverrideContext.Provider
                      value={{
                        overrideVariables,
                        overridePrivacyManager: this.overridePrivacyManager,
                        clearPrivacyManagerOverride: this.clearPrivacyManagerOverride,
                        setDefaultPrivacyManagerVariable: this.setDefaultPrivacyManagerVariable,
                      }}
                    >
                      <DefaultLanguageContext.Provider
                        value={{
                          defaultLanguage: this.state.components[0].settings.defaultLanguage || this.state.components[0].settings.language || 'EN',
                          updateDefaultLanguage: this.updateDefaultLanguage,
                          supportedLanguages: this.state.components[0].settings.supportedLanguages || this.state.components[0].settings.defaultLanguage || 'EN',
                          translationComponent: this.state.components[0].settings.translationComponent || [],
                          updateSupportedLanguages: this.updateSupportedLanguages,
                          updateComponentText: this.updateComponentsText,
                          updatePreviewLanguage: this.updatePreviewLanguage,
                          previewLanguage: this.state.components[0].settings.previewLanguage || this.state.components[0].settings.defaultLanguage || 'EN',
                          highlightComponent: this.highlightComponent
                        }}
                      >
                        <CurrentComponentIndexContext.Provider value={this.state.current}>
                          <CurrentMessageViewContext.Provider value={{
                            currentView: this.state.components.length ? this.state.components[0].id : null,
                            pmData,
                          }}
                          >
                            <SelectedPrivacyManager.Provider value={{
                              settingKey: selectedPrivacyManagerKey,
                              selectedPrivacyManager: this.getSelectedPrivacyManager(this.state.components[0])
                            }}>
                              <NeutralPmContext.Provider value={this.state.includesNeutralPm}>
                                <Sidebar
                                  components={this.state.components}
                                  current={current}
                                  path={path}
                                  addToNotice={this.addToNotice}
                                  removeComponent={this.removeComponent}
                                  updateSetting={this.updateSetting}
                                  updateBulkSetting={this.updateBulkSetting}
                                  goToChild={this.goToChild}
                                  setCurrent={this.setCurrent}
                                  moveChild={this.moveChild}
                                  moveIntoChild={this.moveIntoChild}
                                  cloneComponent={this.cloneComponent}
                                  activeKey={this.state.activeKey}
                                  changeTab={this.changeTab}
                                  showModal={this.showModal}
                                  siteId={this.props.siteId}
                                  currentUser={this.props.currentUser}
                                  updateVariables={this.updateVariables}
                                  variables={this.state.variables}
                                  messageCategory={messageCategory}
                                  messageSubcategory={messageSubcategory}
                                  pmMessages={this.props.pmMessages}
                                  highlight={this.highlight}
                                  editing={this.state.editing}
                                  handleSampleMessagesModal={this.handleSampleMessagesModal}
                                  hiddenFieldFunctions={{
                                    addHiddenField: this.addHiddenField,
                                    removeHiddenField: this.removeHiddenField,
                                    updateHiddenField: this.updateHiddenField,
                                  }}
                                  subviewIndex={this.state.subviewIndex}
                                />
                              </NeutralPmContext.Provider>
                            </SelectedPrivacyManager.Provider>
                          </CurrentMessageViewContext.Provider>
                        </CurrentComponentIndexContext.Provider>
                      </DefaultLanguageContext.Provider>
                    </PmOverrideContext.Provider>
                  </FirstParentSettingsContext.Provider>
                </React.Fragment>
              )}

            {this.state.saveTemplateModal && (
              <SaveTemplate
                removeModal={this.hideSaveTemplateModal}
                createTemplate={this.createTemplate}
                messageSubcategory={this.props.messageSubcategory}
                messageCategory={this.props.messageCategory}
              />
            )}
          </Layout>
        </Layout>
        <Modal
          visible={this.state.showCompleteCheckPrivacyModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button onClick={() => this.confirmPrivacyCheck(this.state.privacyComplianceStatus)} key="back">
              CANCEL
            </Button>,
            <Button onClick={() => this.confirmPrivacyCheck(true)} key="submit" type="primary">
              CONFIRM PRIVACY CHECK REVIEW
            </Button>,
          ]}
        >
          <h4>Privacy Check Completed</h4>
          <div>All compiance rules are achieved for this TCF v2 message.</div>
          <div>Please confirm to finalize the privacy check review.</div>
        </Modal>
        <Modal
          width="600px"
          title={"IAB TCF Approved Messages"}
          visible={this.state.showSampleMessagesModal}
          closable={true}
          className="sampleModal"
          onCancel={() => this.handleSampleMessagesModal(false)}
          footer={[
            <Button onClick={() => this.handleSampleMessagesModal(false)} key="back">
              CANCEL
            </Button>,
            <Button onClick={() => this.copyMessage()} key="submit" type="primary">
              COPY
            </Button>,
          ]}
        >
          <p style={{ fontSize: '14px' }}>We provide you with the list of IAB TCF Approved Messages that can be
            used in the message builder to pass the set of privacy check rules
          </p>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'baseline' }}>
            <div style={{ fontSize: '14px', fontWeight: '600', flex: 1 }}>Select any of the message to copy the content</div>
            <div>Language
              <Select
                defaultValue={this.state.components[0].settings.defaultLanguage || this.state.components[0].settings.language || 'EN'}
                style={{ width: 120, marginLeft: "10px" }}
              >
                {supportLanguageDetails.map(({ code, language }) => <Option key={code}>{language}</Option>)}
              </Select>
            </div>
          </div>
          <ListView>
            {
              sampleMessages.map((msg, index) => {
                return (
                  <ListView.Item
                    onClick={() => this.selectMessage(msg.get('id'))}
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flex: 1, padding: '10px', alignItems: 'end'
                    }}>
                    <Checkbox
                      style={{ marginRight: '10px' }}
                      checked={(msg.get('id') === this.state.msg_id)} />
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <div style={{ fontWeight: '600' }}>{msg.get('title')}</div>
                      <div>{msg.get('description')}</div>
                    </div>

                  </ListView.Item>
                )
              })
            }
          </ListView>
        </Modal>
      </React.Fragment >
    );
  }
}


export default Builder;