/* eslint-disable camelcase */
/* eslint-disable no-alert */
require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch, { retries: 2, retryDelay: 500 });
import { message } from "antd";
import { checkStatus, buildUrlWithVersion, DIAGNOSE_DASHBOARD_BASEURL } from '../helper.js';

const GET_CLIENT_WEBSITES = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/websites/`;
const GET_CLIENT_PAGES = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/pages/`;
const GET_PROXY_TOOLS = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/proxy-tools/`;
const GET_CONSENT_CATEGORIES = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/consent-categories/`;
const GET_POST_CLIENT_SCANS = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/scans/`;
const GET_POST_CLIENT_SCAN_DETAILS = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/scan/`;
const TOGGLE_CLIENT_SCAN = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/scan/toggle/`;
const DELETE_CLIENT_SCAN = `${DIAGNOSE_DASHBOARD_BASEURL}/scan-setup/scan/delete/`;

function fetchWrapper(url, queryParams = {}, method = "get", body = undefined) {
  return fetch(buildUrlWithVersion(url, queryParams, "scan-setup"), {
    method,
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  })
    .then(checkStatus)
    .then((resp) => resp.json())
    .catch((err) => {
      console.log(err);
      const errorMessage = err.response
        ? `Error on ${method} ${
            {
              [GET_CLIENT_WEBSITES]: "Scan websites",
              [GET_CLIENT_PAGES]: "Scan website pages",
              [GET_PROXY_TOOLS]: "Scan proxy tools",
              [GET_CONSENT_CATEGORIES]: "Scan consent categories",
              [GET_POST_CLIENT_SCANS]: "Scans",
              [GET_POST_CLIENT_SCAN_DETAILS]: "Scan details",
              [TOGGLE_CLIENT_SCAN]: "Play/Pause scan",
              [DELETE_CLIENT_SCAN]: "Delete scan",
            }[url]
          } data: status ${err.response.status}`
        : err.message;
      message.error(errorMessage, 5);
      // eslint-disable-next-line no-throw-literal
      throw new Error("Unable to get scan data for selected account");
    });
}

export function getAccountScans(queryParams) {
  return fetchWrapper(GET_POST_CLIENT_SCANS, queryParams);
}

export function getAccountScanDetails(queryParams) {
  return fetchWrapper(GET_POST_CLIENT_SCAN_DETAILS, queryParams);
}

export function getAccountWebsites(queryParams) {
  return fetchWrapper(GET_CLIENT_WEBSITES, queryParams);
}

export function getAccountWebsitePages(queryParams) {
  return fetchWrapper(GET_CLIENT_PAGES, queryParams);
}

export function getConsentCategories(queryParams) {
  return fetchWrapper(GET_CONSENT_CATEGORIES, queryParams);
}

export function getProxyTools(queryParams) {
  return fetchWrapper(GET_PROXY_TOOLS, queryParams);
}

export function createAccountScan(queryParams = {}, body) {
  return fetchWrapper(GET_POST_CLIENT_SCAN_DETAILS, queryParams, "post", body);
}

export function updateAccountScan(queryParams = {}, body) {
  return fetchWrapper(GET_POST_CLIENT_SCAN_DETAILS, queryParams, "PUT", body);
}

export function toggleAccountScan(queryParams = {}, body) {
  return fetchWrapper(TOGGLE_CLIENT_SCAN, queryParams, "post", body);
}

export function deleteAccountScan(queryParams = {}) {
  return fetchWrapper(DELETE_CLIENT_SCAN, queryParams, "post");
}