import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CheckCircleFilled, CloseCircleFilled, EyeFilled, InfoCircleFilled, WarningFilled } from '@ant-design/icons';
import { Input, Modal, Table, Infotip, Alert } from '../../../../styleguide';
import { Popover, Tag } from 'antd';
import moment from 'moment';
import { List, Map } from 'immutable';
import { BACKEND_STATUS_TYPES, STATUS_TYPES } from '../../../../constants.js';
import { complianceList as noticeComplianceList, privacyManagerComplianceList, ottComplianceList } from '../../../../constants';
import CustomFilter from './CustomFilter';
import { ViewMessage } from '../../../common/ViewMessage';
import CustomDeleteModal from '../../../common/CustomDeleteModal';
import { hasFeature, keyByVal } from '../../../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const options = [
  { label: 'Draft', value: 'draft' },
  { label: 'Public', value: 'public' },
  { label: 'Stage', value: 'stage' },
  { label: 'Previously Active', value: 'publicOld' }
];

const defaultCheckedList = ['draft', 'public', 'stage'];

const shouldShowPrivacyManager = (messageType) => ['privacy-manager', 'privacy-manager-ott', 'notice-tcf-v2', 'native-ott'].includes(messageType)

const deleteMessageDet = { id: '', name: '' };

export default class NoticeTable extends Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
    showBuilder: PropTypes.func.isRequired,
    searchText: PropTypes.string.isRequired,
    siteId: PropTypes.number.isRequired,
    siteGroupId: PropTypes.number.isRequired
  };

  state = {
    showPreview: false,
    warningMessagePresent: false,
    deleteMsgDet: deleteMessageDet
  }

  showBuilder = (e, message) => {
    const status = keyByVal(BACKEND_STATUS_TYPES, message.status);
    if (status !== 'draft') {
      return this.showPreview(e, message);
    }

    e.preventDefault();
    e.stopPropagation();
    this.props.showBuilder(message, this.props.isPreviewOnly);
  };

  showPreview = async (e, message) => {
    this.setState({ showPreview: true, previewMessage: Map(message) });
  }

  deleteMessage = (e, messageId) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.deleteMessage(messageId);
    this.toggleWarningMessage(e, deleteMessageDet);
  }

  cloneMessage = (e, messageId) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.cloneMessage(messageId, this.props.isNative)
  }

  getColumns = (columns) => {
    let messageCategory = this.props.messageCategory;
    let messageSubcategory = this.props.messageSubcategory;
    //let isPM = ['privacy-manager', 'privacy_manager_non_iab', 'privacy-manager-ott', 'native-ott'].includes(messageSubcategory) && messageCategory !== 'ccpa'
    let showStatus = (messageCategory === 'gdpr') ||
      (messageCategory === 'ccpa' && messageSubcategory === 'notice') ||
      (messageCategory === 'ccpa' && messageSubcategory === 'native-ott') ||
      (messageCategory === 'ccpa' && messageSubcategory === 'ccpa_pm') ||
      (messageCategory === 'usnat' && messageSubcategory === 'us_pm') || 
      (messageCategory === 'usnat' && messageSubcategory === 'usnat_notice')
    let showPrivacyManager = shouldShowPrivacyManager(messageSubcategory) && messageCategory !== 'ccpa';
    let columnsWithStatus = columns.filter((column) => column.key !== 'privacy_check');
    let columnOthers = columns.filter((column) => column.key !== 'status' && column.key != 'privacy_check');
    let filteredColumns = columns
    if (showPrivacyManager) {
      filteredColumns = columns
    } else {
      if (showStatus) {
        filteredColumns = columnsWithStatus;
      } else {
        filteredColumns = columnOthers
      }
    }
    return filteredColumns
  }

  openBuilder = (e, message) => this.props.showBuilder(message, this.props.isPreviewOnly)

  toggleWarningMessage = (e, message) => {
    e.preventDefault();
    e.stopPropagation();
    const deleteMsgDet = !this.state.warningMessagePresent ? { id: message.id, name: message.description } : deleteMessageDet;
    this.setState((prevState) => ({ warningMessagePresent: !prevState.warningMessagePresent, deleteMsgDet }));
  };

  render() {

    const { messageCategory, messageSubcategory } = this.props;
    //let isPM = ['privacy-manager', 'privacy_manager_non_iab', 'privacy-manager-ott', 'native-ott'].includes(messageSubcategory);
    const isAccountPartOfWave1 = hasFeature(this.props.currentUser, 'tcf2.2');

    const columns = [
      {
        title: 'Name',
        dataIndex: 'description',
        key: 'description',
        align: 'left',
        colSpan: 1,
        sorter: (a, b) => a.description.localeCompare(b.description),
        className: 'temp-record-style',
        render: (description, {
          message_json: msgJson,
          message_category_id: catId,
          message_sub_category_id: subCatId
        }) => {
          return (
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 5 }}>
                {description}
              </div>
              <div>
                { isAccountPartOfWave1 
                && (catId === 1 && subCatId === 5)
                && (!msgJson.includes('$$!VC-ALL!$$') && !msgJson.includes('$$!VC-IAB!$$'))
                ? (
                  <Infotip className="tcf-version-update-infotip" content="Action required: Vendor count not detected" placement="right">
                    <InfoCircleFilled style={{ color: "#f83617" }} />
                  </Infotip>
                ): null
                }
              </div>
            </div>
          )
        }
      },
      {
        title: 'ID',
        dataIndex: 'id',
        align: 'left',
        key: 'ID',
        colSpan: 0.5,
        sorter: (a, b) => a.id - b.id,
        className: 'temp-record-style',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        className: 'temp-record-style',
        key: 'status',
        colSpan: 1,
        render: (msg_status, msg) => {
          const status = keyByVal(BACKEND_STATUS_TYPES, msg_status);
          const realStatus = keyByVal(STATUS_TYPES, status);

          const statusTag = msg.statusSecondLayer?.length ? (
            msg.statusSecondLayer.map((status) => (
              <Tag color="#3FCC97">{status.toUpperCase()}</Tag>
            ))
          ) : realStatus === "PUBLIC" || realStatus === "STAGE" ? (
            <Tag color="#3FCC97">{realStatus}</Tag>
          ) : realStatus === 'DRAFT' ? (
            <Tag>{realStatus}</Tag>
          ) : (<Tag color="#FFC107">{realStatus}</Tag>);
          const messageCategory = this.props.messageCategory;
          return (
            (<div className="status-container">
              {(messageCategory === 'gdpr' || messageCategory === 'ccpa' || messageCategory === 'usnat') && (
                <div>
                  {realStatus === 'PUBLIC_OLD' ? <Tag color="#FFC107" >PREV. LIVE</Tag> : statusTag}
                </div>
              )}
            </div>)
          );
        }
      },
      {
        title: 'Date Created',
        dataIndex: 'created_at',
        key: 'date-created',
        colSpan: 1,
        align: 'left',
        className: 'temp-record-style',
        sorter: (a, b) =>
          moment(a.created_at).valueOf() - moment(b.created_at).valueOf(),
        render: created_at => moment(created_at).format('YYYY-MM-DD')
      },
      {
        title: 'Last Updated',
        dataIndex: 'updated_at',
        key: 'modified',
        align: 'left',
        colSpan: 1,
        className: 'temp-record-style',
        sorter: (a, b) =>
          moment(a.updated_at).valueOf() - moment(b.updated_at).valueOf(),
        render: (updated_at) => (updated_at === null) ? '-' : moment(updated_at).format('YYYY-MM-DD')
      },
      {
        title: 'Privacy Check',
        dataIndex: 'id',
        key: 'privacy_check',
        sorter: (a, b) => a.id - b.id,
        className: 'temp-record-style',
        colSpan: 1,
        width: '150px',
        textWrap: 'word-break',
        render: (action, message) => {
          let originalComplianceList = List()
          const { messageSubcategory, messageCategory } = this.props;
          let editing = JSON.parse(message.message_json)
          let compliance_status = (editing.hasOwnProperty('compliance_status') || editing.settings.compliance_status)
            ? (editing.compliance_status || editing.settings.compliance_status) : false;

          let complianceList;
          if ('privacy-manager' === messageSubcategory) {
            complianceList = privacyManagerComplianceList
          } else if (['native-ott', 'privacy-manager-ott'].includes(messageSubcategory)) {
            complianceList = ottComplianceList
          } else {
            complianceList = noticeComplianceList
          }

          complianceList.map((item) => {
            let obj = new Map();
            obj = obj.set(item.get('id'), item.get('status'));
            originalComplianceList = originalComplianceList.push(obj);
          })

          const compliance_list = (message.hasOwnProperty('compliance_json')) ?
            (message.compliance_json != null) ? JSON.parse(message.compliance_json) : originalComplianceList.toJS()
            : originalComplianceList.toJS()

          let isPassed = false;
          let passedCount = 0;
          let failedCount = 0;
          if (compliance_list != null) {
            passedCount = compliance_list.filter((item, index) => item[index + 1] === true).length
            failedCount = originalComplianceList.toJS().length - passedCount
            isPassed = (passedCount === compliance_list.length) ? true : false
          }

          if (shouldShowPrivacyManager(messageSubcategory) || messageCategory === 'ccpa') {
            return (
              <React.Fragment>
                {(isPassed)
                  ? (compliance_status) ? (
                    <div><CheckCircleFilled style={{ color: 'green', marginRight: '10px' }} />Confirmed</div>
                  ) : (
                    <div><CheckCircleFilled style={{ color: '#FF9148', marginRight: '10px' }} />Unconfirmed</div>
                  )
                  : (
                    <div><CloseCircleFilled style={{ color: '#FF2626', marginRight: '10px' }} />
                      {(failedCount > 1) ? `${failedCount} privacy checks need review` : `${failedCount} privacy check require review`}
                    </div>
                  )}
              </React.Fragment>
            )
          }

        }
      },
      {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        colSpan: 1,
        render: (action, message) => (
          <div>
            {keyByVal(BACKEND_STATUS_TYPES, message.status) === 'draft' && (
              <button
                disabled={keyByVal(BACKEND_STATUS_TYPES, message.status) === 'public' ? true : false}
                className="avo-dialogue-icons avo-sp-edit"
                onClick={(e) =>
                  this.showBuilder(e, message)
                }
              />
            )}
            {keyByVal(BACKEND_STATUS_TYPES, message.status) !== 'draft' && (
              <button
                className="avo-dialogue-icons"
                onClick={(e) => this.showPreview(e, message)} >
                <EyeFilled style={{ 'vertical-align': 'baseline' }} />
              </button>
            )}
            <button
              className="avo-dialogue-icons avo-sp-copy"
              onClick={(e) =>
                this.cloneMessage(e, message.id)}
            />
            {keyByVal(BACKEND_STATUS_TYPES, message.status) === 'draft' && (
              <button
                className="avo-dialogue-icons avo-sp-delete"
                onClick={(e) => this.toggleWarningMessage(e, message)}
              ></button>
            )}
          </div>
        )
      }
    ];


    const columnsByCategory = this.getColumns(columns)
    //const showFilter = (messageCategory === 'gdpr' /*&& !isPM*/) || (messageCategory === 'ccpa' /*&& messageSubcategory === 'notice'*/)
    const showFilter = columnsByCategory.find((column) => column.key === 'status');
    let warningMessage;
    if (this.state.warningMessagePresent) {
      warningMessage = (
        <CustomDeleteModal
          handleDelete={(e) => this.deleteMessage(e, this.state.deleteMsgDet.id)}
          name={this.state.deleteMsgDet.name}
          id={this.state.deleteMsgDet.id}
          handleCancel={this.toggleWarningMessage}
        />
      );
    }

    return (
      <div className="notice-table-template">
        {this.props.messageCategory == 'gdpr' && this.props.messageSubcategory == 'notice-tcf-v2'
         ? (
          <Alert
            type="warning"
            icon={<WarningFilled />}
            showIcon={true}
            message={<>In order to stay compliant with the TCF v2.2, clients are required to disclose the vendor count in the first layer message. Please make sure you have added the vendor count to the draft messages that are linked to the scenarios currently being used in your active campaigns. In order to ensure that this update is reflected on your website, you will need to relaunch all active campaign(s) that are using the public version of the draft message(s) that have been updated. For more context on how this works, reference <a href='https://docs.sourcepoint.com/hc/en-us/articles/9510797797523-First-layer-message-status' target='_blank'>this article</a>.</>}
            style={{marginBottom: '15px'}}
          />
        ) : null}
        <div className="card nt-card">
          <div className="top-container">
            <Input
              type="search"
              placeholder="Search..."
              className="template-search"
              value={this.props.searchText}
              onChange={e => this.props.updateSearch(e)}
            />
            {(showFilter) &&
              (<CustomFilter
                filterCondition={this.props.filterCondition}
                accountId={this.props.accountId}
                options={options}
                defaultCheckedList={defaultCheckedList}
              />)}
          </div>
          <Table
            className="template-table-container"
            dataSource={this.props.messages}
            columns={columnsByCategory}
            filtered
            pagination={{
              position: ['bottomCenter'],
              showTotal: (total) => `Total Messages: ${total}`,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30'],
              locale: { items_per_page: ' Records per page' },
            }}
            onRow={message => {
              return {
                onClick: e => this.showBuilder(e, message)
              };
            }}
          />
          {this.state.showPreview && (
            <Modal
              centered
              onCancel={() => this.setState({ showPreview: false })}
              title={<h3>{this.state.previewMessage.get('description')}<span className='meesage-title-verticle-line'> | </span><span className='message-id'>ID: {this.state.previewMessage?.get("id")}</span></h3>}
              footer={null}
              visible={this.state.showPreview}
              wrapClassName="scenario-modal-wrapper"
            >
              <ViewMessage
                viewMsg={this.state.previewMessage}
                siteId={this.props.siteId}
                isNativeOtt={this.props.messageSubcategory === 'native-ott'}
              />
            </Modal>
          )}
        </div>
        {warningMessage}
      </div>
    );
  }
}
