import React, { useMemo, useRef, useEffect, useContext, useReducer  } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { getSiteGroupPrivacyManagerList, getSitePrivacyManagerList, dumpPrivacyManagerList } from './actions/privacy_manager_actions';
import { SitesContext, CategoryContext } from './components/v2/contexts';
import { messageEndpoints, BACKEND_STATUS_TYPES, subcategoryMap } from './constants';
import { ConfigProvider } from 'antd';

export function useActions(actions, deps = []) {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch);
    },
    deps ? [dispatch, ...deps] : [dispatch]
  )
}

export function useAutoScroll(length) {
  const element = useRef(null);
  const prevLengthRef = useRef(length);
  const prevLength = prevLengthRef.current;
  useEffect(() => {
    prevLengthRef.current = length;
    if (prevLength < length && element.current) {
      element.current.scrollTop = element.current.scrollHeight;
    };
  }, [length]);

  return element;
}

export function usePrivacyManagerList(accountId, siteId) {
  const category = useContext(CategoryContext);
  const { isSiteGroup, sites, siteGroupId } = useContext(SitesContext);

  const [getSgPmList, getSitePmList, dumpPmList] = useActions([getSiteGroupPrivacyManagerList, getSitePrivacyManagerList, dumpPrivacyManagerList]);

  const pmList = (useSelector(({ privacyManagerState }) => privacyManagerState.get('sitePrivacyManagerList')).value || [])
    .filter(pm => (
      siteGroupId
        ? (pm.siteGroupId === siteGroupId)
        : (siteId === pm.siteId)
    ));

  useEffect(() => {
    const isCcpa = category === 'ccpa';
    if (isSiteGroup) {
      const siteIds = sites.map(site => site.id);
      getSgPmList(accountId, siteIds, siteGroupId, isCcpa);
      siteIds.forEach(id => getSitePmList(accountId, id, isCcpa));
    } else {
      getSitePmList(accountId, siteId, isCcpa);
    };

    return () => dumpPmList();
  }, []);

  return pmList;
}

const v2SiteGroupsReducer = (state, action) => {
  switch (action.type) {
    case 'ERROR':
      return { ...state, status: 'ERROR' };
    case 'PENDING':
      return { ...state, status: 'PENDING' };
    case 'RESOLVED':
      return { ...state, status: 'RESOLVED', response: action.payload };
    default:
      throw `UNKNOWN ACTION TYPE: ${action.type}`
  };
};

/*
  Gets all V2 PMs for sitegroup and child sites
*/
export const useV2SiteGroupPms = (messageSubcategory) => {
  const [{ status, response }, dispatch] = useReducer(v2SiteGroupsReducer, { status: null, response: {} });
  const { sites, siteGroupId } = useContext(SitesContext);
  const category = useContext(CategoryContext);

  if (!siteGroupId) {
    return { status, response };
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { accountId, sgSiteId } = useSelector(({ accountState, siteState }) => ({
    sgSiteId: siteState.get('siteGroups').value.find(({ id }) => id === siteGroupId).siteId,
    accountId: accountState.get('userDetails').value ? accountState.get('userDetails').value : ''
  }));

  const makeUrl = (siteId) => `${messageEndpoints.MESSAGES}?object_status=${BACKEND_STATUS_TYPES.draft}&site_id=${siteId}`;

  let pmSubCategoryId;
  switch(messageSubcategory) {
    case 'notice_non_iab': 
      pmSubCategoryId = subcategoryMap.privacy_manager_non_iab;
      break;
    case 'notice':
      pmSubCategoryId = category === 'ccpa' ? subcategoryMap.ccpa_pm : subcategoryMap.privacyManager;
      break;
    case 'notice-tcf-v2':
    default:
      pmSubCategoryId = subcategoryMap.privacyManager;
      break;
  }

  if (!status && accountId) {
    dispatch({ type: 'PENDING' });
    const siteIds = [...sites.toJS().map(({ id }) => id), sgSiteId];
    try {
      (async () => {
        const resps = await Promise.all(siteIds.map(id => fetch(makeUrl(id), { 
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
         })));
        const allMsgs = await Promise.all(resps.map(async resp => {
          const json = await resp.json();
          return json.data.message_list;
        }));
        const pmMsgs = allMsgs.map(msgs => msgs.filter(({ message_sub_category_id: id }) => id === pmSubCategoryId));
        const sortedMsgs = pmMsgs.reduce((sortedMsgs, msgs, index) => {
          const sortedMsg = {
            siteId: msgs.length ? msgs[0].site_id : sites.get(index) && sites.get(index).id,
            msgs: msgs
          };

          return [...sortedMsgs, sortedMsg]
        }, [])
        dispatch({ type: 'RESOLVED', payload: sortedMsgs });
      })();

    } catch (err) {
      console.error(err);
      dispatch({ type: 'ERROR' });
    };
  };

  return { status, response };
};

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

//to get previous props or state. https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useDebouncedSearch = (callback, delay) => {
  const debouncedCallback = useMemo(() => debounce(callback, delay), [callback, delay]);

  useEffect(() => {
    return () => {
      debouncedCallback.cancel();
    };
  }, [debouncedCallback]);

  return debouncedCallback;
};

export const withConfigProvider = (WrappedComponent) => {
  return ({ componentTokens, ...props }) => {
    const tokens = componentTokens || {};

    return (
      <ConfigProvider
        theme={{
          components: {
            [WrappedComponent.displayName || WrappedComponent.name]: tokens,
          },
        }}
      >
        <WrappedComponent {...props} />
      </ConfigProvider>
    );
  };
};
