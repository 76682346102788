import PropTypes from 'prop-types';
import React from 'react';
import { Menu, Dropdown } from 'antd';
import Isvg from 'react-inlinesvg';
import classNames from 'classnames';
import { Link } from 'react-router';
import { browserHistory } from 'react-router';
import { accountHasPrivacyLens } from '../../util';

export default class NavbarDropdownMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isAdmin: PropTypes.bool.isRequired,
    isSPUser: PropTypes.bool.isRequired,
    featureAccess: PropTypes.any,
  }

  static defaultProps = {
    className: '',
  }

  state = {
    visible: false,
  };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  }

  logOut = () => {
    const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

    return fetch(`${permissionsSvcBaseUrl}/authentication/sign-out`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => { 
        // this.props.clearCurrentUser();
        browserHistory.push('/users/sign_in');
      });
  };

  onlyPrivacyLensFeature = () => {
    return (
      !this.props.isSPUser
        && this.props.features 
        && (this.props.features.size === 1 && accountHasPrivacyLens(this.props.features)
        || this.props.features.size === 2 && accountHasPrivacyLens(this.props.features) && this.props.features.includes('sentinel'))
    );
  }

  render() {
    const privacyLensOnly = this.onlyPrivacyLensFeature();

    const isAccessToTemplates = (this.props.featureAccess && this.props.featureAccess.size && this.props.featureAccess.includes('templates')) ||
      !this.props.featureAccess;

    const sites = this.props.isAdmin && !privacyLensOnly ? (
      <Menu.Item className='sites'>
        <Link to='/account/domains'>Analytics Domains</Link>
      </Menu.Item>
    ) : null;
    const users = this.props.isAdmin ? (
      <Menu.Item className='users'>
        <Link to='/user/invite'>Users</Link>
      </Menu.Item>
    ) : null;
    const manageTemplates = isAccessToTemplates ? (
      <Menu.Item className='api-docs'>
        <Link to='/admin/system_templates'>Manage Templates</Link>
      </Menu.Item>
    ) : null;
    const superAdminSubMenu = (this.props.isSPUser && this.props.isAdmin) ? (
      <Menu.Item className='new-account'>
        <Link to='/new_account'>New Account</Link>
      </Menu.Item>
    ) : null;
    const adminSettings = (this.props.isSPUser && this.props.isAdmin) ? (
      <Menu.Item className='admin-settings'>
        <Link to='/admin/admin_settings'>Admin Settings</Link>
      </Menu.Item>
    ) : null;
    const changeLog = (
      !this.props.featureAccess
      || this.props.featureAccess.includes('Change log')
    )
      ? (
        <Menu.Item>
          <Link to='/admin/change_log'>Change Log</Link>
        </Menu.Item>
      ) : null;
    const switchUser = this.props.isSPUser || this.props.masqed ? (
      <Link className='masq' to='/admin/masq'>
        <Isvg src='/images/switch-users-icon.svg'>
          <img src='/images/switch-users-icon.svg' />
        </Isvg>
      </Link>
    ) : null;
    const help = privacyLensOnly
      ? null
      : (
        <Menu.Item className='help'>
          <a target="_blank" href='https://sourcepoint.zendesk.com/hc/en-us'>Help</a>
        </Menu.Item>
      );

    const menu = (
      <Menu className='blueberry-navbar'>
        <Isvg className='triangle-icon' src='/images/triangle-icon.svg'>
          <img src='/images/triangle-icon.svg' />
        </Isvg>
        <Menu.Item className='account'>
          <Link to='/account/edit'>Account</Link>
        </Menu.Item>
        {sites}
        {users}
        {manageTemplates}
        {superAdminSubMenu}
        {adminSettings}
        {changeLog}
        {help}
        <Menu.Divider />
        <Menu.Item className='sign-out'>
          <Isvg
            src='/images/sign-out-icon.svg'
            className='sign-out-icon'
          >
            <img src='/images/sign-out-icon.svg' />
          </Isvg>
          <Link
            className='sign-out-link'
            data-method='delete'
            onClick={ () => { this.logOut() } }
          >
            Sign Out
          </Link>
        </Menu.Item>
      </Menu>
    );

    const classes = classNames('dropdown-container', { 'visible': this.state.visible });
    return (
      <div className={this.props.className}>
        {switchUser}
        <div className={classes}>
          <Dropdown
            onOpenChange={this.handleVisibleChange}
            open={this.state.visible}
            overlay={menu}
            placement="bottomCenter"
          >
            <div>
              <Isvg src='/images/account-icon.svg'>
                <img src='/images/account-icon.svg' />
              </Isvg>
            </div>
          </Dropdown>
        </div>
      </div>
    );
  }
}
