import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip as RechartTooltip,
  ResponsiveContainer,
  Surface,
  Symbols,
  XAxis,
  YAxis,
} from 'recharts';
import DashboardSelectDropdown from '../shared/DashboardSelect';
import Ranges from './LinearGaugeChart';

const metricsColors = {
  'Disclosed Vendors': '#0CA6FF',
  'Non disclosed vendors observed': '#80CC1D',
  'Vendors triggered prior to consent': '#9753FF',
  'Cookies with long lifespans': '#FF53F4',
  'Possible Fingerprinting': '#FF57A1',
  'Data leaving the EEA': '#2596be',
  'All Cookies Found': '#8598be',
  'Vendors after opt out': '#D81E01',
};

const SummaryBlock = ({ summaryData, metricsList = [], selectedMetrics, onMetricsSelect }) => {
  const [disabled, setDisabled] = useState([]);
  const chronologicalSummaryData = summaryData.sort((a, b) => {
    return new Date(a?.timestamp) - new Date(b?.timestamp);
  });

  // Reduce the chronologicalSummaryData array to transform the data into two separate objects: dataBreakdown and dataEvolution
  const { dataBreakdown, dataEvolution } = chronologicalSummaryData.reduce(
    (data, periodData) => {
      // Create a new date variable by converting the timestamp property of each periodData object into a Date object and retrieving its timestamp value
      const date = new Date(periodData?.timestamp).getTime();

      return {
        // Populate the dataBreakdown array with objects containing the date and overall_relative_score properties from each periodData object
        dataEvolution: [
          ...data.dataEvolution,
          {
            date,
            uv: periodData?.overall_relative_score,
          },
        ],

        // Populate the dataEvolution array with objects containing the date and accumulated metrics
        dataBreakdown: [
          ...data.dataBreakdown,
          {
            date,
            // Reduce the metrics array (or an empty array if periodData.metrics is undefined) to accumulate item.metric and item.relative_score properties into an object called collectedMetrics
            ...(periodData.metrics || []).reduce((collectedMetrics, item) => {
              return {
                ...collectedMetrics,
                [item.metric]: item.relative_score,
              };
            }, {}),
          },
        ],
      };
    },
    {
      // Initialize dataBreakdown and dataEvolution as empty arrays
      dataBreakdown: [],
      dataEvolution: [],
    },
  );

  const handleSelectMetrics = (value) => {
    if (value === 'All') {
      onMetricsSelect(selectedMetrics.length === metricsList?.length ? [] : [...metricsList]);
    } else {
      onMetricsSelect(
        selectedMetrics.includes(value)
          ? selectedMetrics.filter((metric) => metric !== value)
          : [...selectedMetrics, value],
      );
    }
  };

  const dateFormatter = (date) => {
    return `${new Date(date).getDate()} ${new Date(date).toLocaleString('default', { month: 'short' })}`;
  };

  const tooltipLabelFormatter = (value) => {
    return moment(value).format('DD MMM');
  };

  const handleClick = (dataKey) => {
    if (_.includes(disabled, dataKey)) {
      setDisabled(disabled.filter((obj) => obj !== dataKey));
    } else {
      setDisabled(disabled.concat(dataKey));
    }
  };

  const renderCusomizedLegend = ({ payload }) => {
    return (
      <div
        className="customized-legend"
        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', height: '45px', justifyContent: 'center' }}
      >
        {payload.map((entry) => {
          const { dataKey, color } = entry;
          const active = _.includes(disabled, dataKey);
          const style = {
            marginRight: 10,
            cursor: 'pointer',
            fontSize: '8px',
            color: active ? '#AAA' : '#43425D',
            display: 'flex',
            alignItems: 'center',
          };

          return (
            <span key={dataKey} className="legend-item" onClick={() => handleClick(dataKey)} style={style}>
              <Surface
                width={10}
                height={10}
                viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
                style={{ marginRight: '5px' }}
              >
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                {active && <Symbols cx={5} cy={5} type="circle" size={25} fill={'#FFF'} />}
              </Surface>
              <span>{dataKey}</span>
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <div>
        The graphs below demonstrate how your properties perform on key compliance criteria in relation to your chosen
        benchmark over the defined time period. To drill down further, please see the Compliance Metrics section below.
      </div>
      <br />
      <div className={'base-relative-metrics'}>
        <div style={{ marginBottom: '10px', fontWeight: 700 }}>Base Relative Score On:</div>
        <DashboardSelectDropdown
          options={metricsList?.map((metric) => ({ title: metric, value: metric }))}
          selected={[...selectedMetrics, ...(selectedMetrics.length === metricsList?.length ? ['All'] : [])]}
          onSelect={handleSelectMetrics}
          onClear={() => onMetricsSelect([])}
          placeholder={'Metrics'}
          showAll
        />
      </div>
      <br />
      <div className={'summary_charts_container'}>
        <div className={'card'}>
          <div className={'card_title'}>
            <b>Overall Relative Score</b>
            <Tooltip
              title={
                'The overall relative score is a percentile rank that looks at all the compliance metrics and compares the performance of your selected properties with the performance of the selected benchmark properties. Example: If you have a relative score of 67% then you score better than 67% of properties in your benchmark'
              }
            >
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <Ranges
            value={
              summaryData.sort((a, b) => new Date(a?.timestamp) - new Date(b?.timestamp))[summaryData?.length - 1]
                ?.overall_relative_score
            }
          />
        </div>
        <div className={'card'}>
          <div className={'card_title'}>
            <b>Overall Relative Score Evolution</b>
            <Tooltip
              title={
                'This graph shows how the relative score changes over time. The time period corresponds to the time period chosen. An increase of the score over time means that the selected properties are improving comparatively with the benchmark'
              }
            >
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div className={'line_chart_container'}>
            <div className={'line_chart_yaxis_label'}>
              <div>Relative Score</div>
            </div>
            <ResponsiveContainer width="90%" height="90%">
              <LineChart width={500} height={300} data={dataEvolution}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" interval={0} tickFormatter={dateFormatter} tick={{ fontSize: 10 }} />
                <YAxis width={25} />
                <RechartTooltip
                  labelFormatter={tooltipLabelFormatter}
                  formatter={(value) => [value, 'Score']}
                  isAnimationActive={false}
                />
                <Line type="monotone" isAnimationActive={false} dataKey="uv" stroke="#000000" fill="#000000" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className={'card'}>
          <div className={'card_title'}>
            <b>Overall Relative Score Breakdown</b>
            <Tooltip
              title={
                'This graph shows the relative score for each compliance metric over time. The time period corresponds to the time period chosen. An increase of the score over time means that the selected properties are improving comparatively with the benchmark.'
              }
            >
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div className={'line_chart_container'}>
            <div className={'line_chart_yaxis_label'}>
              <div>Relative Score</div>
            </div>
            <ResponsiveContainer width="90%" height="90%">
              <LineChart width={500} height={300} data={dataBreakdown}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" interval={0} tickFormatter={dateFormatter} tick={{ fontSize: 10 }} />
                <YAxis width={25} />
                <RechartTooltip
                  labelFormatter={tooltipLabelFormatter}
                  allowEscapeViewBox={{ x: false, y: true }}
                  isAnimationActive={false}
                />
                {selectedMetrics
                  .filter((metric) => !_.includes(disabled, metric))
                  .map((metric) => (
                    <Line
                      type="monotone"
                      key={metric}
                      isAnimationActive={false}
                      dataKey={metric}
                      stroke={metricsColors[metric]}
                      strokeWidth={2}
                      // activeDot={{ r: 8 }}
                    />
                  ))}
                <Legend
                  // wrapperStyle={{ bottom: '-5px' }}
                  verticalAlign="bottom"
                  align="left"
                  payload={selectedMetrics.map((metric) => ({
                    dataKey: metric,
                    color: metricsColors[metric],
                  }))}
                  content={renderCusomizedLegend}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryBlock;
