import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { useState, useMemo } from 'react';
import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import { Menu, Checkbox, Radio, Collapse } from 'antd';

const privacyLensFeatures = ['Privacy Lens - Advanced', 'Privacy Lens - Basic', 'Privacy Lens - Read Only'];
const diagnoseDashboardMetricsFeatures = ['Diagnose - Non disclosed vendors observed', 'Diagnose - Vendors triggered prior to consent', 'Diagnose - Disclosed Vendors', 'Diagnose - Possible Fingerprinting', 'Diagnose - Data leaving the EEA', 'Diagnose - All Cookies Found', 'Diagnose - Cookies with long lifespans', 'Diagnose - Vendors after opt out'];

const FeatureAccessDropdown = (props) => {
  const {
    permissionsList,
    editing,
    userFeatureAccess,
    setSelectedFeatures,
    hasPrivacyLens,
  } = props;


  const [privacyLensOpen, setPrivacyLensOpen] = useState(false);
  const [diagnoseDashboardMetricsOpen, setDiagnoseDashboardMetricsOpen] = useState(false);

  const { privacyLensList, diagnoseMetricsList, otherPermissionsList } = useMemo(() => permissionsList.reduce((groupedPermissionsList, permission) => {
      if (privacyLensFeatures.includes(permission.name)) {
        groupedPermissionsList.privacyLensList.push(permission);
      } else if (diagnoseDashboardMetricsFeatures.includes(permission.name)) {
        groupedPermissionsList.diagnoseMetricsList.push(permission);
      } else if (permission.name === 'Diagnose Dashboard') {
        // Do nothing for the diagnose dashboard
      } else {
        groupedPermissionsList.otherPermissionsList.push(permission);
      }
    
      return groupedPermissionsList;
    }, {
      // initial state
      privacyLensList: [],
      diagnoseMetricsList: [],
      otherPermissionsList: [],
    }), [permissionsList]);

    const handleSelectFeature = (feature, value) => {
      let currentFeatures = userFeatureAccess;
      const diagnoseMetrics = diagnoseMetricsList.map(m => m.name);
      const conditionalAddPrivacyLensFeature = () => {
        // select privacy lens default option
        return permissionsList.some(p => p.name === 'Privacy Lens - Basic') 
        ? currentFeatures.concat('Privacy Lens - Basic')
        : currentFeatures.concat('Privacy Lens - Read Only');
      };

      const toggleSelectAllFeature = (userFeatures) => {
        let result = userFeatures;
        if (userFeatures.includes('All access') && userFeatures.size > 1) {
          // add 'All access', if user manually select all available features
          result = userFeatures.filter(item => item !== 'All access');
        }
        if (userFeatures.size === (hasPrivacyLens ? permissionsList.size - 2 : permissionsList.size)) {
          // remove 'All access', if user manually deselect one-last feature
          result = userFeatures.concat('All access');
        }
        return result;
      };
      const toggleDiagnoseDashboardFeature = (userFeatures) => {
        let result = userFeatures;
        if (diagnoseMetrics.some(metric => userFeatures.includes(metric)) && !userFeatures.includes('Diagnose Dashboard')) {
          // add 'Diagnose Dashboard', if user select one of available diagnose dashboard metric
          result = userFeatures.concat('Diagnose Dashboard');
        }
        if (!diagnoseMetrics.some(metric => userFeatures.includes(metric)) && userFeatures.includes('Diagnose Dashboard')) {
          // remove 'Diagnose Dashboard', if user deselect one-last selected diagnose dashboard metric
          result = userFeatures.filter(f => f !== 'Diagnose Dashboard');
        }
        return result;
      };
    
      switch (feature) {
        case 'All access': {
          const remainingFeatures = permissionsList
            .filterNot(p => currentFeatures.includes(p.name) || privacyLensFeatures.includes(p.name))
            .map(p => p.name);
          const isPrivacyLensFeatureUnselected = !currentFeatures.some(f => privacyLensFeatures.includes(f));
    
          if (value) {
            if (hasPrivacyLens && isPrivacyLensFeatureUnselected) {
              currentFeatures = conditionalAddPrivacyLensFeature();
            } 
            currentFeatures = currentFeatures.concat(remainingFeatures, feature);
          } else {
            currentFeatures = currentFeatures.clear();
          }
          break;
        }
        case 'Privacy Lens - Group': {
          currentFeatures = value
            ? conditionalAddPrivacyLensFeature()
            : currentFeatures.filterNot(f => privacyLensFeatures.includes(f))
          break;
        }
        case 'Privacy Lens - Advanced':
        case 'Privacy Lens - Basic':
        case 'Privacy Lens - Read Only': {
          currentFeatures = currentFeatures
            .filterNot(f => privacyLensFeatures.includes(f))
            .concat(feature);
          break;
        }
        case 'Diagnose Dashboard': {
          currentFeatures = value
            ? currentFeatures.concat(...diagnoseMetrics, feature)
            : currentFeatures.filterNot(f => [feature, ...diagnoseMetrics].includes(f))
          break;
        }
        default: {
          currentFeatures = value
            ? currentFeatures.concat(feature)
            : currentFeatures.filterNot(f => f === feature)
        }
      }

      if (feature !== 'All access') {
        currentFeatures = toggleSelectAllFeature(currentFeatures);
        currentFeatures = toggleDiagnoseDashboardFeature(currentFeatures);
      }
    
      setSelectedFeatures(currentFeatures);
    };
    
  const getPrivacyLensValue = () => {
    let value = null;
    privacyLensFeatures.forEach((f) => {
      if (userFeatureAccess.includes(f)) {
        value = f;
      }
    });
    return value;
  };

  const getListOfFeatures = (featureAccess) => {
    let listOfFeatures;
    if (!featureAccess || featureAccess.includes('All access')) {
      listOfFeatures = <p className="all-access">All access</p>;
    } else {
      const filterUserFeatureAccess = permissionsList?.filter(feature => featureAccess?.includes(feature.name)).map(f => f.name);
      listOfFeatures = (
        <div className="sites-container flex-row">
          <div className="site">{filterUserFeatureAccess.slice(0, 1)}</div>
          {filterUserFeatureAccess.size > 1 ? (
            <div className="more">{`+ ${
              filterUserFeatureAccess.size - 1
            } more`}</div>
          ) : null}
        </div>
      );
    }
    return listOfFeatures;
  };

  if (editing) {
    const isAllDiagnoseMetricSelected = diagnoseMetricsList.every(metric => userFeatureAccess.includes(metric.name));
    const menu = (
      <Menu>
        <Menu.Item key={Math.random()}>
          <div className="flex-row">
            <div>All access</div>
            <Checkbox onChange={e => handleSelectFeature('All access', e.target.checked)} checked={userFeatureAccess.includes('All access')} />
          </div>
        </Menu.Item>
        { hasPrivacyLens ? 
          <React.Fragment>
            <Menu.Item key={Math.random()}>
            <div className="privacy-lens">
              {
                privacyLensOpen
                ? <MinusSquareOutlined onClick={() => setPrivacyLensOpen(!privacyLensOpen)} />
                : <PlusSquareOutlined onClick={() => setPrivacyLensOpen(!privacyLensOpen)} />
              }
              Privacy Lens
              <Checkbox onChange={(e) => { setPrivacyLensOpen(true); handleSelectFeature('Privacy Lens - Group', e.target.checked); }} checked={!!getPrivacyLensValue()} />
            </div>
          </Menu.Item>
          { 
            privacyLensOpen
            ? (
              <Menu.Item key={Math.random()}>
                <Radio.Group onChange={e => handleSelectFeature(e.target.value, e.target.checked)} value={getPrivacyLensValue()}>
                  { privacyLensList.map(p => 
                    <Radio key={p.name} value={p.name}>{<div className="privacy-lens-features"><div title={p.name}>{p.name.slice(15)}</div><div className="description">{p.description}</div></div>}</Radio>,
                  )}
                </Radio.Group>
              </Menu.Item>
            )
            : null
          }
          </React.Fragment> : null
        }
        { diagnoseMetricsList.length ? 
          <React.Fragment>
            <Menu.Item key={Math.random()}>
            <div>
              {
                diagnoseDashboardMetricsOpen
                ? <MinusSquareOutlined onClick={() => setDiagnoseDashboardMetricsOpen(false)} />
                : <PlusSquareOutlined onClick={() => setDiagnoseDashboardMetricsOpen(true)} />
              }
              Diagnose Dashboard Metrics
              <Checkbox onChange={(e) => { setDiagnoseDashboardMetricsOpen(true); handleSelectFeature('Diagnose Dashboard', e.target.checked); }} indeterminate={diagnoseMetricsList.some(metric => userFeatureAccess.includes(metric.name)) && !isAllDiagnoseMetricSelected} checked={isAllDiagnoseMetricSelected} />
            </div>
          </Menu.Item>
          { 
            diagnoseDashboardMetricsOpen
            ? diagnoseMetricsList.map(p => (
                <Menu.Item key={p.name}>
                  <div title={p.name} className={'ellipsis-list-item'}>
                    {p.name}
                    <Checkbox onChange={e => handleSelectFeature(p.name, e.target.checked)} checked={userFeatureAccess.includes(p.name)} />
                  </div>
                </Menu.Item>
            ))
            : null
          }
          </React.Fragment> : null
        }

        {otherPermissionsList.map((p) => {
          return (
            <Menu.Item key={Math.random()}>
              <div title={p.name}>
                {p.name}
                <Checkbox onChange={e => handleSelectFeature(p.name, e.target.checked)} checked={userFeatureAccess.includes(p.name)} />
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <Collapse className="user-permissions-container">
        <Collapse.Panel header={<div>{getListOfFeatures(userFeatureAccess)}</div>} key="1">
          {menu}
        </Collapse.Panel>
      </Collapse>
    );
  }
  return (
    getListOfFeatures(userFeatureAccess)
  );
};

FeatureAccessDropdown.propTypes = {
  permissionsList: ImmutablePropTypes.listOf({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  editing: PropTypes.bool,
  userFeatureAccess: ImmutablePropTypes.listOf(String).isRequired,
  setSelectedFeatures: PropTypes.func.isRequired,
};

export default React.memo(FeatureAccessDropdown);
