import { Record, List, Map, OrderedSet, fromJS } from 'immutable';

export class Translations extends Record({
  names: new Map({}),
  descriptions: new Map({}),
}) {
  constructor(translations) {
    super(fromJS(translations));
  }
}

// class VendorConnectionInCategory extends Record({
//   connectionRef: null,
//   vendorConnectionCategories: List([])
// }) {
//   constructor(vendorConnection) {
//     const updatedVendorConnection = Object.assign({}, vendorConnection);
//     if(vendorConnection.connectionRef && typeof vendorConnection.connectionRef == "string"){
//       updatedVendorConnection.connectionRef = vendorConnection.connectionRef;
//     } else if(vendorConnection.connectionRef && typeof vendorConnection.connectionRef == "object"){
//       updatedVendorConnection.connectionRef = fromJS(vendorConnection.connectionRef);
//     }

//     if(vendorConnection.vendorConnectionCategories && vendorConnection.vendorConnectionCategories.length) {
//       updatedVendorConnection.vendorConnectionCategories = fromJS(vendorConnection.vendorConnectionCategories)
//     }
//     super(updatedVendorConnection);
//   }
// }

export class Category extends Record({
  categoryId: null, // this will be incremented by our counter
  vendorConnection: List([]),
  name: '',
  description: '',
  translations: null,
  channels: List([]),
  dateDeleted: null,
}) {
  constructor(category) {
    const updatedCategory = Object.assign({}, category);
    updatedCategory.categoryId = Number.isInteger(category.categoryId) ? category.categoryId?.toString() : category.categoryId;

    if(category.vendorConnection){
      updatedCategory.vendorConnection = fromJS(category.vendorConnection.map(venCon =>{
        if(typeof venCon.connectionRef == "object"){
          venCon.id = venCon.connectionRef._id;
          venCon.name = venCon.connectionRef.name;
          delete venCon.connectionRef; 
        } else if(typeof venCon.connectionRef == "string"){
          venCon.id = venCon.connectionRef;
        }
        return venCon;
      })); 
    }
    if (category.translations) {
      updatedCategory.translations = new Translations(category.translations);
    }
    if (category.channels) {
      updatedCategory.channels = List(category.channels);
    }
    super(updatedCategory);
  }

}



export class Configuration extends Record({
  id: null,
  configurationId: null,// only used for PUT calls and othre updates only while generating payload. 
  name: "",
  siteIds: List([]), // needed to make regneric vendorList components work as they use siteIds. 
  propertyIds: List([]),
  accountId: null,
  storageGeo: null,
  // sharedScope: ShareScopeType; // not sure if this is needed
  categories: List([]),
  dateCreated: null,
  dateUpdated: null,
  dateDeleted: null,
  version: 1,
  isActive: false,
  isLatestVersion: true,
  nextCategoryIndex: null,
}) {
  constructor(configuration) {
    const updatedConfiguration = Object.assign({}, configuration);
    let largedCatId = -1;
    if (Object.keys(configuration).length) {
      updatedConfiguration.categories = List(configuration.categories).map(p =>{
        if(Number.isInteger(p.categoryId)){
          if(p.categoryId > largedCatId){
            largedCatId = p.categoryId;
          }
        }
        return new Category(p)
      });
      if(configuration._id) updatedConfiguration.id = configuration._id;
      // updatedConfiguration.name = configuration.name;
      // if(configuration.propertyIds) updatedConfiguration.siteIds = List(configuration.propertyIds);
      if(configuration.propertyIds) updatedConfiguration.propertyIds = List(configuration.propertyIds);
      if(configuration.accountId) updatedConfiguration.accountId = configuration.accountId;
      updatedConfiguration.nextCategoryIndex = largedCatId + 1;
    }
    super(updatedConfiguration);
  }

  removeFields(fields) {
    let updatedConfig = this;
    fields.forEach(field => {
      updatedConfig = updatedConfig.set(field, undefined);
    });
    return updatedConfig;
  }

  equals(vendorList) {
    return this.id === vendorList.id;
  }
}

export class VendorConnection extends Record({
  connectionId: null, //string
  accountId: null, //number
  name: "",
  vendorId: null, //string
  portalId: null,//string
  authConfig:  Map({
    apiKey: null, // string
    authType: "oauth", //default value
    token: null, //string
    accessToken: null, //string
    refreshToken: null, //string
    clientId: null, //string
    clientSecret: null, //string
  }), 
  status: Map({
    ok: false,
    msg: ""
  }),
  categories: new List([]),
  dateCreated: null,
  dateUpdated: null,
  dateDeleted: null,
}){
  constructor(vendorConnection) {
    const updatedVendorConnection = Object.assign({}, vendorConnection);
    if (vendorConnection._id) updatedVendorConnection.connectionId = vendorConnection._id
    if(vendorConnection.categories){
      updatedVendorConnection.categories = List(vendorConnection.categories);
    }
    if(vendorConnection.authConfig){
      updatedVendorConnection.authConfig = Map(vendorConnection.authConfig);
    }
    if(vendorConnection.connectionStatus){
      updatedVendorConnection.status = Map(vendorConnection.connectionStatus);
    }
    super(updatedVendorConnection);
  }
}

export class SystemVendor extends Record({
  id: null,
  name: '',
  iconUrl: '',
  type: '',//MARKETING or IDENTITY or CUSTOM,
  authConfigType: "apiKey" //'apiKey' or 'token' or 'oauth'
}) {
  constructor(vendor) {
    const updatedVendor = Object.assign({}, vendor);
    updatedVendor.id = vendor._id;
    super(updatedVendor);
  }
}

export class ComfigurationError extends Record({
  errors: List([]),
  err: null
}) {
  constructor(err){
    const updatedErr = Object.assign({}, err);
    
    if(err.errors) updatedErr.errors = List(err.errors);
    
    super(updatedErr);
  }
}

export class VendorConnectionError extends Record({
  errors: List([]),
  err: null
}) {
  constructor(err){
    const updatedErr = Object.assign({}, err);
    
    if(err.errors) updatedErr.errors = List(err.errors);
    
    super(updatedErr);
  }
}