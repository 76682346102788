import { ConfigProvider, Form } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';

import CustomButton from '../../../common/CustomButton.js';
import {
  getVendorTracePropertiesData,
  getVendorTraceRegionsData,
  getVendorTraceUrlsData,
} from '../../../../api/diagnose_dashboard/vendor_trace';
import { formatRegions } from '../../../../helpers/diagnose/utils';
import DashboardSelectDropdown from '../../dashboard/shared/DashboardSelect.jsx';
import { DateFormatDatePicker, customPanelRender } from '../../dashboard/shared/DatePicker.jsx';
import useSearchParams from '../../dashboard/shared/useSearchParams.js';
import { nestedOptionsRenderer, singleOptionRenderer } from './SelectOptionRenderers.jsx';
import AntdDatePicker from '../../../common/AntdDatePicker.jsx';

const { RangePicker } = AntdDatePicker;

// eslint-disable-next-line camelcase
const propertyMap = ({ website_id, name }) => {
  return {
    title: name,
    value: website_id,
  };
};

const urlMap = (url) => {
  return {
    title: url.url,
    value: JSON.stringify(url.scheduled_scan_ids),
  };
};

export const tagRenderer = (props) => {
  return (
    <div className="diagnose-vendor_trace--filter-selection" title={props.label}>
      {props.label}
    </div>
  );
};

const regionsTagRenderer = (options) => (props) => {
  const [selectedZone] = props.value.split(':');
  const optionsOfZone = options.find((option) => option.value === selectedZone);
  const label = optionsOfZone?.children?.find((region) => region.value === props.value)?.title || props?.label;
  return (
    <div className="diagnose-vendor_trace--filter-selection" title={label}>
      {label}
    </div>
  );
};

const VendorTraceFilters = ({ onApply }) => {
  const searchParams = useSearchParams();
  const [scannedPeriod, setScannedPeriod] = useState({
    dateFrom: null,
    dateTo: null,
  });

  const [property, setProperty] = useState(null);

  const [propertiesList, setProperties] = useState([]);
  const [propertiesListLoading, setPropertiesLoading] = useState(false);

  const [scheduledScanIds, setScheduledScanIds] = useState([]);
  const [urlList, setUrlList] = useState([]);
  const [urlListLoading, setUrlListLoading] = useState(false);

  const [region, setRegion] = useState(null);
  const [regionsList, setRegionList] = useState({ plain: [], formatted: [] });
  const [regionsListLoading, setRegionListLoading] = useState(false);

  useEffect(() => {
    if (
      Object.keys(searchParams).length &&
      searchParams?.start_date &&
      searchParams?.end_date &&
      searchParams?.scheduled_scan_ids &&
      searchParams?.property &&
      searchParams?.region
    ) {
      const loadFilters = async () => {
        const scheduledScanIdsFromSearchParams = searchParams.scheduled_scan_ids.split(',');
        const searchedScanPeriod = {
          dateFrom: moment.utc(searchParams.start_date).format('YYYY-MM-DD'),
          dateTo: moment.utc(searchParams.end_date).format('YYYY-MM-DD'),
        };

        const applyFilters = async () => {
          const filters = {
            property: searchParams.property,
            propertyName: searchParams.property_name,
            scannedPeriod: searchedScanPeriod,
            scannedUrl: scheduledScanIdsFromSearchParams,
            region: searchParams.region,
          };
          onApply(filters);
        };
        const propetyListFetch = async () => {
          /* Properties List */
          setScannedPeriod(searchedScanPeriod);
          try {
            setPropertiesLoading(true);
            const propertiesData = await getVendorTracePropertiesData(searchedScanPeriod);
            setProperties(propertiesData.map(propertyMap));
          } catch (error) {
            console.log(error);
          } finally {
            setPropertiesLoading(false);
            setProperty(searchParams.property);
          }
        };
        const urlListFetch = async () => {
          /* Url List */
          try {
            setUrlListLoading(true);
            const urlsData = await getVendorTraceUrlsData({
              ...searchedScanPeriod,
              websiteId: searchParams.property,
            });
            setUrlList(urlsData.map(urlMap));
          } catch (error) {
            console.log(error);
          } finally {
            setUrlListLoading(false);
            setScheduledScanIds(scheduledScanIdsFromSearchParams);
          }
        };
        const regionsListFetch = async () => {
          /* Regions List */
          try {
            setRegionListLoading(true);
            const regionsData = await getVendorTraceRegionsData({
              ...searchedScanPeriod,
              scheduledScanId: scheduledScanIdsFromSearchParams,
            });
            const formatted = formatRegions(regionsData);
            const plain = formatted.flatMap((zone) => zone?.children.map((region) => region.value));
            setRegionList({ formatted, plain });
          } catch (error) {
            console.log(error);
          } finally {
            setRegionListLoading(false);
            setRegion(searchParams.region);
          }
        };
        await Promise.all([propetyListFetch(), urlListFetch(), regionsListFetch(), applyFilters()]);
      };
      loadFilters();
    }
  }, []);

  const handleSelectProperty = async (value) => {
    setScheduledScanIds([]);
    setRegion(null);
    const newProperty = property === value ? null : value;
    setProperty(newProperty);
    if (newProperty) {
      setUrlList([]);
      setUrlListLoading(true);
      try {
        const resp = await getVendorTraceUrlsData({
          ...scannedPeriod,
          websiteId: newProperty,
        });
        setUrlList(resp.map(urlMap));
      } catch (error) {
        console.log(error);
      } finally {
        setUrlListLoading(false);
      }
    }
  };
  const handleClearProperty = () => {
    setProperty('');
    setRegion(null);
    setScheduledScanIds([]);
  };

  const handleClearUrl = () => {
    setRegion(null);
    setScheduledScanIds([]);
  };

  const handleClearRegion = () => {
    setRegion(null);
  };

  const handleSelectPeriod = async (value) => {
    const newDates = {
      dateFrom: moment(value[0]).format('YYYY-MM-DD'),
      dateTo: moment(value[1]).format('YYYY-MM-DD'),
    };
    setProperty('');
    setScheduledScanIds([]);
    setRegion(null);
    setScannedPeriod(newDates);
    if (value) {
      setProperties([]);
      setPropertiesLoading(true);
      try {
        const resp = await getVendorTracePropertiesData(newDates);
        setProperties(resp.map(propertyMap));
      } catch (error) {
        console.log(error);
      } finally {
        setPropertiesLoading(false);
      }
    }
  };

  const handleSelectUrl = async (value) => {
    setRegion(null);
    const newScanIds = JSON.stringify(scheduledScanIds) === value ? [] : JSON.parse(value);
    setScheduledScanIds(newScanIds);
    if (newScanIds.length) {
      setRegionList({ formatted: [], plain: [] });
      setRegionListLoading(true);
      try {
        const resp = await getVendorTraceRegionsData({
          ...scannedPeriod,
          scheduledScanId: newScanIds,
        });
        const formatted = formatRegions(resp);
        const plain = formatted.flatMap((zone) => zone?.children.map((region) => region.value));
        setRegionList({ formatted, plain });
      } catch (error) {
        console.log(error);
      } finally {
        setRegionListLoading(false);
      }
    }
  };

  const handleSelectRegion = (value) => {
    setRegion(region === value ? null : value);
  };

  const onFinish = () => {
    const filters = {
      property,
      propertyName: propertiesList?.find((p) => p.value === property)?.title || '-',
      scannedPeriod,
      scannedUrl: scheduledScanIds,
      region,
    };
    if (Object.keys(searchParams).length) {
      browserHistory.replace(location.pathname);
    }
    onApply(filters);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const isDateHighlighted =
    searchParams?.end_date && moment.utc(scannedPeriod.dateTo).isSame(moment.utc(searchParams?.end_date), 'days');

  const disabledDate = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, 'days')) >= 7;
    }
    return false;
  };

  const handlePickerState = (open) => {
    if (open) {
      setScannedPeriod({
        dateFrom: null,
        dateTo: null,
      });
    }
  };

  const range = scannedPeriod.dateFrom ? [moment(scannedPeriod.dateFrom), moment(scannedPeriod.dateTo)] : null;
  const isPeriodSelected = scannedPeriod.dateFrom && scannedPeriod.dateTo;

  return (
    <div>
      <Form
        className={'diagnose_filters vendor_trace_filter'}
        name="vendor_trace"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        fields={[
          { name: ['period'], value: range },
          { name: ['property'], value: property },
          { name: ['url'], value: scheduledScanIds },
          { name: ['region'], value: region },
        ]}
        requiredMark={'optional'}
      >
        {/* period */}
        <Form.Item
          label="Scanned Period:"
          name="period"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <ConfigProvider locale={enUS}>
            <RangePicker
              getPopupContainer={(trigger) => trigger.parentElement}
              disabled={false}
              allowClear={false}
              size="medium"
              minDate={moment('2023-01-01')}
              maxDate={moment().subtract(1, 'days')}
              onOpenChange={handlePickerState}
              defaultPickerValue={scannedPeriod.dateFrom ? moment(scannedPeriod.dateFrom) : moment()}
              className={`diagnose-range-input ${isDateHighlighted && 'diagnose-notification-highlight'}`}
              picker={'date'}
              panelRender={customPanelRender}
              format={DateFormatDatePicker}
              onChange={handleSelectPeriod}
              defaultValue={range}
              value={range}
              disabledDate={disabledDate}
            />
          </ConfigProvider>
        </Form.Item>
        {/* property */}
        <Form.Item
          label="Property:"
          name="property"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <DashboardSelectDropdown
            options={propertiesList}
            selected={property ? [property] : []}
            onSelect={handleSelectProperty}
            onClear={handleClearProperty}
            placeholder={'Property'}
            disabled={!isPeriodSelected}
            showAll={false}
            loading={propertiesListLoading}
            tagRender={tagRenderer}
            singleOptionRender={singleOptionRenderer}
            className={
              searchParams.property && property === searchParams.property
                ? 'diagnose-notification-highlight selector'
                : ''
            }
          />
        </Form.Item>
        {/* urls */}
        <Form.Item
          label="Scan URL:"
          name="url"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <DashboardSelectDropdown
            options={urlList}
            selected={scheduledScanIds.length ? [JSON.stringify(scheduledScanIds)] : []}
            onSelect={handleSelectUrl}
            onClear={handleClearUrl}
            placeholder={'Url'}
            disabled={!(isPeriodSelected && property)}
            showAll={false}
            loading={urlListLoading}
            tagRender={tagRenderer}
            singleOptionRender={singleOptionRenderer}
            className={
              searchParams?.scheduled_scan_ids &&
              _.isEqual(scheduledScanIds, searchParams?.scheduled_scan_ids?.split(','))
                ? 'diagnose-notification-highlight selector'
                : ''
            }
          />
        </Form.Item>
        {/* region */}
        <Form.Item
          label="Region Scanned:"
          name="region"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <DashboardSelectDropdown
            showSearch={false}
            showAll={false}
            options={regionsList.formatted}
            selected={region ? [region] : []}
            onSelect={handleSelectRegion}
            onClear={handleClearRegion}
            placeholder={'Region'}
            loading={regionsListLoading}
            disabled={!(scheduledScanIds.length && isPeriodSelected)}
            tagRender={regionsTagRenderer(regionsList.formatted)}
            nestedOptionsRender={nestedOptionsRenderer}
            singleOptionRender={singleOptionRenderer}
            className={
              searchParams.region && region === searchParams.region ? 'diagnose-notification-highlight selector' : ''
            }
          />
        </Form.Item>
        <CustomButton
          className="create-button"
          htmlType="submit"
          disabled={!(property && scheduledScanIds.length && isPeriodSelected && region)}
        >
          APPLY
        </CustomButton>
      </Form>
    </div>
  );
};

export default VendorTraceFilters;
