import React, { useEffect, useState } from "react";
import { Input, Select } from "../../../../styleguide";
import PropertySelection from "../../../common/PropertySelection";
import { fromJS } from "immutable";
import { Typography } from "antd";

const NameAndProperties= (props) => {
  console.time("NameAndPropertiesVL"); //to check rendering

  const updateCategoryName = (name) => {
    const updatedConfig = props.configuration.set('name', name)
    props.updateConfiguration((config) => config.set('name', name));
  }

  const updateSelectedProperties = (propertyIds) => {
    props.updateConfiguration((config) => config.set('propertyIds', fromJS(propertyIds)));
  }

  const configName = props.configuration.get('name')
  const regulationNameError = configName.trim().length === 0 ? "Please Enter Configuration Name" : props.preferenceConfigurationNamesList?.includes(configName.trim()) ? <>Configuration with name <b>{configName}</b> already exists</> : null
  console.timeEnd("NameAndPropertiesVL"); //to check rendering

  return (
    <>
      {!props.isEditFlow ? <div className="step-breif">Utilize this wizard to set up Sourcepoint preference categories and their relationship to integration categories for the specified properties.</div> : null}
      <div className="data-field">
        <Typography.Title level={5}>Configuration Name</Typography.Title>
        <Input
          placeholder="Enter Name"
          value={configName}
          onChange={({target: {value}}) => {
            updateCategoryName(value);
          }}
          error={props.showError && regulationNameError ? regulationNameError : null}
          maxLength={60}
          showCount={true}
        />
      </div>
      <div className="step-header">
        <Typography.Title level={5}>Property Selection</Typography.Title>
      </div>
      <div className="step-breif">Select the properties or property groups you wish this configuration sto apply to.</div>
      <Typography.Text type="danger">{props.showError && props.configuration.get('propertyIds').size == 0? "Select at least one property" : null}</Typography.Text>
      <div className="step-section">
        <div className={`section-content ${props.showError && props.configuration.get('propertyIds').size == 0 ? 'validation-error' : null}`}>
          <PropertySelection
            selectedPropertiesKeys={props.configuration.get('propertyIds')?.toJS()}
            setSelectedPropertiesKeys={updateSelectedProperties}
            sites={props.sites}
            siteGroups={props.siteGroups}
          />
        </div>
      </div>
    </>
  )
}

export default NameAndProperties