import React, { useState, useEffect, useRef } from "react";
import { Tag, Typography, Button, Upload, Row, Col, Space, Modal, message } from "antd";
import { findObjectByKeyRef } from "./helper";
import { CheckOutlined, DownloadOutlined, LeftOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Input,
  SelectDropdown,
  Radio,
} from "../../../../../styleguide";
import CSVLink from "../../../../common/CSVLink.js";
import { convertToCSV } from "../../../../utils.js";

const { Dragger } = Upload;

const tabsFieldTranslationkey = { 1: "names", 2: "descriptions" };

const BulkUploadModal = (props) => {
  const [choiceValue, setChoiceValue] = useState(1);
  const [languages, setLanguages] = useState(props.selectedLang);
  const [purposes, setPurposes] = useState(props?.purposeOptions.map((p) => p.value));
  const parseCsvRow = (row) => {
   const regex = /("([^"])*"|[^,]+)(?:,|$)/g;
  // const regex = /("(?:(?:"")*[^"]*)*"|[^,]+)(?:,|$)/g;
      const values = [];
      let match;

      while ((match = regex.exec(row))) {
        values.push(match[1].replace(/""/g, '"'));
      }

      return values;
    };

  const choiceValueRef = useRef(choiceValue);
  const languagesRef = useRef(languages);
  const purposesRef = useRef(purposes);

  useEffect(() => {
    // Update the ref when choiceValue changes
    choiceValueRef.current = choiceValue;
    languagesRef.current = languages;
    purposesRef.current = purposes;
  }, [choiceValue,languages]); 

  const beforeUpload = async (file) => {
    const reader = new FileReader();
    const currentChoiceValue = choiceValueRef.current;
    const languagesChoiceValue = languagesRef.current;
    const purposesChoiceValue = purposesRef.current;
    const sampleData = getSampleData(currentChoiceValue, languagesChoiceValue);
    const selLanguages = props.allLanguages.filter((lang) =>
      languagesChoiceValue.includes(lang.code)
    ).map((l) => l.language);
    if (file) {
      const allowedExtensions = ["csv", "xlsx", "xls"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        // Process the CSV file here using the CSVLink or do whatever you need
        reader.readAsText(file, "UTF-8");
        reader.onload = (e) => {
          // let csv = e.target.result;
          // const rows = csv.slice(csv.indexOf('\n') + 1).split(/\r\n|\n/);
          // const filteredRows = rows.map(row => (row.search("\t") !== -1) ? row.split('\t') : row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)).filter(([purposeName, language, translation]) => purposeName !== '')
          // const santizedTranslationRows = filteredRows.map(arr => arr.map(item => item.replaceAll('"', '')))
          // uploadTranslations(santizedTranslationRows)
          let fileContent = e.target.result;
          const parsedData = [];

          const rows = fileContent.split(/\r\n|\r|\n/);
          const headers = rows[0].split(",").filter((r) => r !== "");

          for (let i = 1; i < rows.length; i++) {
            const rowData = parseCsvRow(rows[i]);

            const rowObj = {};
            for (let j = 0; j < headers.length; j++) {
              const key = headers[j].replace(/"/g, ""); // Remove surrounding quotes from the header key
              const value = rowData[j].replace(/"/g, ""); // Remove surrounding quotes from the row value
              // if (selLanguages.includes(key)) {
                rowObj[key] = value;
             // }
            }

            if (Object.keys(rowObj).length > 0) {
              parsedData.push(rowObj);
            }
          }
          uploadTranslations(parsedData, sampleData, currentChoiceValue);
        };
        return false;
      }
    }
  };

  const onChange = (e) => {
    const options = e.target.value === 1 ? purposeOptions : descriptionOptions;
    const values =
      e.target.value === 1
        ? purposeOptions.map((p) => p.value)
        : descriptionOptions.map((p) => p.value);
    setChoiceValue(e.target.value);
    setOptions(options);
    setPurposes(values);
    updateSampleData(values, e.target.value);
  };

  const customBeforeUpload = async (file) => {
    const currentChoiceValue = choiceValueRef.current;
    console.log(languages)
    beforeUpload(file, currentChoiceValue);
  };

  const uploadParams = {
    name: "file",
    // accept: '.csv',
    maxCount: 1,
    openFileDialogOnClick: true,
    beforeUpload: beforeUpload,
    onChange: (props) => {
      if(props.file.status == "error") {
        props.file.response = "Invalid file format."
        message.error("Invalid file format.")
      }else if(!props.file.status){
        props.file.response = "File uploaded successfully"
        message.success("File uploaded successfully.")
      }
    }
  };

  const {
    closeModal,
    selectedLang,
    purposeOptions,
    descriptionOptions,
    regulationCategories,
    uploadTranslations,
    activeTab,
    allLanguages,
  } = props;
  //const [purposes, setPurposes] = useState(purposeOptions.map((p) => p.value));
  const [descriptions, setDescriptions] = useState(
    descriptionOptions.map((p) => p.value)
  );
  const [sampleData, setSampleData] = useState([]);
  const [uploadProps, setUploadProps] = useState(uploadParams);
  const [showSuccessMessage, setShowSuccessMsg] = useState("hidden");
  const [options, setOptions] = useState(purposeOptions);
  const [values, setValues] = useState(purposeOptions.map((p) => p.value));

  let languageOptions = allLanguages.toJS()
    .filter((c) => selectedLang?.includes(c.code))
    .map((l, i) => ({
      label: l.language,
      value: l.code,
    }));

  const handleSelectedLanguage = (value) => {
    setLanguages(value);
  };
  const handleSelectedPurpose = (value) => {
    setPurposes(value);
  };

  useEffect(() => {
    updateSampleData(purposes, choiceValue);
  }, [languages, purposes, choiceValue]);

  const updateSampleData = (options, choiceValue) => {
    let sampleData = [];
    const selLanguages = allLanguages.filter((lang) =>
      languages.includes(lang.code)
    ).map((l) => l.language);
    const fieldTranslationkey = tabsFieldTranslationkey[choiceValue.toString()];
    options.map((opt) => {
      let purpose =
        findObjectByKeyRef(
          regulationCategories,
          "categories",
          "purposeRef",
          opt
        ) ||
        findObjectByKeyRef(
          regulationCategories,
          "categories",
          "systemStackRef",
          opt
        ) ||
        findObjectByKeyRef(regulationCategories, "categories", "categoryId", opt);
      if (purpose) {
        const newData = {
          "Category": purpose?.privacyChoice || purpose?.name,
        };
        selLanguages.forEach((lang) => {
          const langCode = allLanguages.find(
            (l) => l.language === lang
          )?.code.toLowerCase();
          const translation =
            purpose?.translations[fieldTranslationkey][langCode] || "";
          newData[lang] = translation || ""; // Initialize other language keys with empty string
        });
        sampleData.push(newData);
      }
    });
    const csvContent = sampleData.length > 0 ? convertToCSV(sampleData) : "";
    setSampleData(csvContent);
  };

  const getSampleData = (activeTab,languages) => {
    let sampleData = [];
    const selLanguages = allLanguages.filter((lang) =>
      languages.includes(lang.code)
    ).map((l) => l.language);
    const fieldTranslationkey = tabsFieldTranslationkey[activeTab.toString()];
    const options =
      activeTab === 1
        ? purposeOptions.map((p) => p.value)
        : descriptionOptions.map((p) => p.value);
    options.map((opt) => {
      let purpose = findObjectByKeyRef(regulationCategories, "categories", "categoryId", opt);
      if (purpose) {
        const newData = {
          "Category": purpose?.privacyChoice || purpose?.name,
        };
        selLanguages.forEach((lang) => {
          const langCode = allLanguages.find(
            (l) => l.language === lang
          )?.code.toLowerCase();
          const translation =
            purpose?.translations[fieldTranslationkey][langCode] || "";
          newData[lang] = translation || ""; // Initialize other language keys with empty string
        });
        sampleData.push(newData);
      }
    });
    console.log(sampleData);

    return sampleData;
  };

  const handleDownload = (e) => {
    //const utf8Bytes = new TextEncoder().encode(sampleData);
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), sampleData], {
      type: "text/csv;charset=utf-8",
    });
    //const blob = new Blob([utf8Bytes], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    //const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = new Date() + "Bulk_Upload.csv";
    a.click();
    URL.revokeObjectURL(url);
    setShowSuccessMsg("visible");
    e.stopPropagation();
  };

  return (
    <Modal
      wrapClassName={`bulk-upload`}
      open={true}
      visible={true}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Close
        </Button>,
      ]}
      onCancel={closeModal}
      title={
        props.onEditScreen
          ? "Manage all Categories"
          : "Bulk Upload Translations"
      }
      width={props.onEditScreen ? 1200 : 730}
      style={{
        top: "5%",
      }}
    >
      <Row gutter={[16, 24]}>
        <Col span={12}>
          {props.onEditScreen ? (
            <Space>
              <Button
                icon={<LeftOutlined />}
                type="text"
                size="middle"
                onClick={closeModal}
              />
              <div>
                <Typography.Text level={4} disabled={true}>
                  {"Bulk Upload Translations"}
                </Typography.Text>
              </div>
            </Space>
          ) : null}
        </Col>
      </Row>
      <div className="main-container">
        <div className="choice-container">
          <div>Upload for: </div>
          <Radio.Group
            style={{ marginTop: "10px" }}
            onChange={onChange}
            value={choiceValue}
          >
            <Radio value={1}>Title</Radio>
            <Radio value={2}>Description</Radio>
          </Radio.Group>
        </div>
        <div className="dropdown-container">
          <div className="select-container">
            <div>Language(s)</div>
            <SelectDropdown
              options={languageOptions}
              onChange={(value) => handleSelectedLanguage(value)}
              value={languages}
              selectAll
              multiple
              showSearch
              directUpdate
            />
          </div>
          <div className="select-container">
            <div>Categories</div>
            <SelectDropdown
              options={options}
              onChange={(value) => handleSelectedPurpose(value)}
              value={purposes}
              selectAll
              multiple
              showSearch
              directUpdate
            />
          </div>
        </div>
        <div className="csv-container">
          <div
            className="success-alert"
            style={{ visibility: showSuccessMessage }}
          >
            <CheckOutlined />
            <span className="success-text">Successfully Downloaded</span>
          </div>
          <Button type="primary" onClick={handleDownload}>
            <DownloadOutlined />
            Download CSV Template
          </Button>
        </div>
        <div className="upload-container">
          <div className="upload-title">Upload translations CSV</div>
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Dragger>
        </div>
      </div>
    </Modal>
  );
};
BulkUploadModal.propTypes = {};
export default BulkUploadModal;
