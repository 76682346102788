import React, { useState, useEffect,useRef } from 'react';
import { Tag, Upload } from 'antd';
import { findObjectByKeyRef } from "./helper";
import { CheckOutlined,DownloadOutlined } from "@ant-design/icons";
import { Input, Button, Modal, SelectDropdown, Radio, message } from '../../../../../../styleguide';
import { COUNTRY_LANGUAGES } from '../../../../../../constants';
import CSVLink from "../../../../../common/CSVLink.js";
import { convertToCSV } from '../../../../../utils.js';

const { Dragger } = Upload;

const tabsFieldTranslationkey = { "1": "names", "2": "descriptions" }

const BulkUploadModal = (props) => {
    const [choiceValue, setChoiceValue] = useState(1);
    const parseCsvRow = (row) => {
        const regex = /("(?:(?:"")*[^"]*)*"|[^,]+)(?:,|$)/g;
        const values = [];
        let match;

        while ((match = regex.exec(row))) {
            values.push(match[1].replace(/""/g, '"'));
        }

        return values;
    };

    const choiceValueRef = useRef(choiceValue);

    useEffect(() => {
        // Update the ref when choiceValue changes
        choiceValueRef.current = choiceValue;
    }, [choiceValue]);


    const beforeUpload = async (file) => {
        const reader = new FileReader();
        const currentChoiceValue = choiceValueRef.current;
        const sampleData = getSampleData(currentChoiceValue);
            if (file) {
                const allowedExtensions = ["csv", "xlsx", "xls"];
                const fileExtension = file.name.split(".").pop().toLowerCase();

                if (allowedExtensions.includes(fileExtension)) {

                    // Process the CSV file here using the CSVLink or do whatever you need
                    reader.readAsText(file, 'UTF-8');
                    reader.onload = e => {
                        // let csv = e.target.result;
                        // const rows = csv.slice(csv.indexOf('\n') + 1).split(/\r\n|\n/);
                        // const filteredRows = rows.map(row => (row.search("\t") !== -1) ? row.split('\t') : row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)).filter(([purposeName, language, translation]) => purposeName !== '')
                        // const santizedTranslationRows = filteredRows.map(arr => arr.map(item => item.replaceAll('"', '')))
                        // uploadTranslations(santizedTranslationRows)
                        let fileContent = e.target.result;
                        const parsedData = [];

                        const rows = fileContent.split(/\r\n|\r|\n/);
                        const headers = rows[0].split(',').filter(r => r !== "");

                        for (let i = 1; i < rows.length; i++) {
                            const rowData = parseCsvRow(rows[i]);

                            const rowObj = {};
                            for (let j = 0; j < headers.length; j++) {
                                const key = headers[j].replace(/"/g, ''); // Remove surrounding quotes from the header key
                                const value = rowData[j].replace(/"/g, ''); // Remove surrounding quotes from the row value

                                rowObj[key] = value;
                            }

                            parsedData.push(rowObj);
                        }
                        uploadTranslations(parsedData, sampleData, currentChoiceValue)
                    };
                    return false
                } 
            }
    };

    const onChange = (e) => {
        const options = e.target.value === 1 ? purposeOptions : descriptionOptions;
        const values = e.target.value === 1 ? purposeOptions.map(p => p.value) : descriptionOptions.map(p => p.value);
        setChoiceValue(e.target.value);
        setOptions(options)
        setPurposes(values);
        updateSampleData(values, e.target.value)
    };

    const customBeforeUpload = async (file) => {
        const currentChoiceValue = choiceValueRef.current;
        beforeUpload(file, currentChoiceValue)
    }

    const uploadParams = {
        name: 'file',
       // accept: '.csv',
        maxCount: 1,
        openFileDialogOnClick: true,
        beforeUpload: beforeUpload,
        onChange: (props) => {
            if(props.file.status == "error") {
              props.file.response = "Invalid file format."
              message.error("Invalid file format.")
            }else if(!props.file.status){
              props.file.response = "File uploaded successfully"
              message.success("File uploaded successfully.")
            }
          }
    };

    const { closeModal, selectedLang, purposeOptions,descriptionOptions,regulationCategories,uploadTranslations,activeTab } = props;
    const [languages, setLanguages] = useState(selectedLang);
    const [purposes, setPurposes] = useState(purposeOptions.map(p => p.value));
    const [descriptions, setDescriptions] = useState(descriptionOptions.map(p => p.value));
    const [sampleData, setSampleData] = useState([]);
    const [uploadProps, setUploadProps] = useState(uploadParams)
    const [showSuccessMessage, setShowSuccessMsg] = useState('hidden')
    const [options, setOptions] = useState(purposeOptions)
    const [values, setValues] = useState(purposeOptions.map(p => p.value))

    let languageOptions = COUNTRY_LANGUAGES.toJS().filter((c => selectedLang?.includes(c.code))).map((l, i) => ({
        label: l.language,
        value: l.code,
    }));
    
    const handleSelectedLanguage = (value) => {
        setLanguages(value)
    }
    const handleSelectedPurpose = (value) => {
        setPurposes(value)
    }

    useEffect(() => {
        updateSampleData(purposes,choiceValue)
    }, [languages, purposes,choiceValue])

    const updateSampleData = (options, choiceValue) => {
        let sampleData = [];
        const selLanguages = COUNTRY_LANGUAGES.filter(lang => languages.includes(lang.code)).map(l => l.language)
        const fieldTranslationkey = tabsFieldTranslationkey[choiceValue.toString()]
        options.map(opt => {
            let purpose = findObjectByKeyRef(regulationCategories, 'categories', 'purposeRef', opt) || findObjectByKeyRef(regulationCategories, 'categories', 'systemStackRef', opt) || findObjectByKeyRef(regulationCategories, 'categories', 'id', opt);
            if (purpose) {
                const newData = {
                    "Privacy Choice": purpose?.privacyChoice || purpose?.name,
                };
                selLanguages.forEach(lang => {
                    const langCode = COUNTRY_LANGUAGES.find(l => l.language === lang)?.code.toLowerCase();
                    const negTrans = purpose?.negativeTranslations[fieldTranslationkey][langCode] || "";
                    const positive = purpose?.translations[fieldTranslationkey][langCode] || "";
                    let translation = purpose.isNegative ? negTrans : positive;
                    newData[lang] = translation || ""; // Initialize other language keys with empty string
                });
                sampleData.push(newData)
            }
        })
        const csvContent = (sampleData.length > 0) ? convertToCSV(sampleData) : "";
        setSampleData(csvContent);
    }

    const getSampleData = (activeTab) => {
        let sampleData = [];
        const selLanguages = COUNTRY_LANGUAGES.filter(lang => languages.includes(lang.code)).map(l => l.language)
        const fieldTranslationkey = tabsFieldTranslationkey[activeTab.toString()]
        const options = activeTab === 1 ? purposeOptions.map(p => p.value) : descriptionOptions.map(p => p.value);
        options.map(opt => {
            let purpose = findObjectByKeyRef(regulationCategories, 'categories', 'purposeRef', opt) || findObjectByKeyRef(regulationCategories, 'categories', 'systemStackRef', opt) || findObjectByKeyRef(regulationCategories, 'categories', "id",opt);
            if (purpose) {
                const newData = {
                    "Privacy Choice": purpose?.privacyChoice || purpose?.name,
                };
                selLanguages.forEach(lang => {
                    const langCode = COUNTRY_LANGUAGES.find(l => l.language === lang)?.code.toLowerCase();
                    const negTrans = purpose?.negativeTranslations[fieldTranslationkey][langCode] || "";
                    const positive = purpose?.translations[fieldTranslationkey][langCode] || "";
                    let translation = purpose.isNegative ? negTrans : positive;
                    newData[lang] = translation || ""; // Initialize other language keys with empty string
                });
                sampleData.push(newData)
            }
        })
        console.log(sampleData)
        
        return sampleData
    }

    const handleDownload = (e) => {
        //const utf8Bytes = new TextEncoder().encode(sampleData);
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), sampleData], { type: 'text/csv;charset=utf-8' });
        //const blob = new Blob([utf8Bytes], { type: 'text/csv;charset=utf-8' });
        const url = URL.createObjectURL(blob);

        //const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = new Date() + 'Bulk_Upload.csv';
        a.click();
        URL.revokeObjectURL(url);
        setShowSuccessMsg('visible')
        e.stopPropagation()
    };


    return (
        <Modal
            wrapClassName={`bulk-upload`} 
            open={true}
            visible={true}
            footer={null}
            onCancel={closeModal}
            title={"Bulk Upload Translations"}
            width={"730px"}
        >
            <div className="main-container">
            <div className="choice-container">
                <div>Upload for: </div>
                <Radio.Group style={{ marginTop: "10px" }} onChange={onChange} value={choiceValue}>
                    <Radio value={1}>Choice Text</Radio>
                    <Radio value={2}>Description</Radio>
                </Radio.Group> 
            </div>
            <div style={{marginTop:"10px"}}>CSV Template Downloader</div>
            <div className="dropdown-container">
                <div className="select-container">
                <div>Language(s)</div>
                <SelectDropdown
                    options={languageOptions}
                    onChange={(value) =>
                        handleSelectedLanguage(value)
                    }
                    value={languages}
                    selectAll
                    multiple
                    showSearch
                    directUpdate
                    />
                </div>
                <div className="select-container">
                <div>Privacy Choices</div>
                <SelectDropdown
                    options={options}
                    onChange={(value) =>
                        handleSelectedPurpose(value)
                    }
                    value={purposes}
                    selectAll
                    multiple
                    showSearch
                    directUpdate
                    />
                    </div>
                </div>
                <div className="csv-container">
                    <div className="success-alert" style={{ visibility: showSuccessMessage}}>
                        <CheckOutlined />
                        <span className="success-text">Successfully Downloaded</span>
                    </div>
                    <Button type="primary" onClick={handleDownload}><DownloadOutlined />Download CSV Template</Button>
                </div>
                <div className="upload-container">
                    <div className="upload-title">Upload Translations CSV</div>
                    <div className="sub-title">This action will overwrite data only for the selected message sentiment for each Privacy Choice.</div>
                    <Dragger className='upload-div' {...uploadProps}>
                        <div>
                            <p className='drop-files'>Drop file(s) here or Click to Upload </p>
                         </div>
                    </Dragger>
                </div>
            </div>
        </Modal>
    );
}
BulkUploadModal.propTypes = {
    
}
export default BulkUploadModal;
