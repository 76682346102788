import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Navbar from '../navbar/Navbar';
import Navigation from '../navigation/Navigation';
import SubNavigation from '../navigation/SubNavigation';
import { connect } from 'react-redux';
import { User } from '../../records/account_records';
import { clearCurrentUser } from "../../actions/account_actions";
import { getSiteGroups, getAllSites } from '../../actions/site_actions';
import Loading from '../common/Loading.js.jsx';

const getLocal = (val) => {
  if (!localStorage.getItem(val)) {
    return null;
  } else if (localStorage.getItem(val) === 'null') {
    localStorage.removeItem(val);
    return null;
  } else {
    return localStorage.getItem(val);
  }
}


class MainLayout extends React.Component {
  state = {
    site_id: getLocal('spt_site_id') || null,
    site_group_id: getLocal('spt_site_group_id') || null,
    hidden: false,
    open: true,
    loading: true
  }
  static propTypes = {
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User),
      isMasq: PropTypes.bool.isRequired,
      isLocalEnv: PropTypes.bool.isRequired,
      isCanary: PropTypes.bool.isRequired,
    }).isRequired,
  }
  componentDidMount = () => {
    if (this.props.currentUser) {
      this.getStarted();
    }
  }
  async componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.getStarted();
    }
    if (this.props.location !== prevProps.location) {
      document.querySelector('.spt-content').scrollTo({ top: 0, behavior: 'smooth'});
    }
  }
  getStarted = async () => {
    const sites = await this.props.getAllSites();
    const siteGroups = await this.props.getSiteGroups(this.props.currentUser.accountId);
    this.doesSiteExist();
  }
  generateIds = () => {
    const { site_id, site_group_id } = this.state;
    let vars = '?';
    if (site_id) vars += `site_id=${site_id}`;
    if (site_group_id) vars += `&site_group_id=${site_group_id}`;
    return vars;
  }
  doesSiteExist = () => {
    const { site_id, site_group_id } = this.state;
    const siteGroups = this.props.siteGroups.toJS();
    const siteList = this.props.sites.toJS();

    /* Check to see if site is selected */
    let hasSite = false;
    if (site_group_id && site_id) {
      hasSite = siteGroups.find(s => s.id == site_group_id && s.siteId == site_id) ? true : false;
    } else if (site_id) {
      hasSite = siteList.find(s => s.id == site_id) ? true : false;
    };

    /* If no site is selected, select first site/group in list */
    if (!hasSite && siteList.length > 0) {
      this.selectSite(siteList[0].id, null, true);
    } else if (!hasSite && siteGroups.length > 0) {
      this.selectSite(siteGroups[0].siteId, siteGroups[0].id, true);
    } else {
      this.setState({ loading: false });
    }
  }
  selectSite = (site_id, site_group_id, dontRoute) => {
    this.setState({
      site_id: site_id || null,
      site_group_id: site_group_id || null,
    }, () => {
      localStorage.setItem('spt_site_id', site_id || null);
      localStorage.setItem('spt_site_group_id', site_group_id || null);
      this.setState({ loading: false }, () => {
        if (!dontRoute) this.props.router.push('/dialogue/campaigns_v2'+this.generateIds());
      });
    });
  }
  toggleHidden = () => this.setState({ hidden: !this.state.hidden })
  toggleOpen = () => this.setState({ open: !this.state.open })
  isDiagnosePages = () => location.pathname.includes('/diagnose_dashboard') || ['/vendor_trace', '/diagnose/alerts'].includes(location.pathname);
  showDiagnoseNotifications = () => !location.pathname.includes('/diagnose/alerts') && this.isDiagnosePages();
  showSiteDropdown = () => !location.pathname.includes('privacy_lens') && !this.isDiagnosePages();
  showSubnav = () => !location.pathname.includes('privacy_lens') || this.props.currentUser?.masqed;

  render() {
    const { site_id, site_group_id, hidden, loading } = this.state;

    let sites = this.props.sites;
    sites = sites.filterNot((s) => {
      return this.props.siteGroups.map((sg) => sg.siteId).includes(s.id);
    });
    if (this.props.currentUser?.siteAccess) {
      sites = sites.filter((s) =>
        this.props.currentUser?.siteAccess.includes(s.id)
      );
    }
    sites = sites.filter(
      (s) =>
        s.type !== 'property_group' && s.domain && !s.domain.includes('-group-')
    ).toJS();

    const isReworkedDesignLayout = ['/admin/admin_settings'].includes(this.props.location.pathname);
    return (
      <div className={classNames('spt-body', isReworkedDesignLayout && 'gray-layout')}>

        { this.props.currentUser && (
          <Navigation
            currentUser={ this.props.currentUser }
            isLocalEnv={ this.props.route.isLocalEnv }
            isCanary={ this.props.route.isCanary }
            clearCurrentUser={ this.props.clearCurrentUser }
            siteId={site_id}
            siteGroupId={site_group_id}
            generateIds={this.generateIds}
            hidden={this.state.hidden}
            open={this.state.open}
            toggleHidden={this.toggleHidden}
            toggleOpen={this.toggleOpen}
          />
        )}

        <div className={`spt-main ${hidden ? 'closed' : ''} ${this.state.open ? 'opened' : ''}`}>
          { loading && <Loading /> }

          { this.props.currentUser && !loading && this.showSubnav() && (
            <SubNavigation
              currentUser={ this.props.currentUser }
              isLocalEnv={ this.props.route.isLocalEnv }
              isCanary={ this.props.route.isCanary }
              clearCurrentUser={ this.props.clearCurrentUser }
              siteId={site_id}
              siteGroupId={site_group_id}
              selectSite={this.selectSite}
              sites={sites}
              siteGroups={this.props.siteGroups.toJS()}
              showSiteDropdown={this.showSiteDropdown()}
              showDiagnoseNotifications={this.showDiagnoseNotifications()}
            />
          )}

          <div className="spt-content">
            {!loading && this.props.children && React.cloneElement(this.props.children, {
              location: this.props.location,
              menuOpen: this.state.open,
              menuHidden: this.state.hidden,
              generateIds: this.generateIds,
              selectSite: this.selectSite,
              siteId: site_id,
              siteGroupId: site_group_id,
            })}
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    siteGroups: store.siteState.getIn(['siteGroups', 'value'])
  };
};

export default connect(mapStateToProps, {
  clearCurrentUser,
  getSiteGroups,
  getAllSites
})(MainLayout);
