import React, { useState } from 'react';
import { Button, Tag } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const RenderLimitedChips = ({ chips, cutOff }) => {
  const [count, updateCount] = useState(cutOff);
  
  const length = chips.length;
  const viewMoreButton = length > cutOff ? (
    count > cutOff ? (
      <Tag className='pointer' color='processing' bordered onClick={() =>  updateCount(cutOff)}> <MinusOutlined  style={{width:"8px"}}/> </Tag>
    ) : (
      <Tag className='pointer' color='processing' bordered onClick={() => updateCount(length)}>+{length - cutOff}</Tag>
    )
  ) : null;

  const updatedChips = chips.slice(0, count);

  return (
    <div>
      {updatedChips}
      {viewMoreButton}
    </div>
  );
};

export default RenderLimitedChips;
