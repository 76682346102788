import { Map, List, Record } from "immutable";

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

let scenarioConsentUrl;
let scenarioCcpaUrl;
let ScenarioUsnatUrl;
let ScenarioPreferencesUrl;
if (process.env.REACT_APP_PUB_PORTAL_ENV === 'prod') {
  scenarioConsentUrl = 'https://sourcepoint.mgr.consensu.org/tcfv2';
  scenarioCcpaUrl = 'https://ccpa-service.sp-prod.net';
  ScenarioUsnatUrl = 'https://cdn.privacy-mgmt.com/usnat';
  ScenarioPreferencesUrl = 'https://cdn.privacy-mgmt.com/preferences';
} else {
  scenarioConsentUrl = 'https://cmpv2.sp-stage.net/tcfv2';
  scenarioCcpaUrl = 'https://ccpa.sp-stage.net';
  ScenarioUsnatUrl = 'https://usp.sp-stage.net';
  ScenarioPreferencesUrl = 'https://preferences.sp-stage.net';
}

export const ContinueRecord = Record({
  continue: true,
});

export const ContinueOnSatisfyRecord = Record({
  continue_on_satisfy: true,
});

export const RegexMatchRecord = Record({
  regex: '',
  continue_on_satisfy: true,
});

export const MessageIdRecord = Record({
  message_id: null,
});

export const ShowMessageFreqCapRecord = Record({
  message_id: null,
  n: 0,
  t: 0,
});

export const IntRangeKVRecord = Record({
  k: '',
  v_start: 0,
  v_end: 0,
  allow_on_satisfy: true,
});

export const StringValueKVRecord = Record({
  k: '',
  v: '',
  type: 'match',
  allow_on_satisfy: true,
});

export const ShowMessageNTimesRecord = Record({
  message_id: null,
  n: 0,
});

export const GeoGateRecord = Record({
  continue: true,
  geo_list: List([]),
});

export const USStateGateRecord = Record({
  continue: true,
  state_list: List([]),
});

export const IPGateRecord = Record({
  continue: true,
  ip_list: List([]),
});

export const PageviewsRecord = Record({
  num_pv: 0,
});

export const TimeDelayRecord = Record({
  delay_secs: 0,
});

export const SessionMessageNLimit = Record({
  limit: 1,
});

export const ConsentData = Record({
  status: 0,
  consent_origin: 'https://sourcepoint.mgr.consensu.org',
  version: 1,
});

export const ConsentDataV2 = Record({
  status: 0,
  consent_origin: scenarioConsentUrl,
  version: 2,
});

export const ConsentDataCCPA = Record({
  status: 0,
  consent_origin: scenarioCcpaUrl,
  version: 'ccpa',
});

export const ConsentDataUSNAT = Record({
  status: 0,
  consent_origin: ScenarioUsnatUrl,
  version: 'usnat',
});

export const ConsentDataPreferences = Record({
  status: 0,
  consent_origin: ScenarioPreferencesUrl,
  version: 'preferences',
});
// statusKey
// 0 = no action/unknown
// 1 = consented
// 2 = rejected

// see ENG-471 for clean up
export const allEEA = 'AT,BE,BG,HR,CY,CZ,DK,EE,FI,FR,DE,GR,HU,IE,IT,LV,LT,LU,MT,NL,PL,PT,RO,SK,SI,ES,SE,GB,NO,IS,LI';
export const allEU = 'AT,BE,BG,HR,CY,CZ,DK,EE,FI,FR,DE,GR,HU,IE,IT,LV,LT,LU,MT,NL,PL,PT,RO,SK,SI,ES,SE,GB';

export const GRAPH_COLORS = {
  lightBlue: "#84ADFB",
  teal: "#00D3C4",
  purple: "#9E64FF",
  orange: "#FFB300",
  gray: "#E3E8F2",
  pink: "#FF97B2",
  limeGreen: "#bbe468",
  blue: "#337ab7",
  red: "#d9534f",
  green: "#008000",
  brown: "#a52a2a"
};

export const CHOICE_NAMES_READABLE = {
  1: "White List",
  2: "Recovery",
  3: "Alt Ad - Video",
  4: "Custom",
  5: "Redirect",
  6: "Continue with Adblocker",
  7: "Alt Pay",
  8: "Login",
  9: "Custom Javascript",
  10: "Welect",
  11: "Accept All",
  12: "Show Privacy Manager",
  13: "Reject All",
  14: "Samba"
};

export const CHOICE_GROUPS = {
  choice: "Choice Options",
  consent: "Consent Options"
};

export const CHOICE_TYPES = {
  whitelist: 1,
  recovery: 2,
  altAdVideo: 3,
  pubCustom: 4,
  redirect: 5,
  continueWithAdblocker: 6,
  altPay: 7,
  login: 8,
  execJS: 9,
  welect: 10,
  acceptAll: 11,
  privacyManager: 12,
  rejectAll: 13,
  samba: 14,
  consentDismiss: 15
};

export const TYPES = {
  LOCK: "lock",
  NOTICE: "notice",
  REDIRECT: "redirect",
  CUSTOM: "custom"
};

export const SUBTYPE = {
  MODAL: "modal",
  INLINE: "inline",
  TOP: "top",
  BOTTOM: "bottom",
  HTML: "html"
};

export const CONSTANTS = {
  WHITELIST: "whitelist",
  RECOVERY: "recovery",
  ALT_AD_VIDEO: "altAdVideo",
  PUB_CUSTOM: "pubCustom",
  REDIRECT: "redirect",
  CONTINUE_WITH_ADBLOCKER: "continueWithAdblocker",
  LOGIN: "login",
  ALTPAY: "altPay",
  EXEC_JS: "execJS",
  SAMBA: "samba",
  WELECT: "welect",
  OPTIONS: "options",
  OPTION_LIST: "option_list",
  OPTION_DATA: "option_data",
  CONSENT_LANGUAGE: "consent_language",
  TYPE: "type",
  SUBTYPE: "subType",
  REJECT_ALL: "rejectAll",
  ACCEPT_ALL: "acceptAll",
  PRIVACY_MANAGER: "privacyManager"
};

export const BACKEND_STATUS_TYPES = {
  draft: 1,
  public: 2,
  publicOld: 3,
  stage: 4,
  stageOld: 5
};

export const STATUS_TYPES = {
  DRAFT: "draft",
  PUBLIC: "public",
  STAGE: "stage",
  PUBLIC_OLD: "publicOld",
};

export const CURRENCIES = {
  USD: "USD"
};

export const FETCHED = "fetched";

export const CLIENTS_WITH_ADBLOCK_GRAPH_AND_POP_UP_STATS = [
  212,
  314,
  145,
  217,
  314,
  177,
  296,
  347,
  200,
  943,
  741,
  1048
];
// Gruner + Jahr, Prisma, Dennis Publishing, chip.de, gutefrage & süddeutsche, Immediate, Bauer, Gamer Network, Future, Neowin, Autotrader, Haymarket Media Group

export const CONSENT_SCOPES = Map({
  SINGLE: "Single site",
  GROUP: "Shared site",
});

export const PRIVACY_MANAGER_URL = process.env.REACT_APP_PUB_PORTAL_ENV === 'stage'
    ? "https://pm.cmp.sp-stage.net"
    : "https://pm.sourcepoint.mgr.consensu.org";

export const CCPA_PRIVACY_MANAGER_URL = 
process.env.REACT_APP_PUB_PORTAL_ENV === 'stage'
    ? "https://ccpa-pm-dev.sp-prod.net"
    : "https://ccpa-pm.sp-prod.net";

export const NOTICE_URL = 
process.env.REACT_APP_PUB_PORTAL_ENV === 'stage'
    ? "https://notice.sp-stage.net"
    : process.env.REACT_APP_PUB_PORTAL_IS_PREPROD 
      ? "https://preprod-cdn.privacy-mgmt.com"
      : "https://notice.sp-prod.net";

// export const NOTICE_URL = 
// process.env.REACT_APP_PUB_PORTAL_ENV === 'stage'
//     ? "http://0.0.0.0:8080"
//     : process.env.REACT_APP_PUB_PORTAL_IS_PREPROD 
//       ? "https://preprod-cdn.privacy-mgmt.com"
//       : "https://notice.sp-prod.net";

export const CCPA_NOTICE_URL = 
process.env.REACT_APP_PUB_PORTAL_ENV === 'stage'
    ? "https://ccpa-notice.sp-stage.net"
    : "https://ccpa-notice.sp-prod.net";
 
export const GEO_FILTER_LIST = List([
  "All",
  "AF",
  "AX",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AQ",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BA",
  "BW",
  "BV",
  "BR",
  "IO",
  "BN",
  "BG",
  "BF",
  "BI",
  "KH",
  "CM",
  "CA",
  "CV",
  "KY",
  "CF",
  "TD",
  "CL",
  "CN",
  "CX",
  "CC",
  "CO",
  "KM",
  "CG",
  "CD",
  "CK",
  "CR",
  "CI",
  "HR",
  "CU",
  "CY",
  "CZ",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "TF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GW",
  "GY",
  "HT",
  "HM",
  "VA",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IM",
  "IL",
  "IT",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KR",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MO",
  "MK",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MS",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "AN",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NF",
  "MP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PN",
  "PL",
  "PT",
  "PR",
  "QA",
  "RE",
  "RO",
  "RU",
  "RW",
  "BL",
  "SH",
  "KN",
  "LC",
  "MF",
  "PM",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "GS",
  "ES",
  "LK",
  "SD",
  "SR",
  "SJ",
  "SZ",
  "SE",
  "CH",
  "SY",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TK",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "US",
  "UM",
  "UY",
  "UZ",
  "VU",
  "VE",
  "VN",
  "VG",
  "VI",
  "WF",
  "EH",
  "YE",
  "ZM",
  "ZW",
  "EU",
  "could_not_determine"
]);

export const COUNTRY_LANGUAGES = List([
  { language: "Arabic", code: "AR" },
  { language: "Basque", code: "EUS" },
  { language: "Bosnian", code: "BS" },
  { language: "Bulgarian", code: "BG" },
  { language: "Catalan", code: "CA" },
  { language: "Chinese", code: "ZH" },
  { language: "Simplified Chinese", code: "ZH-HANS" }, // New language
  { language: "Traditional Chinese", code: "ZH-HANT" }, // New language
  { language: "Croatian", code: "HR" },
  { language: "Czech", code: "CS" },
  { language: "Danish", code: "DA" },
  { language: "Dutch", code: "NL" },
  { language: "English", code: "EN" },
  { language: "Estonian", code: "ET" },
  { language: "Finnish", code: "FI" },
  { language: "French", code: "FR" },
  { language: "Gaelic", code: "GD" },
  { language: "Galician", code: "GL" },
  { language: "German", code: "DE" },
  { language: "Greek", code: "EL" },
  { language: "Hebrew", code: "HE" },
  { language: "Hungarian", code: "HU" },
  { language: "Icelandic", code: "IS" },
  { language: "Indonesian", code: "ID" }, // New language
  { language: "Italian", code: "IT" },
  { language: "Japanese", code: "JA" },
  { language: "Korean", code: "KO" }, // New language
  { language: "Latvian", code: "LV" },
  { language: "Lithuanian", code: "LT" },
  { language: "Macedonian", code: "MK" },
  { language: "Malay", code: "MS" }, // New language
  { language: "Norwegian", code: "NO" },
  { language: "Polish", code: "PL" },
  { language: "Portuguese", code: "PT" },
  { language: "Romanian", code: "RO" },
  { language: "Russian", code: "RU" },
  { language: "Serbian (Cyrillic)", code: "SR-CYRL" },
  { language: "Serbian (Latin)", code: "SR-LATN" },
  { language: "Slovakian", code: "SK" },
  { language: "Slovenian", code: "SL" },
  { language: "Spanish", code: "ES" },
  { language: "Swedish", code: "SV" },
  { language: "Tagalog", code: "TL" }, // New language
  { language: "Turkish", code: "TR" }, 
]);

export const DATEPICKERS = {
  day: "DAY",
  month: "MONTH",
  week: "WEEK",
  quarter: "QUARTER"
};

export const changelog = {
  opcodes: {
    CREATE: "create",
    DELETE: "delete",
    EDIT: "edit"
  },
  entityTypes: {
    SITE: "site",
    SITE_GROUP: "site_group",
    PARTITION: "partition",
    SCENARIO: "scenario",
    MESSAGE: "message",
    MESSAGE_V2: "message_v2",
    VENDOR_LIST: "vendor_list",
    USP_REGULATION: "US_privacy_regulation",
    PREFERENCE_CONFIGURATIN: "preferences_configuration",
    VENDOR_CONNECTION: "vendor_connection",
    PRIVACY_MANAGER: "privacy_manager",
    campaign: { STAGE: "stage-campaign", PUBLIC: "public-campaign" },
    ACCOUNT_OWNER: "account_owner",
  },
  NO_PARENT: "",
  NO_SITE: []
};

export const LINE_GRAPH_COLOR_LIST = List([
  "#108ee9",
  "#ff950c",
  "#ff0000",
  "#a60cff",
  "#3db725",
  "#0CD6FF",
  "#d35400"
]);

export const LINE_GRAPH_STROKE_PATTERN_LIST = List([
  "",
  "2 2.5 3 4.5 8",
  "2 2"
]);

export const LINE_GRAPH_STROKE_WIDTH_LIST = List(["1", "2", "3"]);

export const LINE_GRAPH_LEGEND_PATTERN_LIST = List([
  "solid",
  "dotted",
  "dashed"
]);

export const DEVICES_LIST = List(["desktop", "mobile", "tablet", "tv"]);

export const BROWSERS_LIST = List([
  "Amazon Silk",
  "Android Browser",
  "Bada",
  "BlackBerry",
  "Chrome",
  "Chromium",
  "Epiphany",
  "Firefox",
  "Focus",
  "Generic",
  "Googlebot",
  "Internet Explorer",
  "K-Meleon",
  "Maxthon",
  "Microsoft Edge",
  "MZ Browser",
  "NAVER Whale Browser",
  "Opera",
  "Opera Coast",
  "PhantomJS",
  "Puffin",
  "QupZilla",
  "Safari",
  "Sailfish",
  "Samsung Internet for Android",
  "SeaMonkey",
  "Sleipnir",
  "Swing",
  "Tizen",
  "UC Browser",
  "Vivaldi",
  "WebOS Browser",
  "WeChat",
  "Yandex Browser"
]);

export const OS_FAMILY_LIST = List([
  "Windows",
  "iOS",
  "Windows Phone",
  "macOS",
  "Android",
  "WebOS",
  "BlackBerry",
  "Bada",
  "Tizen",
  "Linux",
  "Chrome OS",
  "PlayStation 4",
  "Roku"
]);

export const NO_PRIVACY_MANAGER_ERROR =
  "There is no privacy manager associated with this site";
export const NO_PRIVACY_MANAGER_ERROR_SITE_GROUP =
  "There is no privacy managers that are associated with all sites in site group. Please ensure all sites in site group are apart of the same active vendor list.";

export const CURRENCY_TO_SYMBOL = new Map({
  DOLLARS: "$",
  POUNDS: "£",
  EUROS: "€"
});

export const LEGACY_EU_COUNTRY_CODES =[
  "AX",
  "AL",
  "AD",
  "AT",
  "BY",
  "BE",
  "BA",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FO",
  "FI",
  "FR",
  "DE",
  "GI",
  "GR",
  "GG",
  "HU",
  "IE",
  "IM",
  "IT",
  "JE",
  "LV",
  "LT",
  "LU",
  "MT",
  "MD",
  "MC",
  "ME",
  "NL",
  "MK",
  "PL",
  "PT",
  "RO",
  "RU",
  "SM",
  "RS",
  "CS",
  "SK",
  "SI",
  "ES",
  "SJ",
  "SE",
  "CH",
  "UA",
  "GB",
  "VA"
]

export const GEO_SCOPE_FILTER_LIST_DETAILS = List([
  { code: "EEA", name: "EEA + UK", region: "", type: "region" },
  // { code: "EU", name: "EU", region: "", type: "region" },
  { code: "US", name: "US", region: "", type: "region" },
  { code: "CA", name: "Canada", region: "", type: "region" },
  { code: "OTHER", name: "OTHER", region: "", type: "region" },
  { code: "AT", name: "Austria", region: "EEA", type: "country" },
  { code: "BE", name: "Belgium", region: "EEA", type: "country" },
  {
    code: "BG",
    name: "Bulgaria",
    region: "EEA",
    type: "country"
  },
  { code: "HR", name: "Croatia", region: "EEA", type: "country" },
  { code: "CY", name: "Republic of Cyprus", region: "EEA", type: "country" },
  { code: "CZ", name: "Czech Republic", region: "EEA", type: "country" },
  { code: "DK", name: "Denmark", region: "EEA", type: "country" },
  { code: "EE", name: "Estonia", region: "EEA", type: "country" },
  { code: "FI", name: "Finland", region: "EEA", type: "country" },
  { code: "FR", name: "France", region: "EEA", type: "country" },
  { code: "DE", name: "Germany", region: "EEA", type: "country" },
  { code: "GR", name: "Greece", region: "EEA", type: "country" },
  { code: "HU", name: "Hungary", region: "EEA", type: "country" },
  { code: "IS", name: "Iceland", region: "EEA", type: "country" },
  { code: "IE", name: "Ireland", region: "EEA", type: "country" },
  { code: "IT", name: "Italy", region: "EEA", type: "country" },
  { code: "LV", name: "Latvia", region: "EEA", type: "country" },
  {
    code: "LI",
    name: "Liechtenstein",
    region: "EEA",
    type: "country"
  },
  {
    code: "LT",
    name: "Lithuania",
    region: "EEA",
    type: "country"
  },
  {
    code: "LU",
    name: "Luxembourg",
    region: "EEA",
    type: "country"
  },
  { code: "MT", name: "Malta", region: "EEA", type: "country" },
  {
    code: "NL",
    name: "Netherlands",
    region: "EEA",
    type: "country"
  },
  { code: "NO", name: "Norway", region: "EEA", type: "country" },
  { code: "PL", name: "Poland", region: "EEA", type: "country" },
  {
    code: "PT",
    name: "Portugal",
    region: "EEA",
    type: "country"
  },
  { code: "RO", name: "Romania", region: "EEA", type: "country" },
  {
    code: "SK",
    name: "Slovakia",
    region: "EEA",
    type: "country"
  },
  {
    code: "SI",
    name: "Slovenia",
    region: "EEA",
    type: "country"
  },
  { code: "ES", name: "Spain", region: "EEA", type: "country" },
  { code: "SE", name: "Sweden", region: "EEA", type: "country" },

  {
    code: "GB",
    name: "United Kingdom",
    region: "EEA",
    type: "country"
  },

  //50 US state
  { name: "Alabama", code: "AL", region: "US", type: "state" },
  { name: "Alaska", code: "AK", region: "US", type: "state" },
  { name: "Arkansas", code: "AR", region: "US", type: "state" },
  { name: "Arizona", code: "AZ", region: "US", type: "state" },
  { name: "California", code: "CA", region: "US", type: "state" },
  { name: "Colorado", code: "CO", region: "US", type: "state" },
  { name: "Connecticut", code: "CT", region: "US", type: "state" },
  { name: "Delaware", code: "DE", region: "US", type: "state" },
  { name: "District of Columbia", code: "DC", region: "US", type: "state" },
  { name: "Florida", code: "FL", region: "US", type: "state" },
  { name: "Georgia", code: "GA", region: "US", type: "state" },
  { name: "Hawaii", code: "HI", region: "US", type: "state" },
  { name: "Idaho", code: "ID", region: "US", type: "state" },
  { name: "Illinois", code: "IL", region: "US", type: "state" },
  { name: "Indiana", code: "IN", region: "US", type: "state" },
  { name: "Iowa", code: "IA", region: "US", type: "state" },
  { name: "Kansas", code: "KS", region: "US", type: "state" },
  { name: "Kentucky", code: "KY", region: "US", type: "state" },
  { name: "Lousiana", code: "LA", region: "US", type: "state" },
  { name: "Maine", code: "ME", region: "US", type: "state" },
  { name: "Maryland", code: "MD", region: "US", type: "state" },
  { name: "Massachusetts", code: "MA", region: "US", type: "state" },
  { name: "Michigan", code: "MI", region: "US", type: "state" },
  { name: "Minnesota", code: "MN", region: "US", type: "state" },
  { name: "Mississippi", code: "MS", region: "US", type: "state" },
  { name: "Missouri", code: "MO", region: "US", type: "state" },
  { name: "Montana", code: "MT", region: "US", type: "state" },
  { name: "Nebraska", code: "NE", region: "US", type: "state" },
  { name: "Nevada", code: "NV", region: "US", type: "state" },
  { name: "New Hampshire", code: "NH", region: "US", type: "state" },
  { name: "New Jersey", code: "NJ", region: "US", type: "state" },
  { name: "New Mexico", code: "NM", region: "US", type: "state" },
  { name: "New York", code: "NY", region: "US", type: "state" },
  { name: "North Carolina", code: "NC", region: "US", type: "state" },
  { name: "North Dakota", code: "ND", region: "US", type: "state" },
  { name: "Ohio", code: "OH", region: "US", type: "state" },
  { name: "Oklahoma", code: "OK", region: "US", type: "state" },
  { name: "Oregon", code: "OR", region: "US", type: "state" },
  { name: "Pennsylvania", code: "PA", region: "US", type: "state" },
  { name: "Rhode Island", code: "RI", region: "US", type: "state" },
  { name: "South Carolina", code: "SC", region: "US", type: "state" },
  { name: "South Dakota", code: "SD", region: "US", type: "state" },
  { name: "Tennessee", code: "TN", region: "US", type: "state" },
  { name: "Texas", code: "TX", region: "US", type: "state" },
  { name: "Utah", code: "UT", region: "US", type: "state" },
  { name: "Vermont", code: "VT", region: "US", type: "state" },
  { name: "Virginia", code: "VA", region: "US", type: "state" },
  { name: "Washington", code: "WA", region: "US", type: "state" },
  { name: "West Virginia", code: "WV", region: "US", type: "state" },
  { name: "Wisconsin", code: "WI", region: "US", type: "state" },
  { name: "Wyoming", code: "WY", region: "US", type: "state" },

  //Canadian province
  { name: "Alberta", code: "AB", region: "CA", type: "province" },
  { name: "British Columbia ", code: "BC", region: "CA", type: "province" },
  { name: "Manitoba", code: "MB", region: "CA", type: "province" },
  { name: "New Brunswick", code: "NB", region: "CA", type: "province" },
  { name: "Newfoundland and Labrador", code: "NL", region: "CA", type: "province" },
  { name: "Northwest Territories", code: "NT", region: "CA", type: "province" },
  { name: "Nova Scotia", code: "NS", region: "CA", type: "province" },
  { name: "Nunavut", code: "NU", region: "CA", type: "province" },
  { name: "Ontario", code: "ON", region: "CA", type: "province" },
  { name: "Prince Edward Island", code: "PE", region: "CA", type: "province" },
  { name: "1Quebec", code: "QC", region: "CA", type: "province" },
  { name: "Saskatchewan", code: "SK", region: "CA", type: "province" },
  { name: "Yukon", code: "YT", region: "CA", type: "province" },
]);

export const US_STATES = GEO_SCOPE_FILTER_LIST_DETAILS
  .filter(({ region, type }) => region === 'US' && type === 'state')
  .map(({ name, code, region, type }) => ({ name, code, region, type }));


export const GEO_FILTER_LIST_DETAILS = List([
  { code: "EEA", name: "EEA + UK", region: "", type: "region" },
  { code: "NA", name: "North America", region: "", type: "region" },
  // { code: "EU", name: "EU", region: "", type: "region" },
  { code: "OTHER", name: "Other", region: "", type: "region" },
  { code: "CA", name: "Canada", region: "", type: "country" },
  { code: "AF", name: "Afghanistan", region: "AS", type: "country" },
  { code: "AX", name: "Åland", region: "OTHER", type: "country" },
  { code: "AL", name: "Albania", region: "OTHER", type: "country" },
  { code: "DZ", name: "Algeria", region: "AF", type: "country" },
  { code: "AS", name: "American Samoa", region: "OC", type: "country" },
  { code: "AD", name: "Andorra", region: "OTHER", type: "country" },
  { code: "AO", name: "Angola", region: "AF", type: "country" },
  { code: "AI", name: "Anguilla", region: "NA", type: "country" },
  { code: "AQ", name: "Antarctica", region: "AN", type: "country" },
  { code: "AG", name: "Antigua and Barbuda", region: "NA", type: "country" },
  { code: "AR", name: "Argentina", region: "SA", type: "country" },
  { code: "AM", name: "Armenia", region: "AS", type: "country" },
  { code: "AW", name: "Aruba", region: "NA", type: "country" },
  { code: "AU", name: "Australia", region: "OC", type: "country" },
  { code: "AT", name: "Austria", region: "EEA", type: "country" },
  { code: "AZ", name: "Azerbaijan", region: "AS", type: "country" },
  { code: "BS", name: "Bahamas", region: "NA", type: "country" },
  { code: "BH", name: "Bahrain", region: "AS", type: "country" },
  { code: "BD", name: "Bangladesh", region: "AS", type: "country" },
  { code: "BB", name: "Barbados", region: "NA", type: "country" },
  { code: "BY", name: "Belarus", region: "OTHER", type: "country" },
  { code: "BE", name: "Belgium", region: "EEA", type: "country" },
  { code: "BZ", name: "Belize", region: "NA", type: "country" },
  { code: "BJ", name: "Benin", region: "AF", type: "country" },
  { code: "BM", name: "Bermuda", region: "NA", type: "country" },
  { code: "BT", name: "Bhutan", region: "AS", type: "country" },
  { code: "BO", name: "Bolivia", region: "SA", type: "country" },
  {
    code: "BQ",
    name: "Bonaire, Sint Eustatius, and Saba",
    region: "NA",
    type: "country"
  },
  { code: "BA", name: "Bosnia and Herzegovina", region: "OTHER", type: "country" },
  { code: "BW", name: "Botswana", region: "AF", type: "country" },
  { code: "BV", name: "Bouvet Island", region: "AN", type: "country" },
  { code: "BR", name: "Brazil", region: "SA", type: "country" },
  {
    code: "IO",
    name: "British Indian Ocean Territory",
    region: "AS",
    type: "country"
  },
  { code: "VG", name: "British Virgin Islands", region: "NA", type: "country" },
  { code: "BN", name: "Brunei", region: "AS", type: "country" },
  {
    code: "BG",
    name: "Bulgaria",
    region: "EEA",
    type: "country"
  },
  { code: "BF", name: "Burkina Faso", region: "AF", type: "country" },
  { code: "BI", name: "Burundi", region: "AF", type: "country" },
  { code: "CV", name: "Cabo Verde", region: "AF", type: "country" },
  { code: "KH", name: "Cambodia", region: "AS", type: "country" },
  { code: "CM", name: "Cameroon", region: "AF", type: "country" },
  // { code: "CA", name: "Canada", region: "NA", type: "country" },
  { code: "KY", name: "Cayman Islands", region: "NA", type: "country" },
  {
    code: "CF",
    name: "Central African Republic",
    region: "AF",
    type: "country"
  },
  { code: "TD", name: "Chad", region: "AF", type: "country" },
  { code: "CL", name: "Chile", region: "SA", type: "country" },
  { code: "CN", name: "China", region: "AS", type: "country" },
  { code: "CX", name: "Christmas Island", region: "OC", type: "country" },
  {
    code: "CC",
    name: "Cocos [Keeling] Islands",
    region: "AS",
    type: "country"
  },
  { code: "CO", name: "Colombia", region: "SA", type: "country" },
  { code: "KM", name: "Comoros", region: "AF", type: "country" },
  { code: "CG", name: "Congo Republic", region: "AF", type: "country" },
  { code: "CK", name: "Cook Islands", region: "OC", type: "country" },
  { code: "CR", name: "Costa Rica", region: "NA", type: "country" },
  { code: "HR", name: "Croatia", region: "EEA", type: "country" },
  { code: "CU", name: "Cuba", region: "NA", type: "country" },
  { code: "CW", name: "Curaçao", region: "NA", type: "country" },
  { code: "CY", name: "Republic of Cyprus", region: "EEA", type: "country" },
  { code: "CZ", name: "Czech Republic", region: "EEA", type: "country" },
  { code: "DK", name: "Denmark", region: "EEA", type: "country" },
  { code: "DJ", name: "Djibouti", region: "AF", type: "country" },
  { code: "DM", name: "Dominica", region: "NA", type: "country" },
  { code: "DO", name: "Dominican Republic", region: "NA", type: "country" },
  { code: "CD", name: "DR Congo", region: "AF", type: "country" },
  { code: "EC", name: "Ecuador", region: "SA", type: "country" },
  { code: "EG", name: "Egypt", region: "AF", type: "country" },
  { code: "SV", name: "El Salvador", region: "NA", type: "country" },
  { code: "GQ", name: "Equatorial Guinea", region: "AF", type: "country" },
  { code: "ER", name: "Eritrea", region: "AF", type: "country" },
  { code: "EE", name: "Estonia", region: "EEA", type: "country" },
  { code: "SZ", name: "Eswatini", region: "AF", type: "country" },
  { code: "ET", name: "Ethiopia", region: "AF", type: "country" },
  { code: "FK", name: "Falkland Islands", region: "SA", type: "country" },
  { code: "FO", name: "Faroe Islands", region: "OTHER", type: "country" },
  { code: "FJ", name: "Fiji", region: "OC", type: "country" },
  { code: "FI", name: "Finland", region: "EEA", type: "country" },
  { code: "FR", name: "France", region: "EEA", type: "country" },
  { code: "GF", name: "French Guiana", region: "SA", type: "country" },
  { code: "PF", name: "French Polynesia", region: "OC", type: "country" },
  {
    code: "TF",
    name: "French Southern Territories",
    region: "AN",
    type: "country"
  },
  { code: "GA", name: "Gabon", region: "AF", type: "country" },
  { code: "GM", name: "Gambia", region: "AF", type: "country" },
  { code: "GE", name: "Georgia", region: "AS", type: "country" },
  { code: "DE", name: "Germany", region: "EEA", type: "country" },
  { code: "GH", name: "Ghana", region: "AF", type: "country" },
  { code: "GI", name: "Gibraltar", region: "OTHER", type: "country" },
  { code: "GR", name: "Greece", region: "EEA", type: "country" },
  { code: "GL", name: "Greenland", region: "NA", type: "country" },
  { code: "GD", name: "Grenada", region: "NA", type: "country" },
  { code: "GP", name: "Guadeloupe", region: "NA", type: "country" },
  { code: "GU", name: "Guam", region: "OC", type: "country" },
  { code: "GT", name: "Guatemala", region: "NA", type: "country" },
  { code: "GG", name: "Guernsey", region: "OTHER", type: "country" },
  { code: "GN", name: "Guinea", region: "AF", type: "country" },
  { code: "GW", name: "Guinea-Bissau", region: "AF", type: "country" },
  { code: "GY", name: "Guyana", region: "SA", type: "country" },
  { code: "HT", name: "Haiti", region: "NA", type: "country" },
  {
    code: "HM",
    name: "Heard Island and McDonald Islands",
    region: "AN",
    type: "country"
  },
  { code: "HN", name: "Honduras", region: "NA", type: "country" },
  { code: "HK", name: "Hong Kong", region: "AS", type: "country" },
  { code: "HU", name: "Hungary", region: "EEA", type: "country" },
  { code: "IS", name: "Iceland", region: "EEA", type: "country" },
  { code: "IN", name: "India", region: "AS", type: "country" },
  { code: "ID", name: "Indonesia", region: "AS", type: "country" },
  { code: "IR", name: "Iran", region: "AS", type: "country" },
  { code: "IQ", name: "Iraq", region: "AS", type: "country" },
  { code: "IE", name: "Ireland", region: "EEA", type: "country" },
  { code: "IM", name: "Isle of Man", region: "OTHER", type: "country" },
  { code: "IL", name: "Israel", region: "AS", type: "country" },
  { code: "IT", name: "Italy", region: "EEA", type: "country" },
  { code: "CI", name: "Ivory Coast", region: "AF", type: "country" },
  { code: "JM", name: "Jamaica", region: "NA", type: "country" },
  { code: "JP", name: "Japan", region: "AS", type: "country" },
  { code: "JE", name: "Jersey", region: "OTHER", type: "country" },
  { code: "JO", name: "Jordan", region: "AS", type: "country" },
  { code: "KZ", name: "Kazakhstan", region: "AS", type: "country" },
  { code: "KE", name: "Kenya", region: "AF", type: "country" },
  { code: "KI", name: "Kiribati", region: "OC", type: "country" },
  { code: "XK", name: "Kosovo", region: "OTHER", type: "country" },
  { code: "KW", name: "Kuwait", region: "AS", type: "country" },
  { code: "KG", name: "Kyrgyzstan", region: "AS", type: "country" },
  { code: "LA", name: "Laos", region: "AS", type: "country" },
  { code: "LV", name: "Latvia", region: "EEA", type: "country" },
  { code: "LB", name: "Lebanon", region: "AS", type: "country" },
  { code: "LS", name: "Lesotho", region: "AF", type: "country" },
  { code: "LR", name: "Liberia", region: "AF", type: "country" },
  { code: "LY", name: "Libya", region: "AF", type: "country" },
  {
    code: "LI",
    name: "Liechtenstein",
    region: "EEA",
    type: "country"
  },
  {
    code: "LT",
    name: "Lithuania",
    region: "EEA",
    type: "country"
  },
  {
    code: "LU",
    name: "Luxembourg",
    region: "EEA",
    type: "country"
  },
  { code: "MO", name: "Macao", region: "AS", type: "country" },
  { code: "MG", name: "Madagascar", region: "AF", type: "country" },
  { code: "MW", name: "Malawi", region: "AF", type: "country" },
  { code: "MY", name: "Malaysia", region: "AS", type: "country" },
  { code: "MV", name: "Maldives", region: "AS", type: "country" },
  { code: "ML", name: "Mali", region: "AF", type: "country" },
  { code: "MT", name: "Malta", region: "EEA", type: "country" },
  { code: "MH", name: "Marshall Islands", region: "OC", type: "country" },
  { code: "MQ", name: "Martinique", region: "NA", type: "country" },
  { code: "MR", name: "Mauritania", region: "AF", type: "country" },
  { code: "MU", name: "Mauritius", region: "AF", type: "country" },
  { code: "YT", name: "Mayotte", region: "AF", type: "country" },
  { code: "MX", name: "Mexico", region: "NA", type: "country" },
  { code: "FM", name: "Micronesia", region: "OC", type: "country" },
  { code: "MD", name: "Moldova", region: "OTHER", type: "country" },
  { code: "MC", name: "Monaco", region: "OTHER", type: "country" },
  { code: "MN", name: "Mongolia", region: "AS", type: "country" },
  { code: "ME", name: "Montenegro", region: "OTHER", type: "country" },
  { code: "MS", name: "Montserrat", region: "NA", type: "country" },
  { code: "MA", name: "Morocco", region: "AF", type: "country" },
  { code: "MZ", name: "Mozambique", region: "AF", type: "country" },
  { code: "MM", name: "Myanmar", region: "AS", type: "country" },
  { code: "NA", name: "Namibia", region: "AF", type: "country" },
  { code: "NR", name: "Nauru", region: "OC", type: "country" },
  { code: "NP", name: "Nepal", region: "AS", type: "country" },
  {
    code: "NL",
    name: "Netherlands",
    region: "EEA",
    type: "country"
  },
  { code: "AN", name: "Netherlands Antilles", region: "NA", type: "country" },
  { code: "NC", name: "New Caledonia", region: "OC", type: "country" },
  { code: "NZ", name: "New Zealand", region: "OC", type: "country" },
  { code: "NI", name: "Nicaragua", region: "NA", type: "country" },
  { code: "NE", name: "Niger", region: "AF", type: "country" },
  { code: "NG", name: "Nigeria", region: "AF", type: "country" },
  { code: "NU", name: "Niue", region: "OC", type: "country" },
  { code: "NF", name: "Norfolk Island", region: "OC", type: "country" },
  { code: "KP", name: "North Korea", region: "AS", type: "country" },
  { code: "MK", name: "North Macedonia", region: "OTHER", type: "country" },
  {
    code: "MP",
    name: "Northern Mariana Islands",
    region: "OC",
    type: "country"
  },
  { code: "NO", name: "Norway", region: "EEA", type: "country" },
  { code: "OM", name: "Oman", region: "AS", type: "country" },
  { code: "PK", name: "Pakistan", region: "AS", type: "country" },
  { code: "PW", name: "Palau", region: "OC", type: "country" },
  { code: "PS", name: "Palestine", region: "AS", type: "country" },
  { code: "PA", name: "Panama", region: "NA", type: "country" },
  { code: "PG", name: "Papua New Guinea", region: "OC", type: "country" },
  { code: "PY", name: "Paraguay", region: "SA", type: "country" },
  { code: "PE", name: "Peru", region: "SA", type: "country" },
  { code: "PH", name: "Philippines", region: "AS", type: "country" },
  { code: "PN", name: "Pitcairn Islands", region: "OC", type: "country" },
  { code: "PL", name: "Poland", region: "EEA", type: "country" },
  {
    code: "PT",
    name: "Portugal",
    region: "EEA",
    type: "country"
  },
  { code: "PR", name: "Puerto Rico", region: "NA", type: "country" },
  { code: "QA", name: "Qatar", region: "AS", type: "country" },
  { code: "RE", name: "Réunion", region: "AF", type: "country" },
  { code: "RO", name: "Romania", region: "EEA", type: "country" },
  { code: "RU", name: "Russia", region: "OTHER", type: "country" },
  { code: "RW", name: "Rwanda", region: "AF", type: "country" },
  { code: "BL", name: "Saint Barthélemy", region: "NA", type: "country" },
  { code: "SH", name: "Saint Helena", region: "AF", type: "country" },
  { code: "LC", name: "Saint Lucia", region: "NA", type: "country" },
  { code: "MF", name: "Saint Martin", region: "NA", type: "country" },
  {
    code: "PM",
    name: "Saint Pierre and Miquelon",
    region: "NA",
    type: "country"
  },
  { code: "WS", name: "Samoa", region: "OC", type: "country" },
  { code: "SM", name: "San Marino", region: "OTHER", type: "country" },
  { code: "ST", name: "São Tomé and Príncipe", region: "AF", type: "country" },
  { code: "SA", name: "Saudi Arabia", region: "AS", type: "country" },
  { code: "SN", name: "Senegal", region: "AF", type: "country" },
  { code: "RS", name: "Serbia", region: "OTHER", type: "country" },
  { code: "CS", name: "Serbia and Montenegro", region: "OTHER", type: "country" },
  { code: "SC", name: "Seychelles", region: "AF", type: "country" },
  { code: "SL", name: "Sierra Leone", region: "AF", type: "country" },
  { code: "SG", name: "Singapore", region: "AS", type: "country" },
  { code: "SX", name: "Sint Maarten", region: "NA", type: "country" },
  {
    code: "SK",
    name: "Slovakia",
    region: "EEA",
    type: "country"
  },
  {
    code: "SI",
    name: "Slovenia",
    region: "EEA",
    type: "country"
  },
  { code: "SB", name: "Solomon Islands", region: "OC", type: "country" },
  { code: "SO", name: "Somalia", region: "AF", type: "country" },
  { code: "ZA", name: "South Africa", region: "AF", type: "country" },
  {
    code: "GS",
    name: "South Georgia and South Sandwich Islands",
    region: "AN",
    type: "country"
  },
  { code: "KR", name: "South Korea", region: "AS", type: "country" },
  { code: "SS", name: "South Sudan", region: "AF", type: "country" },
  { code: "ES", name: "Spain", region: "EEA", type: "country" },
  { code: "LK", name: "Sri Lanka", region: "AS", type: "country" },
  { code: "KN", name: "St Kitts and Nevis", region: "NA", type: "country" },
  {
    code: "VC",
    name: "St Vincent and Grenadines",
    region: "NA",
    type: "country"
  },
  { code: "SD", name: "Sudan", region: "AF", type: "country" },
  { code: "SR", name: "Suriname", region: "SA", type: "country" },
  { code: "SJ", name: "Svalbard and Jan Mayen", region: "OTHER", type: "country" },
  { code: "SE", name: "Sweden", region: "EEA", type: "country" },
  { code: "CH", name: "Switzerland", region: "OTHER", type: "country" },
  { code: "SY", name: "Syria", region: "AS", type: "country" },
  { code: "TW", name: "Taiwan", region: "AS", type: "country" },
  { code: "TJ", name: "Tajikistan", region: "AS", type: "country" },
  { code: "TZ", name: "Tanzania", region: "AF", type: "country" },
  { code: "TH", name: "Thailand", region: "AS", type: "country" },
  { code: "TL", name: "Timor-Leste", region: "OC", type: "country" },
  { code: "TG", name: "Togo", region: "AF", type: "country" },
  { code: "TK", name: "Tokelau", region: "OC", type: "country" },
  { code: "TO", name: "Tonga", region: "OC", type: "country" },
  { code: "TT", name: "Trinidad and Tobago", region: "NA", type: "country" },
  { code: "TN", name: "Tunisia", region: "AF", type: "country" },
  { code: "TR", name: "Turkey", region: "AS", type: "country" },
  { code: "TM", name: "Turkmenistan", region: "AS", type: "country" },
  {
    code: "TC",
    name: "Turks and Caicos Islands",
    region: "NA",
    type: "country"
  },
  { code: "TV", name: "Tuvalu", region: "OC", type: "country" },
  {
    code: "UM",
    name: "U.S. Minor Outlying Islands",
    region: "OC",
    type: "country"
  },
  { code: "VI", name: "U.S. Virgin Islands", region: "NA", type: "country" },
  { code: "UG", name: "Uganda", region: "AF", type: "country" },
  { code: "UA", name: "Ukraine", region: "OTHER", type: "country" },
  { code: "AE", name: "United Arab Emirates", region: "AS", type: "country" },
  {
    code: "GB",
    name: "United Kingdom",
    region: "EEA",
    type: "country"
  },
  { code: "US", name: "United States", region: "NA", type: "country" },
  { code: "UY", name: "Uruguay", region: "SA", type: "country" },
  { code: "UZ", name: "Uzbekistan", region: "AS", type: "country" },
  { code: "VU", name: "Vanuatu", region: "OC", type: "country" },
  { code: "VA", name: "Vatican City", region: "OTHER", type: "country" },
  { code: "VE", name: "Venezuela", region: "SA", type: "country" },
  { code: "VN", name: "Vietnam", region: "AS", type: "country" },
  { code: "WF", name: "Wallis and Futuna", region: "OC", type: "country" },
  { code: "EH", name: "Western Sahara", region: "AF", type: "country" },
  { code: "YE", name: "Yemen", region: "AS", type: "country" },
  { code: "ZM", name: "Zambia", region: "AF", type: "country" },
  { code: "ZW", name: "Zimbabwe", region: "AF", type: "country" },

  //Canadian province
  { name: "Alberta", code: "AB", region: "CA", type: "province" },
  { name: "British Columbia ", code: "BC", region: "CA", type: "province" },
  { name: "Manitoba", code: "MB", region: "CA", type: "province" },
  { name: "New Brunswick", code: "NB", region: "CA", type: "province" },
  { name: "Newfoundland and Labrador", code: "NL", region: "CA", type: "province" },
  { name: "Northwest Territories", code: "NT", region: "CA", type: "province" },
  { name: "Nova Scotia", code: "NS", region: "CA", type: "province" },
  { name: "Nunavut", code: "NU", region: "CA", type: "province" },
  { name: "Ontario", code: "ON", region: "CA", type: "province" },
  { name: "Prince Edward Island", code: "PE", region: "CA", type: "province" },
  { name: "1Quebec", code: "QC", region: "CA", type: "province" },
  { name: "Saskatchewan", code: "SK", region: "CA", type: "province" },
  { name: "Yukon", code: "YT", region: "CA", type: "province" },
]);

export const COUNTRIES = GEO_FILTER_LIST_DETAILS
  .filter(({ type }) => type === 'country')
  .map(({ name, code, region }) => ({ name, code, region }));

//List of countries that available for a free site scan
export const PROXY_LOCATIONS = new List([
  { code: "AU", name: "Australia" },
  { code: "AT", name: "Austria" },
  { code: "BE", name: "Belgium" },
  { code: "BR", name: "Brazil" },
  { code: "CA", name: "Canada" },
  { code: "DK", name: "Denmark" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "France" },
  { code: "DE", name: "Germany" },
  { code: "IS", name: "Iceland" },
  { code: "IN", name: "India" },
  { code: "IE", name: "Ireland" },
  { code: "IT", name: "Italy" },
  { code: "JP", name: "Japan" },
  { code: "LU", name: "Luxembourg" },
  { code: "MX", name: "Mexico" },
  { code: "NL", name: "Netherlands" },
  { code: "NO", name: "Norway" },
  { code: "PH", name: "Philippines" },
  { code: "PL", name: "Poland" },
  { code: "RO", name: "Romania" },
  { code: "ZA", name: "South Africa" },
  { code: "ES", name: "Spain" },
  { code: "SE", name: "Sweden" },
  { code: "CH", name: "Switzerland" },
  { code: "UA", name: "Ukraine" },
  { code: "US", name: "United States" },
  { code: "UK", name: "United Kingdom" },
]);

// changed mapped item from mutable to immutable as was breaking reporting functionality
export const GEO_LIST_MERGED = new List([
  ...GEO_SCOPE_FILTER_LIST_DETAILS,
  ...GEO_FILTER_LIST_DETAILS
    .filter(item => {
      const isInScopeList = GEO_SCOPE_FILTER_LIST_DETAILS.find(({ name }) => name === item.name);
      return !isInScopeList && !(item.type === 'region' && (item.code === 'NA' || item.code === 'OTHER'));
    })
    .map(item => Map(item).set('region', 'OTHER').set('code', item.name === 'Canada' ? 'CAN' : item.code).toJS())
]);

export const GEO_LIST_TREE_STRUCTURE =  [
  {
    title: 'US',
    code: 'US',
    key: 'US',
    children: []
  },
  {
    title: 'EEA + UK',
    code: 'EEA',
    key: 'ALL_EEA',
    children: []
  },
  {
    title: 'Canada',
    code: 'CA',
    key: 'CAN',
    children: []
  },
  {
    title: 'Others',
    code: 'OTHER',
    key: 'ALL_OTHER',
    children: []
  }
];

GEO_LIST_MERGED.forEach( r => {
  if(r.type === 'region' || (r.type ==='country' && r.code === 'US')) return;

  if(typeof r.region === 'string') {
    let region = GEO_LIST_TREE_STRUCTURE.find(t=>t.code == r.region);
    if(r.type === 'state') {
      region.children.push({title: r.name, code: `STATE_${r.code}`, key: `${region.code};${r.code}`})
    } else if(r.type === 'province'){
      region.children.push({title: r.name, code: `CA_${r.code}`, key: `${region.code};${r.code}`})
    } else {
      region.children.push({title: r.name, code: r.code, key: `${region.code};${r.code}`})
    }
  } else {
    r.region.forEach( region => {
    let obj = GEO_LIST_TREE_STRUCTURE.find(t=>t.code == region);
    if(r.type === 'state') {
      obj.children.push({title: r.name, code: `STATE_${r.code}`, key: `${obj.code};${r.code}`})
    }else if(r.type === 'province'){
      obj.children.push({title: r.name, code: `CA_${r.code}`, key: `${obj.code};${r.code}`})
    } else {
      obj.children.push({title: r.name, code: r.code, key: `${obj.code};${r.code}`})
    }
    })
  };
});

export const region_title_index_map = {
  ...GEO_LIST_TREE_STRUCTURE.reduce((map, item) => {
  item.children.forEach(child => {
    map[child.code] = child.title;
  });
  map[item.key] = item.title;
  return map;
}, {}),
  "ALL_EU": "EU",
  "ALL": "All"
};

export const durationTypes = {
  SESSION: 'session',
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day',
  HOUR: 'hour',
  MINUTE: 'minute',
};

export const urlMappingPatterns = {
  MATCHES: { key: 'MATCHES', text: 'match' },
  BEGINS_WITH: { key: 'BEGINS_WITH', text: 'begin with' },
  ENDS_WITH: { key: 'ENDS_WITH', text: 'end with' },
  CONTAINS: { key: 'CONTAINS', text: 'contain' },
};

export const dialogueTypes = {
  MESSAGE: 'message',
  PARTITION: 'partition',
  SCENARIO: 'scenario',
}

export const messageEndpoints = {
  MESSAGES: `${permissionsSvsBase}/msg/v2/get_message_list`,
  CREATE: `${permissionsSvsBase}/msg/v2/create_message`,
  UPDATE: `${permissionsSvsBase}/msg/v2/update_message`,
  DELETE: `${permissionsSvsBase}/msg/v2/delete_message`,
  CLONE: `${permissionsSvsBase}/msg/v2/clone_message`,
};

export const newMessageEndpoints = {
  MESSAGES: `${permissionsSvsBase}/msg/get_message_templates`,
  MESSAGES_CATEGORY: `${permissionsSvsBase}/msg/get_message_templates`,
  CREATE: `${permissionsSvsBase}/msg/create_message_template`,
  UPDATE: `${permissionsSvsBase}/msg/update_message_template`,
  DELETE: `${permissionsSvsBase}/msg/delete_message_template`,
  CLONE: `${permissionsSvsBase}/msg/v2/clone_message`,
};

export const subcategoryMap = {
  notice: 1,
  "privacy-manager": 2,
  privacyManager: 2,
  sar: 3,
  dsarRetrieval: 4,
  "notice-tcf-v2": 5,
  native: 6,
  'privacy-manager-ott': 7,
  notice_non_iab: 8,
  privacy_manager_non_iab: 9,
  ios: 10,
  ccpa_ott: 11,
  ccpa_pm: 12,
  custom: 13,
  "native-ott": 14,
  "native-ott-ccpa": 14,
  us_pm: 2,
  usnat_notice: 1,
  preferences: 1,
};

export const categoryMap = {
  gdpr: 1,
  ccpa: 2,
  adblock: 3,
  ios14: 4,
  custom: 5,
  usnat: 6,
  preferences: 7,
};

export const complianceList = List([
  Map({
    id: 1,
    status: false,
    type: 'manual',
    title: 'Storage and access of information',
    description: 'Does the 1st layer of the UI provide information about the storage and access of information from the user’s device by third-party vendors?'
  }),
  Map({
    id: 2,
    status: false,
    type: 'manual',
    title: 'Processing of personal data',
    description: 'Does the 1st layer of the UI provide information about the processing of personal data by third party Vendors?'
  }),
  Map({
    id: 3,
    status: false,
    type: 'manual',
    title: 'Example of personal data',
    description: 'Does the 1st layer of the UI provide an example of personal data processed?'
  }),
  Map({
    id: 4,
    status: false,
    type: 'manual',
    title: 'Vendor Count',
    description: 'Does the 1st layer of the UI show the number of the third party Vendors?'
  }),
  // Map({
  //   id: 4,
  //   status: false,
  //   type: 'manual',
  //   title: 'Information about consenting',
  //   description: 'Is there information about the consequences of consenting or not in the 1st layer of the UI?'
  // }),
  Map({
    id: 5,
    status: false,
    type: 'manual',
    title: 'Scope of the consent choice',
    description: 'Does the 1st layer of the UI provide information about the scope of the consent choice, i.e. service-specific consent or group-specific consent?'
  }),
  Map({
    id: 6,
    status: false,
    type: 'manual',
    title: 'Withdraw their consent at any time',
    description: 'Does the 1st layer of the UI inform the user that they can withdraw their consent at any time and how to do so?'
  }),
  Map({
    id: 7,
    status: false,
    type: 'manual',
    title: 'UI',
    description: 'Is the UI prominently displayed, covering most of the website content?'
  }),
  // Map({
  //   id: 7,
  //   status: false,
  //   type: 'manual',
  //   title: 'First Layer text must be fully displayed before Calls to Action are active',
  //   description: 'The entirety of the first layer message must be displayed before the Calls to Action (Accept All, More Options) are shown and active.'
  // }),
  Map({
    id: 8,
    status: false,
    type: 'manual',
    title: 'Direct Link to Vendors',
    description: 'Is there a direct link to the list of third parties in the 1st layer of the UI?'
  }),
  Map({
    id: 9,
    status: false,
    type: 'manual',
    title: 'Accept and manage options present',
    description: 'Are there calls to action in the 1st layer for users to express consent (e.g. `Accept`) and customize their choices (e.g. `Manage options`)?'
  }),
  Map({
    id: 10,
    status: false,
    type: 'manual',
    title: 'Equal weight of calls to action',
    description: 'Do the two primary calls to action on the 1st layer have matching text treatment and, for each, a minimum contrast ratio of 5:1?'
  }),
  Map({
    id: 11,
    status: false,
    type: 'manual',
    title: 'Purposes and/or Stacks and Special Features',
    description: 'Does the 1st layer of the UI provide information about the Purposes and/or Stacks and Special Features used by third parties? (Either the Vendor List component is used (recommended) or text exactly reflects the Purposes and/or Stacks and Special Features used.)'
  })
])

export const privacyManagerComplianceList = List([
  Map({
    id: 1,
    status: false,
    type: 'manual',
    title: 'Vendors processing personal data on the basis of Legitimate Interest',
    description: 'If you allow Legitimate Interest as a legal basis for processing,  does the the UI provide information about third party Vendors processing personal data on this basis?  This information can be in the First Layer message, or in the Privacy Manager instructional text.'
  }),
  Map({
    id: 2,
    status: false,
    type: 'manual',
    title: 'Right to object to Legitimate Interest Processing',
    description: 'If Legitimate Interest is used as a legal basis for processing data, does the UI advise the user of their right to object to their personal data being processed on this? This can be text in the Privacy Manager instructions or in the First Layer message.'
  }),
])

export const GeoGate = Record({
  continue: true,
  geo_list: List([]),
})


export const privacyCheckCategoryMap = {
  "privacy-manager": privacyManagerComplianceList,
  "privacy-manager-ott": complianceList,
  "notice-tcf-v2": complianceList
}

export const ottComplianceList = List([
  Map({
    id: 1,
    status: false,
    type: 'manual',
    title: 'Vendors processing personal data on the basis of Legitimate Interest',
    description: 'If you allow Legitimate Interest as a legal basis for processing,  does the the UI provide information about third party Vendors processing personal data on this basis?  This information can be in the First Layer message, or in the Privacy Manager instructional text.'
  }),
  Map({
    id: 2,
    status: false,
    type: 'manual',
    title: 'Right to object to Legitimate Interest Processing',
    description: 'If Legitimate Interest is used as a legal basis for processing data, does the UI advise the user of their right to object to their personal data being processed on this? This can be text in the Privacy Manager instructions or in the First Layer message.'
  }),
  Map({
    id: 3,
    status: false,
    type: 'manual',
    title: 'Storage and access of information',
    description: 'Does the 1st layer of the UI provide information about the storage and access of information from the user’s device by the publisher and third-party vendors?'
  }),
  Map({
    id: 4,
    status: false,
    type: 'manual',
    title: 'Processing of personal data',
    description: 'Does the 1st layer of the UI provide information about the processing of personal data by the publisher and third party Vendors?'
  }),
  Map({
    id: 5,
    status: false,
    type: 'manual',
    title: 'Example of personal data',
    description: 'Does the 1st layer of the UI provide an example of personal data processed?'
  }),
  Map({
    id: 6,
    status: false,
    type: 'manual',
    title: 'Information about consenting',
    description: 'Is there information about the consequences of consenting or not in the 1st layer of the UI?'
  }),
  Map({
    id: 7,
    status: false,
    type: 'manual',
    title: 'Scope of the consent choice',
    description: 'Does the 1st layer of the UI provide information about the scope of the consent choice, i.e. global consent, service-specific consent, or group-specific consent?'
  }),
  Map({
    id: 8,
    status: false,
    type: 'manual',
    title: 'Withdraw their consent at any time',
    description: 'Does the 1st layer of the UI inform the user that they can withdraw their consent at any time and how to do so?'
  }),
  Map({
    id: 9,
    status: false,
    type: 'manual',
    title: 'First Layer text must be fully displayed before Calls to Action are active',
    description: 'The entirety of the first layer message must be displayed before the Calls to Action (Accept All, More Options) are shown and active.'
  }),
  Map({
    id: 10,
    status: false,
    type: 'manual',
    title: 'Direct Link to Vendors',
    description: 'Is there a direct link to the list of third parties in the 1st layer of the UI?'
  }),
  Map({
    id: 11,
    status: false,
    type: 'manual',
    title: 'Accept and manage options present',
    description: 'Are there calls to action in the 1st layer for users to express consent (e.g. `Accept`) and customize their choices (e.g. `Manage options`)?'
  }),
  Map({
    id: 12,
    status: false,
    type: 'manual',
    title: 'Equal weight of calls to action',
    description: 'Do the two primary calls to action on the 1st layer have matching text treatment and, for each, a minimum contrast ratio of 5:1?'
  }),
  Map({
    id: 13,
    status: false,
    type: 'manual',
    title: 'Does the 1st layer of the UI provide information about the Purposes and/or Stacks and Special Features used by third parties?',
    description: 'Either the Vendor List component is used (recommended) or text exactly reflects the Purposes and/or Stacks andSpecial Features used.'
  })
])

export const sampleMessages = List([
  Map({
    id: 1,
    title: 'Sample 1',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque est non augue dictum sodales.Nulla tempus ullamcorper sem, ac euismod erat semper ut.Integer vehicula sem ac nulla scelerisque suscipit. Quisque sagittis sem quis nulla porta suscipit at non urna. '
  }),
  Map({
    id: 2,
    title: 'Sample 2',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque est non augue dictum sodales.Nulla tempus ullamcorper sem, ac euismod erat semper ut.Integer vehicula sem ac nulla scelerisque suscipit. Quisque sagittis sem qis nulla porta suscipit at non urna.'
  }),
])

export const TRY_CATCH_STR = {
  TRY: 'try{',
  CATCH: '}catch(e){console.err("action error: ",err)}',
};

export const canAddSet = new Set([
  'Notice',
  'PrivacyManager',
  'PrivacyManagerCCPA',
  'PrivacyManagerUS',
  'IOS',
  'Custom',
  'PrivacyManagerOTT',
  'PrivacyManagerOTTCategoriesView',
  'PrivacyManagerOTTVendorsView',
  'PrivacyManagerOTTCardDetailsView',
  'PrivacyManagerOTTPrivacyPolicyView',
  'PrivacyManagerOTTCustomView',
  'PrivacyManagerTCFv2',
  'PMTCFv2Inline',
  'Sar',
  'Column',
  'Row',
  'SuccessView',
  'ErrorView',
  'DsarRetrieval',
  'OneTimeCodeView',
  'CodeSuccessView',
  'RequestPendingView',
  'CCPAPrivacyManagerOTT',
  'CCPAPrivacyManagerOTT',
  'CCPAPrivacyManagerOTTVendorsView',
  'CCPAPrivacyManagerOTTPreferencesView',
  'CCPAPrivacyManagerOTTPrivacyPolicyView',
  'CCPAPrivacyManagerOTTCardDetailsView',
  'CustomPmView',
  'Preferences'
]);

export const PRIVACY_LENS_RULES_IMAGE_EXIST = ['r1', 'r13', 'r7', 'r25', 'r37', 'r5', 'r4', 'r6', 'r17', 'r18'];

export const CAMPAIGN_TYPES = [
  { label: 'GDPR Compliance', value: 1 },
  { label: 'Ad-block Messaging', value: 3 },
  { label: 'U.S. Privacy Compliance (legacy)', value: 2 },
  { label: 'U.S. MultiState Privacy Compliance', value: 6 },
  { label: 'Preferences', value: 7 },
  { label: 'iOS Tracking Authorization', value: 4 },
  { label: 'Custom Messaging', value: 5 },
  { label: 'No Type', value: 0 }
];

export const CAMPAIGNS_MAP = CAMPAIGN_TYPES.reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {});

export const CAMPAIGN_CODES = CAMPAIGN_TYPES.map(t => t.value);

export const regionsMap = {
  US: 'US',
  // US_NY: 'US New York',
  'US-CA': 'US California',
  // us_co: 'US Colorado',
  GB: 'UK',
  FR: 'France',
  DE: 'Germany',
  IT: 'Italy',
  ES: 'Spain',
  // dk: 'Denmark',
};

export const GCM_ACTION_TYPE = 'google_consent_mode';
export const GCM_CATEGORIES = ['analytics_storage', 'ad_storage', 'ad_user_data', 'ad_personalization', 'security_storage', 'personalization_storage', 'functionality_storage'] 

const vcStringMaker = (val) => `$$!VC-${val}!$$`

export const vendorCountType = (value) => {
  switch (value) {
    case 0:
      return vcStringMaker('ALL')
    case 1:
      return vcStringMaker('IAB')
    default:
      throw 'invalid value for vc count type'
  }
}