import React, { useState, useEffect } from 'react';
import { Upload, Button } from 'antd'
import { InfoCircleOutlined, DownloadOutlined, UploadOutlined, CheckCircleOutlined, ExclamationCircleOutlined, InfoOutlined } from "@ant-design/icons"
import { SelectDropdown, Select, Modal, Tooltip } from '../../../../../../../styleguide';
import TranslationModal from "./TranslationModal";
import { COUNTRY_LANGUAGES } from '../../../../../../../constants';
import CollapsiblePanel from '../../../../../../common/CollapsiblePanel';
import { hasDuplicateNames } from "../../../../builderUtils";
const { Dragger } = Upload;

const { Option } = Select;


const Language = ({
  settingsTitle,
  defaultLanguage,
  updateDefaultLanguage,
  supportedLanguages,
  updateSupportedLanguages,
  updateComponentText,
  translationComponent,
  components,
  updateBulkSetting,
  previewLanguage,
  updatePreviewLanguage,
  highlightComponent,
  isGlobalSettings
}) => {

  const [showModal, setShowModal] = useState(false)
  const [selectedLang, setSelectedLang] = useState('')
  const [data, setUpdatedData] = useState([])
  const [showTranslationModal, setShowTranslationModal] = useState(false)
  const [translationsComparison, setTranslationComparison] = useState({})

  const COMPONENT_TYPES_TO_TRANSLATE = ["Text", "Button", "PMButton", "IOSButton", "PreferencesButton"];

  const getTextAndButtonComponents = (data, parentPath = '', indexKey = 0, indexPath = [], supportedLanguages) => {
    const components = [];

    data.forEach((component, index) => {
      const currentPath = `${parentPath}${index}`;
      const currentIndexPath = [...indexPath, index];

      if (COMPONENT_TYPES_TO_TRANSLATE.includes(component.type)) {
        const textValues = {};

        // Extract text for default language
        if (component.settings.text && defaultLanguage) {
          textValues[defaultLanguage] = component.settings.text;
        } else {
          textValues[defaultLanguage] = component.name
        }

        // Extract text for supported languages
        if (component.settings.languages) {
          supportedLanguages.forEach(lang => {
            if (component.settings.languages[lang] && component.settings.languages[lang].text) {
              textValues[lang] = component.settings.languages[lang].text;
            } else {
              textValues[lang] = "";
            }
          });
        } else {
          supportedLanguages.filter(lang => lang !== defaultLanguage).forEach(lang => {
            textValues[lang] = "";
          });
        }

        components.push({
          type: component.type,
          key: `${component.type}-${indexKey}`,
          indexPath: currentIndexPath,
          name: component.name,
          value: textValues,
        });
        indexKey++;
      }

      if (component.children && component.children.length > 0) {
        const childComponents = getTextAndButtonComponents(
          component.children,
          `${currentPath}.children.`,
          indexKey,
          currentIndexPath,
          supportedLanguages,
        );
        components.push(...childComponents);
        indexKey += childComponents.length;
      }
    });

    return components;
  };

  const parseCsvRow = (row) => {
    const regex = /("(?:(?:"")*[^"]*)*"|[^,]+)(?:,|$)/g;
    const values = [];
    let match;

    while ((match = regex.exec(row))) {
      values.push(match[1].replace(/""/g, '"'));
    }

    return values;
  };

  const getInnerTextFromHTML = (htmlString) => {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlString, 'text/html');
    const innerText = parsedHTML.documentElement.textContent;
    return innerText.trim();
  }

  const hasDuplicateComponentNames = (arr) => {
    const componentNames = new Set();
    let count = 0
    for (const item of arr) {
      if (componentNames.has(item.Component)) {
        count = count + Object.keys(item).length - 1
      }
      componentNames.add(item.Component);
    }

    return count; // No duplicate component names found
  }

  // function written to extract count of translations replaced , added and removed 
  const compareTranslations = (obj1, obj2) => {
    let translationsReplaced = 0;
    let translationsAdded = 0;
    let translationsMissing = 0;

    let hasDuplicateCount = hasDuplicateComponentNames(obj1)
    let discardedCount = 0;
    let discardedKeys = []
   
    obj1.map((item, index) => {
      const keys1 = Object.keys(item);
      if (obj2[index]) {
        const keys2 = Object.keys(obj2[index]);
        const differingKey = keys1.filter((key) => !keys2.includes(key));
        differingKey.map(key => {
          discardedKeys.push(key)
        })
      }
      if (!obj2 || !obj2[index]) {
        const values = Object.values(item).filter(v => v !== "");
        discardedCount = discardedCount + values?.length -1
      }
    });
   
    const discardedLangKeys = [...new Set(discardedKeys)]

    obj1.map(o => {
      discardedLangKeys.map(key => {
        if (o[key]) {
          discardedCount = discardedCount + 1
        }
      })
    })

    const compareObjects = (obj1, obj2) => {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      for (const key of keys1) {
        let val1 = obj1[key];
        let val2 = obj2[key];

        const areBothObjects = typeof val1 === 'object' && typeof val2 === 'object';
        const areBothArrays = Array.isArray(val1) && Array.isArray(val2);

        if (areBothObjects) {
          compareObjects(val1, val2);
        } else if (areBothArrays) {
          if (val1.length === val2.length) {
            for (let i = 0; i < val1.length; i++) {
              compareObjects(val1[i], val2[i]);
            }
          } else {
            translationsAdded += Math.abs(val1.length - val2.length);
          }
        } else {
          // Get the inner text content
          if (typeof val1 === 'string' && typeof val2 === 'string') {
            val1 = (val1 && (val1 !== "")) ? getInnerTextFromHTML(val1) : "";
            val2 = (val2 && (val2 !== "")) ? getInnerTextFromHTML(val2) : "";
            if (val1==="") {
              translationsMissing++;
            } else if (val1 !== "" && val2 === "") {
              translationsAdded++;
            } else if (val2 && val1 !== val2) {
              translationsReplaced++;
            }
          }
        }
      }
    };

    compareObjects(obj1, obj2);

    return { "Translations Replaced": translationsReplaced, "Translations Added": translationsAdded, "Translations Missing": translationsMissing, "Translations Discarded": hasDuplicateCount + discardedCount,"LanguageDiscarded":discardedLangKeys };
  };


  const beforeUpload = async (file) => {
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = e => {
      let fileContent = e.target.result;
      const parsedData = [];

      const rows = fileContent.split(/\r\n|\r|\n/).filter(r => r !== "");
      const headers = rows[0].split(',').filter(r => r !== "");

      for (let i = 1; i < rows.length; i++) {
        const rowData = parseCsvRow(rows[i]).filter(r => r !== "");

        const rowObj = {};
        for (let j = 0; j < headers.length; j++) {
          const key = headers[j]?.replace(/"/g, ''); // Remove surrounding quotes from the header key
          const value = rowData[j]?.replace(/"/g, ''); // Remove surrounding quotes from the row value
          if(key){
            rowObj[key] = value || ""
          }
        }

        parsedData.push(rowObj);
      }

      const formattedComponents = data?.map(component => {
        const formattedComponent = {
          Component: component.name,
        };

        Object.keys(component.value).forEach(lang => {
          const langKey = COUNTRY_LANGUAGES.toJS().find(l => l.code === lang)?.language;
          if (langKey) {
            formattedComponent[langKey] = component.value[lang];
          }
        });

        return formattedComponent;
      });
      const translationsData = compareTranslations(parsedData, formattedComponents)
      data.map(d => {
        const tset = parsedData.find(t => t.Component === d.name)
        if (tset) {
          const invalidlang = translationsData["LanguageDiscarded"];
          const langKeys = Object.keys(tset)?.filter(lang => !invalidlang?.includes(lang))
          langKeys.shift()
          const value = {}
          langKeys.map(language => {
            const code = COUNTRY_LANGUAGES.toJS().find(lang => lang.language === language)?.code
            if (code && tset[language]!="") {
              value[code] = { text: tset[language] }
            }
          })

          updateBulkSetting('languages', value, undefined, undefined, d?.indexPath)
        }
      })
      
      setTranslationComparison(translationsData)
      setShowTranslationModal(true)
    };
    // Prevent upload
    return false;
  };

  const uploadParams = {
    name: 'file',
    accept: '.csv',
    maxCount: 1,
    openFileDialogOnClick: true,
    parserOptions: { header: false },
    beforeUpload: beforeUpload
  };

  const updateComponentName = (data) => {
    data.map(d => {
      if (d?.name && d !== '') {
        updateBulkSetting('componentName', d?.name, undefined, undefined, d?.indexPath)
      }
    })
    setUpdatedData(data)
  }

  useEffect(() => {
    const supportedLang = typeof supportedLanguages === 'string' ? [supportedLanguages] : supportedLanguages
    // Usage
    const textComponentsWithTranslations = components && getTextAndButtonComponents(components, '', 0, [], supportedLang);
    const selectedComponents = textComponentsWithTranslations && translationComponent?.length > 0 ? textComponentsWithTranslations.filter(c => translationComponent.includes(c.key)) : []
    setUpdatedData(selectedComponents)
  }, [supportedLanguages, translationComponent,components])

  const selectComponent = (value) => {
    const textComponentsWithTranslations = getTextAndButtonComponents(components, '', 0, [], supportedLang);
    const selectedComponents = translationComponent.length > 0 ? textComponentsWithTranslations.filter(c => value.includes(c.key)) : []
    setUpdatedData(selectedComponents)
    updateComponentText(selectedComponents, value)
  }

  const highlight = (e, value) => {
    e.stopPropagation()
    highlightComponent(value?.path)
  }

  const supportedLang = typeof supportedLanguages === 'string' ? [supportedLanguages] : supportedLanguages
  const textComponentsWithTranslations = components && getTextAndButtonComponents(components, '', 0, [], supportedLang);
  const selectedComponents = textComponentsWithTranslations && translationComponent?.length > 0 ? textComponentsWithTranslations.filter(c => translationComponent.includes(c.key)) : []
  const hasDuplicates = selectedComponents.length > 0 ? hasDuplicateNames(selectedComponents) : false;
  const supportedLanguageOptions = COUNTRY_LANGUAGES.filter(l => supportedLang.includes(l.code));
  const tooltipContent = "The message content will default to this language if the end user's browser default language is not supported and a language translation is not provided."
  const duplicateContentTooltip = "Resolve the duplicate names of selected components by clicking on Download CSV template to be able to upload the translations." 

  return (
    <>
      <div className="four-options">
        <h4>{settingsTitle} <Tooltip title={tooltipContent}>
          <InfoCircleOutlined />
        </Tooltip></h4>
        <div className="option-inputs">
          <Select
            style={{ width: '100%' }}
            value={defaultLanguage}
            onChange={updateDefaultLanguage}
          >
            {COUNTRY_LANGUAGES.map(({ code, language }) => <Option key={code}>{language}</Option>)}
          </Select>
        </div>
        <h4>{"Supporting Language"}</h4>
        <div className="option-inputs">
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={supportedLanguages}
            onChange={updateSupportedLanguages}
          >
            {COUNTRY_LANGUAGES.map(({ code, language }) => <Option key={code} value={code}>{language}</Option>)}
          </Select>
        </div>
        {!isGlobalSettings ? (
          <>
            {supportedLang.length <= 1 && (<span><InfoCircleOutlined /> Select more languages to support bulk translation</span>)}
            {supportedLang.length > 1 && (
              <CollapsiblePanel title="Bulk Upload Translation">
                <div className="bulk-option">
                  {supportedLang.length > 1 && (
                    <>
                      <span className="dropdown-title">
                        {"Select Component(s) for Translation"}
                      </span>
                      <SelectDropdown
                        style={{ width: "100%" }}
                        className={"opt-in-select"}
                        dropdownClassName={"opt-in-dropdown"}
                        optionLabelProp="name"
                        showSearch={false}
                        showArrow={true}
                        highlight={highlight}
                        tagRender={(value, option) => (
                          <p className="selected-purpose-tag">{}</p>
                        )}
                        selectAll={true}
                        multiple
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        directUpdate
                        onChange={(val) => selectComponent(val)}
                        value={translationComponent}
                        options={textComponentsWithTranslations.map(
                          ({ key, name, value, indexPath }) => {
                            return {
                              key: key,
                              name: name,
                              value: key,
                              label: `${name}-${value[defaultLanguage]}`,
                              path: indexPath,
                            };
                          }
                        )}
                      ></SelectDropdown>
                    </>
                  )}
                  {selectedComponents.length > 0 && (
                    <div className="csv-selection-upload">
                      <div className="bullet-container">
                        <b>1.</b>
                        <div
                          style={{ marginLeft: "2px" }}
                          onClick={() => setShowModal(true)}
                        >
                          <span className="download-csv-text">
                            {" "}
                            <DownloadOutlined style={{ marginRight: "2px" }} />
                            Download CSV file
                          </span>{" "}
                          and add translations
                        </div>
                      </div>
                      <div className="bullet-container">
                        <b>2.</b>
                        <h4>{"After you have added all the translations"}</h4>
                      </div>
                      <div className="upload-container">
                        <Dragger {...uploadParams}>
                          <Button type="secondary" disabled={hasDuplicates}>
                            <UploadOutlined /> Upload translation file
                          </Button>
                        </Dragger>
                        {hasDuplicates && (
                          <Tooltip title={duplicateContentTooltip}>
                            <InfoCircleOutlined
                              style={{ color: "orange", marginLeft: "2px" }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </CollapsiblePanel>
            )}
          </>
        ) : null}
        <h4>Preview Message</h4>
        <div className="option-inputs">
          <Select
            style={{ width: "100%" }}
            value={previewLanguage}
            onChange={updatePreviewLanguage}
          >
            {supportedLanguageOptions.map(({ code, language }) => (
              <Option key={code}>{language}</Option>
            ))}
          </Select>
        </div>
      </div>
      <TranslationModal
        showModal={showModal}
        setShowModal={setShowModal}
        supportedLanguages={supportedLanguages}
        defaultLanguage={defaultLanguage}
        components={components}
        textComponentsWithTranslations={data}
        updateComponentName={updateComponentName}
      />
      <Modal
        wrapClassName={'translations-status'}
        title={"File Upload Status"}
        visible={showTranslationModal}
        open={showTranslationModal}
        onOk={() => setShowTranslationModal(false)}
        okText={"Close"}
        onCancel={() => setShowTranslationModal(false)}>
        <div style={{ marginBottom: "10px" }}><b>LanguageTranslation.csv</b> <CheckCircleOutlined style={{ color: 'green', marginRight: '2px' }} /> was uploaded successfully.</div>
        <div className="translation-container">
          <div className="translation-row">
          {Object.keys(translationsComparison).map((key) => (
            <>
                {(key === 'Translations Added') && (
                <div className="translation-count">
                  <div><CheckCircleOutlined className="info-icon" /> <span className="title">{key} : {translationsComparison[key]}</span></div>
                  <div className="translation-text">These translations were added directly with no issues.</div>
                </div>
                )}
                {(key === 'Translations Missing') && (
                  <div className="translation-count">
                  <div><InfoCircleOutlined className="warning-icon" /> <span className="title">{key} : {translationsComparison[key]}</span></div>
                      <div className="translation-text">Translation data for these entries were missing.</div>
                  </div>
                )}
             </>
          ))}
          </div>
          <div className="translation-row">
            {Object.keys(translationsComparison).map((key) => (
              <>
               {(key === 'Translations Replaced') && (
                    <div className="translation-count">
                      <div><CheckCircleOutlined className="info-icon" /> <span className="title">{key} : {translationsComparison[key]}</span></div>
                    <div className="translation-text">These translations were added directly with no issues.</div>
                    </div>
                )}
                {(key === 'Translations Discarded') && (
                  <div className="translation-count">
                    {/* <div><InfoCircleOutlined className="error-icon" /> <span className="title">{key} : {translationsComparison[key]}</span></div>
                    <div className="translation-text">These translations were discorded due to - Incorrect component , incorrect Language or duplicate entries.</div> */}
                </div>)}
              </>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};


export default Language;