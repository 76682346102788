import React, { useEffect, useState } from "react";
import { Input, Select } from "../../../../../../styleguide";
import PropertySelection from "../../../../../common/PropertySelection";
import { CONSENT_SCOPE_TOOLTIP, renderInfoTip } from "../../../helper";
import { findNthTermInAnEntityList } from "../../../../../utils";

const NameAndProperty = (props) => {
  
  const [ regulationName, setRegulationName ] = useState(props.usPrivacyRegulation.name ?? "");
  const [ selectedPropertiesKeys, setSelectedPropertiesKeys ] = useState(props.usPrivacyRegulation.siteIds);
  const [ consentScope, setConsentScope ] = useState(props.usPrivacyRegulation.consentScope);
  const [ regulationNameError, setRegulationNamenError ] = useState(null);
  const [ siteIdsError, setSiteIdsError ] = useState(false);

  useEffect(()=>{
    props.usPrivacyRegulation.name = regulationName;
    props.usPrivacyRegulation.siteIds =  selectedPropertiesKeys;
    props.usPrivacyRegulation.consentScope = consentScope;
    props.resetValidationError();
  },[regulationName, selectedPropertiesKeys, consentScope])


  useEffect(() => {
    if (props.showError) {
      const isRegulationNameEmpty = regulationName.trim().length === 0;
      const isRegulationNameExists = props.regulationsList?.some(al => al.name === regulationName.trim());
      setRegulationNamenError(
        isRegulationNameEmpty
          ? "Please Enter Regulation Name"
          : isRegulationNameExists
          ? <>Regulation with name <b>{regulationName}</b> already exists</>
          : null
      );
  
      setSiteIdsError(
        selectedPropertiesKeys.length === 0
          ? "Select at least one property"
          : false
      );
    } else {
      setRegulationNamenError(null);
      setSiteIdsError(false);
    }
  }, [props.showError, regulationName, selectedPropertiesKeys]);

  useEffect(() => {
    if(!props.isEditFlow && props.regulationsList.size && !Boolean(props.usPrivacyRegulation.name)){
      setRegulationName(findNthTermInAnEntityList(props.regulationsList.map(reg => reg.name), "New Regulation"))
    }
  },[props.regulationsList]);

  return (
    <>
      {!props.isEditFlow ? <div className="step-breif">This wizard will guide you through settings that are required to set up a privacy framework for users on your properties that fall under <b>US state privacy regulations.</b></div> : null}
      <div className="data-field">
        <b>Regulation Name</b>
        <Input
          placeholder="Enter Name"
          value={regulationName}
          onChange={({target: {value}}) => {
            setRegulationName(value);
          }}
          error={regulationNameError}
          maxLength={60}
          showCount={true}
        />
      </div>
      <div className="step-header">
        <h4>Property Selection</h4>
      </div>
      <div className="step-breif">Select the properties or property groups you wish these regulation settings to apply to.</div>
      <div className="step-error">{siteIdsError}</div>
      <div className="step-section">
        <div className={`section-content ${siteIdsError ? 'validation-error' : null}`}>
          <PropertySelection
            selectedPropertiesKeys={selectedPropertiesKeys}
            setSelectedPropertiesKeys={setSelectedPropertiesKeys}
            sites={props.sites}
            siteGroups={props.siteGroups}
          />
        </div>
      </div>
      <div className="step-header">
        <h4>Consent Scope</h4>
        {renderInfoTip(CONSENT_SCOPE_TOOLTIP)}
      </div>
      <div className="step-breif">
        <Select
          value={consentScope}
          onChange={(value) => {setConsentScope(value)}}
          size="large"
          options={[{label: 'Single site', value: 'SINGLE'}, {label: 'Shared site', value: 'GROUP'}]}
        />
      </div>
    </>
  )
}

export default NameAndProperty;
