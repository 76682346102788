import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Menu, Tag, Typography } from 'antd';
import { fromJS } from 'immutable';
import { SelectDropdown, Button, Tabs, Alert } from "../../../../../styleguide";
import { PlusOutlined, MinusOutlined, UploadOutlined } from "@ant-design/icons"
import { findObjectByKeyRef, getSelectedTranslationLanguages, getLanguagesWithEmptyTranslation, getEmptyTranslationLanguages } from "./helper.js";
import { COUNTRY_LANGUAGES } from '../../../../../constants';
import { useActions, usePrevious } from "../../../../../hooks";
import TranslationComponent from './TranslationComponent';
import BulkUploadModal from "./BulkUploadModal";
import { MESSAGE_SENTIMENT_TOOLTIP, renderInfoTip } from "../../../us_privacy_regulation/helper";
import { sample } from 'lodash';
const { TabPane } = Tabs;

const { Text } = Typography;


const LanguageAndTranslations = (props) => {
  const { regulation, updateConfiguration, parentTab } = props;
  const [configurationCategories, setConfigurationCategories] = useState(props.categories.toJS())
  const [selectedLang, setSelectedLang] = useState(["default"])
  const [selectedPurpose, setSelectedPurpose] = useState(props.categories?.toJS()?.filter(item => !item.dateDeleted)[0])
  const [showBulkUpload, setShowBulkUpload] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [selectedSubMenuKey, setSelectedSubMenuKey] = useState([]);
  const [tableData, setTableData] = useState([])
  const [bulkChoice, setBulkChoice] = useState(null)
  const MISSING_TRANSLATION = "Missing Translations"

  const previousParentTab = usePrevious(parentTab)

  let tabs = [
    { key: 1, title: "Title" },
    { key: 2, title: "Description" },
  ]
  const tabsFieldNamekey = { "1": "name", "2": "description", "3": "illustration" }
  const tabsFieldTranslationkey = { "1": "names", "2": "descriptions", "3": "illustrations" }

  let selectedTransLanguages = getSelectedTranslationLanguages(configurationCategories).map(l => l.toUpperCase())

  if (!selectedTransLanguages.includes("DEFAULT")) {
    selectedTransLanguages.unshift("DEFAULT")
  }

  let liveCategories = configurationCategories.filter(item => !item.dateDeleted)

  let emptyTranslationLanguages = getLanguagesWithEmptyTranslation(liveCategories);

  let allLanguages = COUNTRY_LANGUAGES.unshift({ language: 'Default', code: 'DEFAULT' })
  const dropdownLanguages = allLanguages.sort((a, b) => {
    // Move English language to the top
    if (a.language === "Default") {
      return -1;
    } else if (b.language === "Default") {
      return 1;
    } else {
      return 0;
    }
  });

  let languageOptions = dropdownLanguages.toJS().map((l, i) => ({
    label: l.language,
    value: l.code,
    disabled: l.code === 'DEFAULT' ? true : false
  }));
  //languageOptions.unshift({label: 'Default', value: 'default', disabled: true})
  const filteredCategoriesTitle = props.categories?.toJS()?.filter(item => !item.dateDeleted).filter(c => 
    c.name !== ''
  )

  const filteredCategoriesDescription = props.categories?.toJS()?.filter(item => !item.dateDeleted).filter(c => c.description !== '')
  const purposeOptionsTitle = filteredCategoriesTitle.map((purpose) => (
    {
      label: purpose?.name,
      value: purpose?.categoryId,
    }
  ))

  const purposeOptionsDescription = filteredCategoriesDescription.map((purpose) => (
    {
      label: purpose?.name,
      value: purpose?.categoryId,
    }
  ))


  const onChangeTab = (value) => {
    setActiveTab(value)
    let defaultSelectedKey = selectedPurpose?.categoryId || configurationCategories[0]?.categoryId
    handleMenuClick(defaultSelectedKey, value)
  }

  const getChoiceId = (selPurpose) => {
    let isPresent = liveCategories.find(cat => cat?.categoryId === selPurpose?.categoryId)
    return isPresent !== undefined ? selPurpose?.categoryId : liveCategories?.[0]?.categoryId
  }

  const selectedKey = liveCategories.length > 0 && getChoiceId(selectedPurpose)

  useEffect(() => {
    if (JSON.stringify(props.categories) !== JSON.stringify(configurationCategories) || (previousParentTab !== parentTab)) {
      const categories = props.categories?.toJS()
      setSelectedPurpose(categories?.[0])
      setActiveTab("1")
      updateConfigurationCategories(categories);
    }
  }, [props.categories, parentTab])

  useEffect(() => {
    const updatedConfiguration = regulation.set('categories', fromJS(configurationCategories));
    updateConfiguration(updatedConfiguration)
  }, [configurationCategories]);

  useEffect(() => { 
    if (selectedTransLanguages.length > 0 && liveCategories?.length > 0) {
      const selectedTransLanguagesUpper = selectedTransLanguages.map(l => l.toUpperCase());
      handleSelectedLanguage(selectedTransLanguagesUpper, activeTab);
      setSelectedLang(selectedTransLanguages)
    } else {
      handleSelectedLanguage(selectedLang, activeTab);
    }
    if (liveCategories?.length > 0) {
      handleMenuClick(selectedKey, activeTab)
      updateTableData(liveCategories, selectedTransLanguages, activeTab)
    }
  }, [configurationCategories, parentTab])

  const updateConfigurationCategories = (updatedCategories) => {
    setConfigurationCategories(updatedCategories);
    const updatedConfiguration = regulation.set('categories', fromJS(updatedCategories));
    updateConfiguration(updatedConfiguration);
  }

  const getMenuItems = (key) => {
    let purposes = []
    let categories = configurationCategories.filter(item => !item.dateDeleted);
    categories?.map((cat) => {
      let emptyLanguages = cat?.translations ? getEmptyTranslationLanguages(cat?.translations, key) : []
      let isDefaultTextEmpty = checkDefaultText(cat, key)
        purposes.push(
          <Menu.Item
            className={selectedSubMenuKey === cat.categoryId ? 'selected' : 'notselected'}
            key={cat.categoryId}
            disabled={isDefaultTextEmpty}
          >
            {cat?.name}<span style={{position: 'absolute', right: 0}}>{emptyLanguages.length > 0 && renderInfoTip(MISSING_TRANSLATION, "warning")}</span>
          </Menu.Item>
        )
      })
    return purposes
  }

  const filterTransLationObject = (translations, selectedLang) => {
    const filteredTranslation = Object.entries(translations)
      .filter(([key]) => selectedLang.includes(key.toUpperCase()))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
    return filteredTranslation
  }


  const updateCategoryTranslation = (category, languages, activeTab) => {
    const fieldTranslationkey = tabsFieldTranslationkey[activeTab];
    const fieldKey = tabsFieldNamekey[activeTab];

    // Initialize translations if not already present
    const translations = category?.translations?.[fieldTranslationkey] || {};

    // Process each language
    languages.forEach(lang => {
      const lowerLang = lang.toLowerCase();
      if (lowerLang === "default") {
        translations[lowerLang] = category[fieldKey] || "";
      } else {
        translations[lowerLang] = translations[lowerLang] || "";
      }
    });

    // Update category translations
    if (!category.translations) {
      category.translations = {};
    }
    category.translations[fieldTranslationkey] = filterTransLationObject(translations, languages);

    return category;
  };

  /** called when supported language is changed */
  const handleSelectedLanguage = (value, activeTab) => {
    setSelectedLang(value)
    tabs.map(t => {
      const activetab = t.key.toString();
      configurationCategories?.forEach(category => {
        const updatedCategory = updateCategoryTranslation(category, value, activetab);
        return updatedCategory
      });
    })
    updateConfigurationCategories(configurationCategories)
    updateTableData(configurationCategories, value, activeTab)
  }

  const getPurposesContentByTab = (activeTab, selectedPurpose, key) => {
    const fieldkey = tabsFieldTranslationkey[activeTab]
    return (selectedPurpose?.translations && selectedPurpose?.translations?.[fieldkey]) ? selectedPurpose?.translations[fieldkey] : { "default": selectedPurpose[tabsFieldNamekey[activeTab]] }
  }
  const handleMenuClick = (key, activeTab) => {
    setSelectedSubMenuKey(key);

    const selectedPurpose = findObjectByKeyRef(configurationCategories, 'categories', 'categoryId', key);
    console.log(selectedPurpose);

    const translations = getPurposesContentByTab(activeTab, selectedPurpose);
    const selectedLanguages = new Set(selectedLang.map(l => l.toLowerCase()));

    const dataSource = allLanguages.reduce((acc, lang, index) => {
      const langCode = lang.code.toLowerCase();
      if (selectedLanguages.has(langCode)) {
        const translation = translations?.[langCode] || '';
        acc.push({ key: index + 1, name: lang.language, translation });
      }
      return acc;
    }, []);

    setTableData(dataSource);
    setSelectedPurpose(selectedPurpose);
  }


  const handleTranslationChanges = (data, purpose) => {
    setTableData(data);
    const updatedPurpose = (purpose) ? { ...purpose } : { ...selectedPurpose };
    const fieldTranslationkey = tabsFieldTranslationkey[activeTab]

    let translations = updatedPurpose?.translations?.[fieldTranslationkey]

    if (!translations) {
      translations = {}
    }

    data.forEach((row) => {
      let code = allLanguages.find(l => l.language.toLowerCase() === row.name.toLowerCase())?.code.toLowerCase();
      if (code) {
        translations[code] = row.translation;
      }
    })

    if (!updatedPurpose?.translations) {
      updatedPurpose.translations = {};
    }
    updatedPurpose.translations[fieldTranslationkey] = translations;

    const updatedCategories = configurationCategories.map(cat => cat.categoryId === updatedPurpose.categoryId ? updatedPurpose : cat);
    updateConfigurationCategories(updatedCategories);
  }


  const handleTranslationChangesAfterUpload = (data, purpose, choice) => {
    setTableData(data);
    const activeTab = choice;
    setActiveTab("1")
    const updatedPurpose = (purpose) ? { ...purpose } : { ...selectedPurpose };
    const fieldTranslationkey = tabsFieldTranslationkey[activeTab]

    let translations = updatedPurpose?.translations?.[fieldTranslationkey]

    if (!translations) {
      translations = {}
    }
    data.forEach((row) => {
      let code = allLanguages.find(l => l.language.toLowerCase() === row.name.toLowerCase())?.code.toLowerCase();
      if (code) {
        translations[code] = row.translation;
      }
    })

    if (!updatedPurpose?.translations) {
      updatedPurpose.translations = {};
    }
    updatedPurpose.translations[fieldTranslationkey] = translations;

    const updatedCategories = configurationCategories.map(cat => cat.categoryId === updatedPurpose.categoryId ? updatedPurpose : cat);
    updateConfigurationCategories(updatedCategories)
  }

  const updateTableData = (configurationCategories, selectedLang, activeTab) => {
    if (configurationCategories?.length === 0) {
      setTableData([]);
      return;
    }

    const id = getChoiceId(selectedPurpose);
    const fieldTranslationkey = tabsFieldTranslationkey[activeTab];
    const selPurpose = findObjectByKeyRef(configurationCategories, 'categories', 'categoryId', id);

    let translations = selPurpose?.translations?.[fieldTranslationkey] || {};
    const selectedLanguages = new Set(selectedLang.map(l => l.toLowerCase()));

    const dataSource = dropdownLanguages.reduce((acc, language, index) => {
      const langCode = language.code.toLowerCase();
      if (selectedLanguages.has(langCode)) {
        const translation = translations[langCode] || '';
        if (language.language) {
          acc.push({ key: index + 1, name: language.language, translation });
        }
      }
      return acc;
    }, []);

    setTableData(dataSource);
  };


  const formatUploadedData = (data,sampleData) => {
    const obj2 = [];
    const sampleDataCategories = sampleData.map(item => item.Category.toLowerCase());
    const filteredData = data.filter(item => sampleDataCategories.includes(item.Category.toLowerCase()));
    filteredData.forEach((item,index) => {
      const translations = [];
      const privacyChoice = item?.["Category"] || null;

      Object.entries(item).forEach(([key, value]) => {
        if (key !== "Category") {
          translations.push({
            key: translations.length + 1,
            name: key,
            translation: value
          });
        }
      });

      if (privacyChoice) {
        obj2.push({
          [privacyChoice]: translations
        });
      }

    });

    return obj2;
  };


  const uploadTranslations = (fileData, sampleData, choice) => {
    if (sampleData.length > 0) {
      const uploadedData = formatUploadedData(fileData, sampleData);
      const activeChoice = choice === 1 ? "2" : "1";
      setBulkChoice(activeChoice)
      uploadedData.map((row, index) => {
        let purposeName = Object.keys(row)[0]
        let purpose = findObjectByKeyRef(configurationCategories, 'categories', 'name', purposeName)
        let defaultTranslation = (choice === 1) ? purpose?.name : purpose?.description; 
        if (defaultTranslation) {
          handleTranslationChangesAfterUpload(row[purposeName], purpose, choice?.toString())
        }
      })
    }
  }

  const openBulkEdit = () => {
    setShowBulkUpload(true); 
    if(props.onEditScreen) props.setHideParentModal(true)
  }

  const closeBulkEdit = () => {
    setShowBulkUpload(false);
    if (props.onEditScreen) props.setHideParentModal(false)
  }


  let selectedLangWithoutTrans = dropdownLanguages
  .filter(c => emptyTranslationLanguages.includes(c.code.toLowerCase()))
  .filter(l => selectedLang.includes(l.code) && l.code !== 'DEFAULT')
  .map(l => l.language)
  .toJS()
  

  const alertForEmptyLanguages = selectedLangWithoutTrans.length ? (
    <Alert
      className="top-margin"
      type="warning"
      showIcon
      message={<>We do not provide default translations for <b>{selectedLangWithoutTrans.join(', ')}</b>. Please make sure to add translations or else it will use default language only.</>}
    />
  ) : null;

  const checkDefaultText = (selectedPurpose, tab) => {
    let fieldkey = tabsFieldNamekey[tab]
    return selectedPurpose?.[fieldkey] === ""
  } 
  
  let categoriesWithoutDeleted = configurationCategories?.filter(item => !item.dateDeleted);
  return (
    <>
       {props.readOnly || props.onEditScreen ? null :(
        <div className="step-header">
          <Typography.Title level={4}>Language and Translation</Typography.Title>
        </div>
      )}
      <div className="step-breif">
        In this step, include translations for one or more languages. You have the option to adjust the default text for category titles and descriptions from the previous step (Add Categories).
      </div>
      {liveCategories.length > 0 && (<div className={`step-section`} >
        <div className={`supported-language`}>
          <div className="heading">View Languages</div>
          <> 
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                {/* <div className="sub-heading">Please select the languages your message will support.</div> */}
                <SelectDropdown
                  options={languageOptions}
                  onChange={(value) =>
                    handleSelectedLanguage(value, activeTab)
                  }
                  value={selectedLang}
                  selectAll
                  multiple
                  entityName="Languages"
                  showSearch
                  directUpdate
                  closeTags
                  disabled={props.readOnly}
                />
              </div>
              {!props.readOnly && (<Button onClick={openBulkEdit} className="btn-action" type="secondary"><UploadOutlined className="icon" />Bulk Upload</Button>)}
            </div>
          </>
        </div>
        <div style={{ display: 'flex' }}>
          {selectedLangWithoutTrans && alertForEmptyLanguages}
        </div>
        <Tabs
          genre='classic'
          className="language-translation-tabs"
          defaultActiveKey={1}
          activeKey={activeTab}
          onChange={onChangeTab}>
          {tabs.map((t => {
            const purposes = getMenuItems(t.key)
            const selectedKey = purposes[0]?.categoryId
            let emptyLanguages = getLanguagesWithEmptyTranslation(categoriesWithoutDeleted, t.key)
            let isDefaultTextEmpty = checkDefaultText(selectedPurpose,t.key) || false
            return (
              <TabPane
                tab={<span className="tab-title">{t.title}{(emptyLanguages.length > 0 || isDefaultTextEmpty) && renderInfoTip(MISSING_TRANSLATION,"warning")}</span>}
                key={t.key}
                disabled={isDefaultTextEmpty}
              >
                <div className="translation-container">
                  <TranslationComponent
                    purposes={purposes}
                    selectedKey={selectedKey}
                    selectedSubMenuKey={selectedSubMenuKey}
                    handleMenuClick={handleMenuClick}
                    activeTab={activeTab}
                    selectedLang={selectedLang}
                    selectedPurpose={selectedPurpose}
                    handleTranslationChanges={handleTranslationChanges}
                    data={tableData}
                    readOnly={props.readOnly}
                    menuTitle="Category"
                    onEditScreen={props.onEditScreen}
                  />
                </div>
              </TabPane>
            )
          }))}
        </Tabs>
      </div>)}
      {liveCategories.length === 0 && (
        <div className="empty-div">
          <h4>Start by adding your category first</h4>
          {props.showError ? <Text type="danger" strong>{"Please add at least one category"}</Text> : null}
        </div>
      )}
      {showBulkUpload && (
        <BulkUploadModal
          closeModal={closeBulkEdit}
          selectedLang={selectedLang}
          languageOptions={languageOptions}
          purposeOptions={purposeOptionsTitle}
          descriptionOptions={purposeOptionsDescription}
          regulationCategories={configurationCategories}
          uploadTranslations={uploadTranslations}
          activeTab={activeTab}
          onEditScreen={props.onEditScreen}
          allLanguages={allLanguages}
        />
      )}
    </>
  )
}

export default LanguageAndTranslations;