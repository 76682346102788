import React from 'react';
import { Modal, Button } from 'antd';

function DismissErrorModal({ 
  modalWrapperClass = '', 
  title, 
  error, 
  isModalVisible, 
  handleCancel, 
  renderOk, 
  okText, 
  cancelText, 
  handleOk,
  primaryAction,
  dontShowCross
}){

   const getPrimarybtnClass = (key) => {
     return primaryAction && primaryAction == key ? 'primary-action-class' : '';
   }

   const footer= [ 
     <Button
       key='cancel'
       className={ getPrimarybtnClass('cancel') }
       onClick={ (e) => handleCancel(e) }>
       {cancelText || 'Cancel'}
      </Button>,
     renderOk && (
       <Button
         key='submit'
         type='primary'
         className={ getPrimarybtnClass('submit') }
         onClick={ (e) => handleOk(e) }>
         {okText || 'Okay'}
       </Button>
     ),
   ];

   return <Modal 
      wrapClassName={ `${modalWrapperClass} dismiss-error ` } 
      title={ title } 
      visible={ isModalVisible } 
      footer={ footer }
      onCancel={handleCancel}
      maskClosable={false}
      closable={!dontShowCross}
      >
        { error }
      </Modal>;
}

export default DismissErrorModal;