import React, { useState, useEffect } from "react";
import { Modal, Tabs, Button, LinesEllipsis, Chip, Alert } from "../../../../../../styleguide";
import AddSystemVendors from "./AddSystemVendors";
import { getGlobalVendors } from "../../../../../../actions/us_privacy_regulation_actions";
import { connect } from "react-redux";
import { VendorWrapper } from "../../../../../../records/us_privacy_regulation_records";
import { Map, OrderedSet } from "immutable";
import ImportVendorsFromVL from "./ImportVendorsFromVL";
import { WarningOutlined } from "@ant-design/icons";
import AddScanResultVendors from "./AddScanResultVendors";
import { hasFeature } from "../../../../../utils";

const AddVendorsModal = (props) => {
  const {categories, vendorsWrappers} = props.usPrivacyRegulation;
  const existingVendorIds = vendorsWrappers.map((vw) => vw.vendorId);

  const [activeTabKey, setActiveTabKey] = useState("1");
  const [vendorsHashMap, setVendorsHashMap] = useState({});
  const [selectedVendorIds, setSelectedVendorIds] = useState([]);
  const [vendorsFromImportedVl, setVendorsFromImportedVl] = useState([]);
  const [importHooks, setImportHooks] = useState(false);
  const [overlappingVendorIds, setOverlappingVendorIds] = useState([]);
  const [overrideVendorsWarningMessage, setOverrideVendorsWarningMessage] = useState(null);
  const [overlappingVendorChipsCount, setOverlappingVendorChipsCount] = useState(10);

  useEffect(()=>{
    props.getGlobalVendors();
  },[])

  useEffect(()=>{
    setSelectedVendorIds([])
  },[activeTabKey])

  useEffect(()=>{
    if(overlappingVendorIds.length) setOverrideVendorsWarningMessage(populateWarningMessage(overlappingVendorChipsCount));
  },[overlappingVendorChipsCount]);

  const populateWarningMessage = (chipsCount) => {
    let vendorsNamesMap = {}
    if(vendorsWrappers.length){
      vendorsNamesMap =  vendorsWrappers.reduce((map, item)=>{
        map[item.vendorId] = item.name;
        return map;
      }, {});
    }
    let chipsArray = overlappingVendorIds.map((id) => (
      <Chip className="warning-tag">{vendorsNamesMap[id]}</Chip>
    ));
    let viewMoreButton = null;
    const length = chipsArray.length;
    if(length > 10) {
      viewMoreButton = chipsCount > 10 ?
      <Chip className="warning-tag viewmore" onClick={() => setOverlappingVendorChipsCount(10)}>-</Chip> :
      <Chip className="warning-tag viewmore" onClick={() =>setOverlappingVendorChipsCount(length)}>+{length - 10}</Chip>
    }
    const updatedChips = chipsArray.slice(0, chipsCount)

    const message = (
      <div className="confirmation-footer">
        <div className="icon-div"> <WarningOutlined /></div>
        <div>
          <div>
            Following vendors already exist in your vendor list
          </div>
          <div>
            {updatedChips}{viewMoreButton}
          </div>
          <div>
            {importHooks ? <b>Do you want to update the privacy choice mapping and hooks configuration (based on the imported vendor list configuration) for the overlapping vendors?</b> : <b>Do you want to update the privacy choice mapping for the overlapping vendors?</b>}
          </div>
          <div className='button-group'>
            <div>
              <Button onClick={() => handleAddVendors(false)} type="secondary"> Skip and continue </Button>
              <Button onClick={() => handleAddVendors(true)} type="primary">Update and continue</Button>
            </div>
          </div>
        </div>
      </div>
    )
    return message;
  }

  const onClickAddVendors = () => {
    if(overlappingVendorIds.length){
      const message = populateWarningMessage(overlappingVendorChipsCount)
      setOverrideVendorsWarningMessage(message)
    } else {
      handleAddVendors(false);
    }
  }


  const handleAddVendors = (override) => {
    let updatedCategories = _.cloneDeep(categories);
    let selectedVendorIdsCopy = _.cloneDeep(selectedVendorIds);


    if(!override){
      selectedVendorIdsCopy = selectedVendorIdsCopy.filter(id => !overlappingVendorIds.includes(id));
    }
    updatedCategories = updatedCategories.map(cat => {
      //Object.entries(obj).filter(([key]) => keys.includes(key))
      let updatedCat = _.cloneDeep(cat);
      for (const [vendorId, categories] of Object.entries(vendorsHashMap).filter(([key]) => selectedVendorIdsCopy.includes(key))){
        if(categories.includes(updatedCat.id ?? updatedCat.privacyChoice) && selectedVendorIdsCopy.includes(vendorId)){
          if(!updatedCat.vendorCategorization[vendorId]){
            updatedCat.vendorCategorization[vendorId] = {
              type: updatedCat.defaultLegalBasis,
              vendorId: vendorId
            }
          }
        } else {
          delete updatedCat.vendorCategorization[vendorId]
        }
      }
      return updatedCat;
    })

    let updatedVendorsWrappers = vendorsWrappers;
    switch(activeTabKey){
      case "1":
        updatedVendorsWrappers = vendorsWrappers.concat(props.globalVendors?.toJS().filter( v => selectedVendorIdsCopy.includes(v.id) && !vendorsWrappers.some(vw => vw.vendorId === v.id)).map(vendor => new VendorWrapper({...vendor, vendorId: vendor.id, id: null})?.toJS()));
        break;
      case "2":
        updatedVendorsWrappers = vendorsWrappers.concat(props.scannedVendors?.toJS().filter( v => selectedVendorIdsCopy.includes(v.id) && !vendorsWrappers.some(vw => vw.vendorId === v.id)).map(vendor => new VendorWrapper({...vendor, vendorId: vendor.id, id: null})?.toJS()));
        break;
    }

    if(vendorsFromImportedVl && vendorsFromImportedVl.length) {
      if(override){
        updatedVendorsWrappers = updatedVendorsWrappers.filter(vw => !overlappingVendorIds.includes(vw.vendorId));
      }
      updatedVendorsWrappers = updatedVendorsWrappers.concat(vendorsFromImportedVl.filter(v => selectedVendorIdsCopy.includes(v.vendorId) && !updatedVendorsWrappers.some(vw => vw.vendorId === v.vendorId)).map(vendor => {
        if(importHooks){
          return new VendorWrapper(vendor)?.toJS()
        } else {
          return new VendorWrapper({name: vendor.name, vendorId: vendor.vendorId})?.toJS()
        }
      }))
    }

    props.updateVendorsWrappers(updatedVendorsWrappers); 
    props.updateCategories(updatedCategories);
    props.setShowAddVendorsModal(false);
    setOverrideVendorsWarningMessage(null)
  }

  const handleCancel = () => {
    props.setShowAddVendorsModal(false);
  }

  const overrideVendorsWarning = overrideVendorsWarningMessage && <Alert message={overrideVendorsWarningMessage} type="warning"/> ;

  const renderFooter = () => overrideVendorsWarningMessage ? overrideVendorsWarning : (
    [
      <Button key="back" onClick={handleCancel}>Cancel</Button>,
      <Button key="submit" type="primary" onClick={onClickAddVendors} disabled={selectedVendorIds.length == 0}>Add Selected Vendors</Button>,
    ]
  )

  useEffect(()=>{
    let overlappingVendorIds = []
    selectedVendorIds.forEach(id => {
      if(existingVendorIds.includes(id)) overlappingVendorIds.push(id)
    })
    setOverlappingVendorIds(overlappingVendorIds);
    setOverrideVendorsWarningMessage(null)
  },[selectedVendorIds])

  const vendorScanResultsTab = hasFeature(props.currentUser, 'vendor_scanning_v2') ? (
    <Tabs.TabPane tab="Vendor Scan Results" key="2">
      <AddScanResultVendors
        categories={categories}
        usPrivacyRegulation={props.usPrivacyRegulation}
        selectedVendorIds={selectedVendorIds}
        setSelectedVendorIds={setSelectedVendorIds}
        vendorsHashMap={vendorsHashMap}
        setVendorsHashMap={setVendorsHashMap}
        overlappingVendorIds={overlappingVendorIds}
        isOverlap={Boolean(overrideVendorsWarningMessage)}
      />
    </Tabs.TabPane>
  ) : null;

  return (
    <Modal
      open={props.showAddVendorsModal}
      title={"Add Vendors"}
      onOk={handleAddVendors}
      onCancel={handleCancel}
      closable={true}
      className="usnat-vendor-management-modal"
      destroyOnClose
      footer={renderFooter()}
      width={'900px'}
      style={{
        top: "5%"
      }}
    >
      <div>Select all the vendors that you want to add to your list. The vendors here have been mapped to all the selected privacy choices by default. You can update the selection by clicking on the dropdown.</div>
      <Tabs
        defaultActiveKey="1"
        onChange={(key) => setActiveTabKey(key)}
        activeKey={activeTabKey}
        className="spsg-normal"
      >
        <Tabs.TabPane tab="System Vendors" key="1">
          <AddSystemVendors
            categories={categories}
            existingVendorIds={existingVendorIds}
            selectedVendorIds={selectedVendorIds}
            setSelectedVendorIds={setSelectedVendorIds}
            vendorsHashMap={vendorsHashMap}
            setVendorsHashMap={setVendorsHashMap}
            globalVendors={props.globalVendors}
            pendingRequestsMap={props.pendingRequestsMap}
          />
        </Tabs.TabPane>
        {vendorScanResultsTab}
        <Tabs.TabPane tab="Existing Vendor List" key="3">
          <ImportVendorsFromVL
            categories={categories}
            selectedVendorIds={selectedVendorIds}
            setSelectedVendorIds={setSelectedVendorIds}
            vendorsHashMap={vendorsHashMap}
            setVendorsHashMap={setVendorsHashMap}
            setVendorsFromImportedVl={setVendorsFromImportedVl}
            setImportHooks={setImportHooks}
            overlappingVendorIds={overlappingVendorIds}
            isOverlap={Boolean(overrideVendorsWarningMessage)}
          />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  )
}

const mapStateToProps = function (store) {
  return {
    globalVendors: store.usPrivacyReducerState.getIn(['mspsSystemVendors', 'value']) ?? OrderedSet([]),
    scannedVendors: store.vendorListState.getIn(['scannedVendors', 'value']),
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    pendingRequestsMap: new Map({
      globalVendors: store.usPrivacyReducerState.getIn(['mspsSystemVendors', 'pending'])
    }),
  };
};

export default connect(mapStateToProps, {
  getGlobalVendors 
})(AddVendorsModal);