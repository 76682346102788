import React, { useState, useEffect } from "react";
import { SelectDropdown, Input} from "../../../../../styleguide"
import { Col, Form, Row, Space, Typography, Button, Modal, Alert } from 'antd';
import { Category } from "../../../../../records/preferences_records";
import { LeftOutlined } from "@ant-design/icons";
import { channelOptions } from '../../../helper';

const { TextArea } = Input;

const AddCategoryModal = (props) => {
  const {
    showAddCategoryModal,
    handleCancel,
    addCategory,
    mode,
    currentCategory,
    initialValues
  } = props

  const [form] = Form.useForm();
  const [selectedChannel, setSelectedChannel] = useState([])
  const [confirmMsgBeforeSave, setConfirmMsgBeforeSave] = useState(null);

  function generateRandom10DigitNumber() {
    const min = 1000000000; // Minimum 10-digit number
    const max = 9999999999; // Maximum 10-digit number
    // Generate a random number between min and max (inclusive)
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNum;
  }

  useEffect(() => {
    const initialValues = (mode === 'Add') ? { name: '', channel: [], description: '' } : currentCategory?.toJS()
    form.setFieldsValue(initialValues); // Populate the form with initial values
  }, [mode, currentCategory]);

  const handleAddUpdateCategory = async (showConfirmatin) => {
    if(mode == 'Edit' && showConfirmatin){
      setConfirmMsgBeforeSave(<>If you change the default text for the category title and/or description here, you will need to update the translation text for additional languages in the <b>Language and Translations</b> step as well</>)
    } else {
      const categoryValues = await form.validateFields();
      categoryValues.description = categoryValues.description.trim();
      let updatedCategory;
      if(mode == "Edit"){
        updatedCategory = currentCategory.set("name", categoryValues.name).set("channels", categoryValues.channels).set("description", categoryValues.description)
        addCategory(updatedCategory);
      } else {
        const categoryValuesWithId = {
          ...categoryValues,
          categoryId: `temp_${generateRandom10DigitNumber()}`,  // Add a unique ID to the category
        };
        addCategory(new Category(categoryValuesWithId))
      }
      handleCancel();
      setConfirmMsgBeforeSave(null);
    }
  }

  const modalTitle = (mode === 'Add') ? "Add Category" : "Edit category"
  const btnTitle =
    mode === "Add" ? "Add Category" : confirmMsgBeforeSave ? "Confirm" : "Save";

  return (
    <Modal
      open={showAddCategoryModal}
      title={props.onEditScreen ? "Manage all Categories" : modalTitle}
      onOk={() => confirmMsgBeforeSave ? handleAddUpdateCategory(false) : handleAddUpdateCategory(true)}
      okText={btnTitle}
      okButtonProps={{size: "large"}}
      onCancel={handleCancel}
      cancelButtonProps={{size: "large"}}
      closable={true}
      className="usnat-vendor-management-modal"
      destroyOnClose={true}
      width={props.onEditScreen ? 1200 : 800}
      style={{
        top: "5%",
      }}
    >
      <Row gutter={[16, 24]}>
        <Col span={12}>
          {props.onEditScreen ? (
            <Space>
              <Button icon={<LeftOutlined />} type="text" size="middle" onClick={handleCancel} />
              <div><Typography.Text level={4} disabled={true}>{modalTitle}{mode === 'Add' ? null : ":"}</Typography.Text> <Typography.Text strong>{mode === 'Add' ? null : `${currentCategory.get('name')}`}</Typography.Text></div>
            </Space>
          ) : null}
        </Col>
      </Row>
    
      <Form
        form={form}
        layout={"vertical"}
        component={false}
        initialValues={{ name: "", channels: [], description: "" }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Category Name" rules={[{ required: true, message: 'Please enter category name' }]}   labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Input style={{ width: "300px" }} placeholder="Category Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="channels" label="Channels" rules={[{ required: true, message: 'Please select channel' }]} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <SelectDropdown
                value={["email"]}
                options={channelOptions}
                onChange={(value) => setSelectedChannel(value)}
                selectAll
                multiple
                entityName="Channels"
                directUpdate
                style={{ width: "300px" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="description" label="Description" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <TextArea rows={2} placeholder="Enter Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {confirmMsgBeforeSave ? <Alert type={"warning"} showIcon message={confirmMsgBeforeSave}/>: null}
    </Modal>
  );
}

export default AddCategoryModal
